import { Descriptions, Tag } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";

const StudentDetails = () => {
  const { id } = useParams();
  const [initialRender, setInitialRender] = useState(true);
  const [student, setStudent] = useState(null);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getStudent(id).then((data) => {
        console.log(data.data);
        setStudent(data.data);
      });
    }
  }, [initialRender]);
  return (
    <div className="max-w-7xl mx-auto bg-white border border-gray-300 p-4">
      {student && (
        <>
          <Descriptions
            extra={<Tag color="blue">Bus - {student.bus.busno}</Tag>}
            bordered
            size="small"
            title={student.givenName + " " + student.familyName + " details"}
          >
            <Descriptions.Item label="Address">
              {student.address}
            </Descriptions.Item>
            <Descriptions.Item label="Suburb">
              {student.suburb.name}
            </Descriptions.Item>
            <Descriptions.Item label="Post Code">
              {student.postcode}
            </Descriptions.Item>
            <Descriptions.Item label="Enrol Date">
              {moment(student.createdOn).format("DD-MM-YY")}
            </Descriptions.Item>
            <Descriptions.Item label="Year">{student.year}</Descriptions.Item>
            <Descriptions.Item label="Medical">
              {student.medical || "---"}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions bordered size="small" title="Bus details">
            <Descriptions.Item label="Bus No">
              {student.bus.busno}
            </Descriptions.Item>
            <Descriptions.Item label="Driver">
              {student.bus.driver}
            </Descriptions.Item>
            <Descriptions.Item label="Driver Phone">
              {student.bus.phone}
            </Descriptions.Item>

            <Descriptions.Item label="Usage">{student.usage}</Descriptions.Item>
            <Descriptions.Item label="Contact">
              {student.cparent}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions
            bordered
            size="small"
            title={`Parent-1 details ${
              student.cparent === "Parent-1" ? "(Contact Parent)" : ""
            }`}
          >
            <Descriptions.Item label="Name">
              {student.p1givenName + " " + student.p1familyName}
            </Descriptions.Item>
            <Descriptions.Item label="Phone">
              {student.p1phone}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {student.p1email}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions
            bordered
            size="small"
            title={`Parent-2 details ${
              student.cparent === "Parent-2" && "(Contact Parent)"
            }`}
          >
            <Descriptions.Item label="Name">
              {student.p2givenName + " " + student.p2familyName}
            </Descriptions.Item>
            <Descriptions.Item label="Phone">
              {student.p2phone}
            </Descriptions.Item>
            <Descriptions.Item label="Email">
              {student.p2email}
            </Descriptions.Item>
          </Descriptions>
          <br />
          <Descriptions bordered size="small" title="Emergency contact details">
            <Descriptions.Item label="Name">
              {student.egivenName + " " + student.efamilyName}
            </Descriptions.Item>
            <Descriptions.Item label="Phone">
              {student.ephone}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </div>
  );
};

export default StudentDetails;
