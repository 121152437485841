import { ClearOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Form,
  Divider,
  Input,
  Select,
  Button,
  Space,
  notification,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const ExitForm = () => {
  const [form] = Form.useForm();
  const { lg } = useBreakpoint();
  const router = useHistory();

  const onFinish = (values) => {
    console.log(values);
    AdminService.newWithDraw(values).then((data) => {
      notification.success({
        message: "Success!",
        description: "Saved successfully",
      });
      router.push("/completed");
    });
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="container mx-auto min-h-screen">
      <div className="py-6  overflow-hidden lg:pb-24 ">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h2 className="text-center uppercase text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl mb-6">
              Withdraw Bus Service Form
            </h2>
            <h2 className="text-center uppercase text-3xl leading-8 font-extrabold tracking-tight text-red-600 sm:text-4xl">
              CHOPPERS TRANSPORT
            </h2>
          </div>
          <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            layout={lg ? "horizontal" : "vertical"}
            {...GlobalVariables.formLayout}
            initialValues={{ cob: "1101" }}
          >
            <Form.Item>
              <Divider orientation="left">STUDENT DETAILS</Divider>
            </Form.Item>
            <Form.Item
              label="Family Name"
              name="familyName"
              rules={[{ required: true, message: "Family name is required!" }]}
            >
              <Input placeholder="Please type family name" />
            </Form.Item>
            <Form.Item
              label="Given Name"
              name="givenName"
              rules={[{ required: true, message: "Given name is required!" }]}
            >
              <Input placeholder="Please type given name" />
            </Form.Item>
            <Form.Item label="Student Year Level in 2021" name="year">
              <Select placeholder="Select Year">
                {GlobalVariables.years.map((y) => (
                  <Select.Option key={y} value={y}>
                    {y}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Student Usage" name="usage">
              <Select placeholder="Select Usage">
                {GlobalVariables.usages.map((y) => (
                  <Select.Option key={y.key} value={y.key}>
                    {y.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Divider orientation="left">PARENT DETAILS</Divider>
            </Form.Item>
            <Form.Item
              label="Family Name"
              name="p1familyName"
              rules={[{ required: true, message: "Family name is required!" }]}
            >
              <Input placeholder="Please type family name" />
            </Form.Item>
            <Form.Item
              label="Given Name"
              name="p1givenName"
              rules={[{ required: true, message: "Given name is required!" }]}
            >
              <Input placeholder="Please type given name" />
            </Form.Item>
            <Form.Item
              label="E-mail"
              name="p1email"
              rules={[{ required: true, message: "Email is required!" }]}
            >
              <Input placeholder="Please type parent email" />
            </Form.Item>
            <Form.Item
              label="Phone"
              name="p1phone"
              rules={[{ required: true, message: "Phone is required!" }]}
            >
              <Input placeholder="Please type parent phone" />
            </Form.Item>
            <Form.Item
              wrapperCol={
                lg
                  ? {
                      offset: 6,
                      span: 18,
                    }
                  : {}
              }
            >
              <div>Name(s) of sibling(s) exiting the bus service</div>
            </Form.Item>
            <Form.Item
              wrapperCol={
                lg
                  ? {
                      offset: 6,
                      span: 18,
                    }
                  : {}
              }
              // label="Family Name"
              name="siblings"
              rules={[{ required: true, message: "Family name is required!" }]}
            >
              <Input placeholder="Please type sibling(s) name" />
            </Form.Item>
            <Form.Item
              wrapperCol={
                lg
                  ? {
                      offset: 6,
                      span: 18,
                    }
                  : {}
              }
            >
              <p className=""> Acknowledge Terms and Conditions - 1*</p>
              <p>
                Your bus enrolment will be finalised by the beginning next term.
                No refund will be issued for the remaining of current term.
              </p>
            </Form.Item>
            <Form.Item
              wrapperCol={
                lg
                  ? {
                      offset: 6,
                      span: 18,
                    }
                  : {}
              }
              className="mt-6"
            >
              <Space>
                <Button
                  type="default"
                  htmlType="reset"
                  icon={<ClearOutlined />}
                >
                  Clear Form
                </Button>

                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                >
                  Submit Form
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ExitForm;
