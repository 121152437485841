export const GlobalVariables = {
  formLayout: {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 18,
    },
  },
  tailLayout: {
    wrapperCol: {
      offset: 6,
      span: 18,
    },
  },
  usages: [
    { key: "Morning", value: "Morning Service" },
    { key: "Afternoon", value: "Afternoon Service" },
    { key: "Both", value: "Both Services" },
  ],

  years: [
    "Kindergarten",
    "Year-1",
    "Year-2",
    "Year-3",
    "Year-4",
    "Year-5",
    "Year-6",
    "Year-7",
    "Year-8",
    "Year-9",
    "Year-10",
    "Year-11",
    "Year-12",
  ],
  compare: (a, b) => {
    var atitle = a || "";
    var btitle = b || "";
    var alfabe =
      "AaBbCcÇçDdEeFfGgĞğHhIıİiJjKkLlMmNnOoÖöPpQqRrSsŞşTtUuÜüVvWwXxYyZz0123456789";
    if (atitle.length === 0 || btitle.length === 0) {
      return atitle.length - btitle.length;
    }
    for (var i = 0; i < atitle.length && i < btitle.length; i++) {
      var ai = alfabe.indexOf(atitle[i]);
      var bi = alfabe.indexOf(btitle[i]);
      if (ai !== bi) {
        return ai - bi;
      }
    }
  },
};
