import {
  DeleteOutlined,
  SearchOutlined,
  UserSwitchOutlined,
  ClearOutlined,
  SaveOutlined,
  ArrowRightOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";

const Students = () => {
  const [students, setStudents] = useState(null);
  const [buses, setBuses] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [state, setState] = useState({ searchText: "", searchedColumn: "" });
  const router = useHistory();
  const { lg } = useBreakpoint();
  const [form] = Form.useForm();
  const searchInput = useRef();

  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getStudents().then((data) => {
        setStudents(data.data.students);
        console.log(data.data);
        setBuses(data.data.buses);
      });
    }
  }, [initialRender]);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setState({ ...state, searchText: "" });
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      title: "App Type",
      dataIndex: "appType",
      ...getColumnSearchProps("appType"),
      sorter: (a, b) => GlobalVariables.compare(a.appType, b.appType),
    },
    {
      title: "First name",
      dataIndex: "givenName",
      ...getColumnSearchProps("givenName"),
      sorter: (a, b) => GlobalVariables.compare(a.givenName, b.givenName),
    },
    {
      title: "Family Name",
      dataIndex: "familyName",
      ...getColumnSearchProps("familyName"),
      sorter: (a, b) => GlobalVariables.compare(a.familyName, b.familyName),
    },
    {
      title: "Year",
      dataIndex: "year",
      ...getColumnSearchProps("year"),
      sorter: (a, b) => GlobalVariables.compare(a.year, b.year),
    },
    {
      title: "Guardian",
      dataIndex: "guardian",
      ...getColumnSearchProps("guardian"),
      sorter: (a, b) => GlobalVariables.compare(a.guardian, b.guardian),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      ...getColumnSearchProps("phone"),
      sorter: (a, b) => GlobalVariables.compare(a.phone, b.phone),
    },
    {
      title: "Address",
      dataIndex: "address",
      ...getColumnSearchProps("address"),
      sorter: (a, b) => GlobalVariables.compare(a.address, b.address),
    },
    {
      title: "Suburb",
      dataIndex: "suburb",
      ...getColumnSearchProps("suburb"),
      sorter: (a, b) => GlobalVariables.compare(a.suburb, b.suburb),
    },
    {
      title: "Postcode",
      dataIndex: "postcode",
      ...getColumnSearchProps("postcode"),
      sorter: (a, b) => GlobalVariables.compare(a.postcode, b.postcode),
    },
    {
      title: "Bus No",
      dataIndex: "busno",
      ...getColumnSearchProps("busno"),
      sorter: (a, b) => GlobalVariables.compare(a.busno, b.busno),
    },
    {
      title: "Actions",
      render: (item) =>
        JSON.parse(localStorage.getItem("user")).role === "Admin" && (
          <Space>
            <Tooltip title="Delete Student" placement="left">
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => deleteStudent(item.id)}
                okText="Yes! Delete."
                cancelText="Cancel"
              >
                <Button type="primary" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
            <Tooltip title="Other Details" placement="left">
              <Button
                type="primary"
                ghost
                onClick={() => router.push("/admin/students/" + item.id)}
                icon={<SearchOutlined />}
              />
            </Tooltip>
            <Tooltip title="Change Bus" placement="left">
              <Button
                type="primary"
                onClick={() => goModal(item)}
                icon={<UserSwitchOutlined />}
              />
            </Tooltip>
          </Space>
        ),
    },
  ];
  const deleteStudent = (id) => {
    console.log(id);
    AdminService.deleteStudent(id).then((data) => {
      notification.success({
        message: "Success!",
        description: "Student Deleted!",
      });
      setInitialRender(true);
    });
  };
  const goModal = (item) => {
    setSelectedStudent(item);
    setVisible(true);
  };

  const exportExcel = () => {
    AdminService.getExcel().then((data) => {
      const url = window.URL.createObjectURL(new Blob([data.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "students.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  };

  const onFinish = (v) => {
    console.log(v);
    const req = {
      studentId: selectedStudent.id,
      newbusid: v.newbusid,
    };
    AdminService.changeBus(req).then((data) => {
      notification.success({ message: "Bus changed!" });
      setInitialRender(true);
      onReset();
    });
  };
  const onReset = () => {
    form.resetFields();
    setVisible(false);
  };
  return (
    <div className="mx-auto container  -mt-4">
      <Modal
        width={1000}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={false}
        title={
          "Change Bus for " +
          selectedStudent?.givenName +
          " " +
          selectedStudent?.familyName
        }
      >
        {selectedStudent && (
          <div>
            <Form form={form} onFinish={onFinish} onReset={onReset}>
              <Form.Item
                name="newbusid"
                rules={[{ required: true, message: "New bus is required!" }]}
              >
                <Select
                  placeholder="Select new bus"
                  className="w-full"
                  allowClear
                >
                  <Select.Option key="a" value={0}>
                    Send to pool
                  </Select.Option>
                  {buses.map((b, index) => (
                    <Select.Option key={index} value={b.id}>
                      {
                        <div>
                          <span>{`Bus-${b.busno}`}</span>{" "}
                          <ArrowRightOutlined className="mx-2" />
                          {b.suburbs.map((s, index) => (
                            <Tag color="blue" key={index}>
                              {s.name}
                            </Tag>
                          ))}{" "}
                        </div>
                      }
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item className="mt-6">
                <Space>
                  <Button
                    type="default"
                    htmlType="reset"
                    icon={<ClearOutlined />}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                  >
                    Change Bus
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>

      <div className="  mx-auto px-4 sm:px-6 lg:px-8  pb-24">
        <br />
        {!lg && (
          <div className=" flex items-center justify-center bg-gray-50 bg-opacity-80 rounded-lg py-6 px-4 sm:px-6 lg:px-8 shadow-lg border border-gray-400">
            <div className="max-w-md w-full space-y-8 text-center">
              <div>
                <p>
                  Your screen size is very small. <br />
                  Large screen required for this page.
                  <br />
                  Please connect desktop computer.
                  <br /> Thank you
                </p>
                <br />
              </div>
            </div>
          </div>
        )}
        {lg && (
          <>
            <Button
              style={{ float: "right" }}
              type="primary"
              icon={<FileExcelOutlined />}
              onClick={exportExcel}
            >
              Export Excel
            </Button>
            <br />
            <Table
              title={() =>
                students && (
                  <span className="text-amazonl font-semibold text-base">
                    Total Students : {students.length}
                  </span>
                )
              }
              dataSource={students}
              bordered
              pagination={false}
              columns={columns}
              size="small"
              loading={students === null}
              rowKey="id"
            />
            {/* <br />{" "}
            {students && students.length > 0 && (
              <>
                <Divider orientation="center">
                  Send mail to all guardians
                </Divider>
                <Form
                  className="max-w-4xl"
                  form={form}
                  layout={lg ? "horizontal" : "vertical"}
                  onFinish={onFinish}
                  {...GlobalVariables.formLayout}
                >
                  <Form.Item label="Mail Subject" name="subject">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Mail Message">
                    <ReactQuill
                      modules={modules}
                      theme="snow"
                      onChange={(e) => quillChange(e)}
                      placeholder="Type news long details"
                    />
                  </Form.Item>

                  <Form.Item {...GlobalVariables.tailLayout}>
                    <Popconfirm
                      title="Are you sure ?"
                      onConfirm={onFinish}
                      okText="Yes! Send."
                      cancelText="Cancel"
                    >
                      <Button type="primary" danger icon={<MailOutlined />}>
                        Send Email to All Guardians
                      </Button>
                    </Popconfirm>
                  </Form.Item>
                </Form>
              </>
            )}*/}
          </>
        )}
      </div>
    </div>
  );
};

export default Students;
