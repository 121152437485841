export const suburbs = [
  { suburb: "East Albury", postcode: 2640, lga: "Albury (City)" },
  { suburb: "Lavington", postcode: 2641, lga: "Albury (City)" },
  { suburb: "Glenroy", postcode: 2640, lga: "Albury (City)" },
  { suburb: "Albury", postcode: 2640, lga: "Albury (City)" },
  { suburb: "North Albury", postcode: 2640, lga: "Albury (City)" },
  { suburb: "Table Top", postcode: 2640, lga: "Albury (City)" },
  { suburb: "Splitters Creek", postcode: 2640, lga: "Albury (City)" },
  { suburb: "West Albury", postcode: 2640, lga: "Albury (City)" },
  { suburb: "Springdale Heights", postcode: 2641, lga: "Albury (City)" },
  { suburb: "Thurgoona", postcode: 2640, lga: "Albury (City)" },
  { suburb: "South Albury", postcode: 2640, lga: "Albury (City)" },
  { suburb: "Wirlinga", postcode: 2640, lga: "Albury (City)" },
  { suburb: "Hamilton Valley", postcode: 2641, lga: "Albury (City)" },
  { suburb: "Jindera", postcode: 2642, lga: "Greater Hume Shire (Area)" },
  { suburb: "Armidale", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Boorolong", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Dumaresq", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Duval", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Donald Creek", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Black Mountain", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Aberfoyle", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Thalgarrah", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Wollomombi", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Jeogla", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Ebor", postcode: 2453, lga: "Armidale Regional (Area)" },
  { suburb: "Dangarsleigh", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Metz", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Hillgrove", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Castle Doyle", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Enmore", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Lower Creek", postcode: 2440, lga: "Armidale Regional (Area)" },
  { suburb: "Carrai", postcode: 2440, lga: "Armidale Regional (Area)" },
  { suburb: "Tilbuster", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Kellys Plains", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Wongwibinda", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Lyndhurst", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Puddledock", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Croydon Park", postcode: 2133, lga: "Inner West (Area)" },
  { suburb: "Croydon", postcode: 2132, lga: "Inner West (Area)" },
  { suburb: "Ashfield", postcode: 2131, lga: "Inner West (Area)" },
  { suburb: "Haberfield", postcode: 2045, lga: "Inner West (Area)" },
  { suburb: "Summer Hill", postcode: 2130, lga: "Inner West (Area)" },
  { suburb: "Hurlstone Park", postcode: 2193, lga: "Inner West (Area)" },
  { suburb: "Ashbury", postcode: 2193, lga: "Inner West (Area)" },
  { suburb: "Guyong", postcode: 2798, lga: "Cabonne (Area)" },
  { suburb: "Newington", postcode: 2127, lga: "Parramatta (City)" },
  { suburb: "Lidcombe", postcode: 2141, lga: "Cumberland (Area)" },
  { suburb: "Berala", postcode: 2141, lga: "Cumberland (Area)" },
  { suburb: "Auburn", postcode: 2144, lga: "Cumberland (Area)" },
  { suburb: "Sydney Olympic Park", postcode: 2127, lga: "Parramatta (City)" },
  { suburb: "Silverwater", postcode: 2128, lga: "Parramatta (City)" },
  { suburb: "Regents Park", postcode: 2143, lga: "Cumberland (Area)" },
  { suburb: "Wentworth Point", postcode: 2127, lga: "Parramatta (City)" },
  { suburb: "Rookwood", postcode: 2141, lga: "Cumberland (Area)" },
  { suburb: "East Ballina", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Tintenbar", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Cumbalum", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Broken Head", postcode: 2481, lga: "Ballina (Area)" },
  { suburb: "Lennox Head", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Newrybar", postcode: 2479, lga: "Ballina (Area)" },
  { suburb: "Knockrow", postcode: 2479, lga: "Ballina (Area)" },
  { suburb: "Skennars Head", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Wardell", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "West Ballina", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Alstonvale", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "East Wardell", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Wollongbar", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Bagotville", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Ballina", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Fernleigh", postcode: 2479, lga: "Ballina (Area)" },
  { suburb: "Meerschaum Vale", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Keith Hall", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "South Ballina", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Teven", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Alstonville", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Cabbage Tree Island", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Goat Island", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Pimlico Island", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Pimlico", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Rous Mill", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Dalwood", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Marom Creek", postcode: 2480, lga: "Ballina (Area)" },
  { suburb: "Patchs Beach", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Rous", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Lynwood", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Uralba", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Empire Vale", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "Tuckombil", postcode: 2477, lga: "Ballina (Area)" },
  { suburb: "Brooklet", postcode: 2479, lga: "Ballina (Area)" },
  { suburb: "Coolgardie", postcode: 2478, lga: "Ballina (Area)" },
  { suburb: "McLeans Ridges", postcode: 2480, lga: "Ballina (Area)" },
  { suburb: "Euston", postcode: 2737, lga: "Balranald (Area)" },
  { suburb: "Balranald", postcode: 2715, lga: "Balranald (Area)" },
  { suburb: "Hatfield", postcode: 2715, lga: "Balranald (Area)" },
  { suburb: "Oxley", postcode: 2711, lga: "Balranald (Area)" },
  { suburb: "Ivanhoe", postcode: 2878, lga: "Balranald (Area)" },
  { suburb: "Mossgiel", postcode: 2878, lga: "Balranald (Area)" },
  { suburb: "Corrong", postcode: 2711, lga: "Balranald (Area)" },
  { suburb: "Arumpo", postcode: 2715, lga: "Balranald (Area)" },
  { suburb: "Hornsby", postcode: 2077, lga: "Hornsby (Area)" },
  { suburb: "Clare", postcode: 2711, lga: "Balranald (Area)" },
  { suburb: "Kyalite", postcode: 2715, lga: "Balranald (Area)" },
  { suburb: "Mungo", postcode: 2715, lga: "Balranald (Area)" },
  { suburb: "Booligal", postcode: 2711, lga: "Balranald (Area)" },
  {
    suburb: "Condell Park",
    postcode: 2200,
    lga: "Canterbury-Bankstown (Area)",
  },
  { suburb: "Bankstown", postcode: 2200, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Punchbowl", postcode: 2196, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Mount Lewis", postcode: 2190, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Greenacre", postcode: 2190, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Yagoona", postcode: 2199, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Chullora", postcode: 2190, lga: "Canterbury-Bankstown (Area)" },
  {
    suburb: "Bonny Hills",
    postcode: 2445,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Georges Hall",
    postcode: 2198,
    lga: "Canterbury-Bankstown (Area)",
  },
  { suburb: "Villawood", postcode: 2163, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Bass Hill", postcode: 2197, lga: "Canterbury-Bankstown (Area)" },
  {
    suburb: "Chester Hill",
    postcode: 2162,
    lga: "Canterbury-Bankstown (Area)",
  },
  { suburb: "Sefton", postcode: 2162, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Revesby", postcode: 2212, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Lansdowne", postcode: 2163, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Potts Hill", postcode: 2143, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Birrong", postcode: 2143, lga: "Canterbury-Bankstown (Area)" },
  {
    suburb: "Bankstown Aerodrome",
    postcode: 2200,
    lga: "Canterbury-Bankstown (Area)",
  },
  { suburb: "Milperra", postcode: 2214, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "East Hills", postcode: 2213, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Panania", postcode: 2213, lga: "Canterbury-Bankstown (Area)" },
  {
    suburb: "Picnic Point",
    postcode: 2213,
    lga: "Canterbury-Bankstown (Area)",
  },
  {
    suburb: "Revesby Heights",
    postcode: 2212,
    lga: "Canterbury-Bankstown (Area)",
  },
  { suburb: "Padstow", postcode: 2211, lga: "Canterbury-Bankstown (Area)" },
  {
    suburb: "Padstow Heights",
    postcode: 2211,
    lga: "Canterbury-Bankstown (Area)",
  },
  { suburb: "Eglinton", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "West Bathurst", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Bathurst", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "South Bathurst", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Kelso", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Raglan", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Mount Panorama", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Mitchell", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "White Rock", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Windradyne", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Gormans Hill", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Orton Park", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Forest Grove", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Llanarth", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Abercrombie", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Robin Hill", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Laffing Waters", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Georges Plains", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Wimbledon", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Wisemans Creek", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Charlton", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Curragh", postcode: 2795, lga: "Bathurst Regional (Area)" },
  {
    suburb: "Abercrombie River",
    postcode: 2795,
    lga: "Bathurst Regional (Area)",
  },
  { suburb: "Caloola", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Copperhannia", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Colo", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Arkell", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Cow Flat", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Fosters Valley", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Trunkey Creek", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Triangle Flat", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Meadow Flat", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Millah Murrah", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Wiagdon", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Clear Creek", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Upper Turon", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Sunny Corner", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Yetholme", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Locksley", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Milkers Flat", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Sallys Flat", postcode: 2850, lga: "Bathurst Regional (Area)" },
  { suburb: "Kirkconnell", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Gemalla", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Gowan", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Freemantle", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Killongbutta", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Bruinbun", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Peel", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Crudine", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Glanmire", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Paling Yards", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Napoleon Reef", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Tarana", postcode: 2787, lga: "Bathurst Regional (Area)" },
  { suburb: "Wattle Flat", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Sofala", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Walang", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Tambaroora", postcode: 2850, lga: "Bathurst Regional (Area)" },
  { suburb: "Duramana", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Mount Rankin", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Turondale", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Billywillinga", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Rock Forest", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Watton", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Yarras", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "The Rocks", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Bathampton", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Dunkeld", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Vittoria", postcode: 2799, lga: "Bathurst Regional (Area)" },
  {
    suburb: "Fitzgeralds Valley",
    postcode: 2795,
    lga: "Bathurst Regional (Area)",
  },
  {
    suburb: "Fitzgeralds Mount",
    postcode: 2799,
    lga: "Bathurst Regional (Area)",
  },
  { suburb: "O'Connell", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Rockley Mount", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Brewongle", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "The Lagoon", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Bald Ridge", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Bella Vista", postcode: 2153, lga: "The Hills Shire (Area)" },
  { suburb: "Baulkham Hills", postcode: 2153, lga: "The Hills Shire (Area)" },
  { suburb: "Castle Hill", postcode: 2154, lga: "The Hills Shire (Area)" },
  { suburb: "Kellyville", postcode: 2155, lga: "The Hills Shire (Area)" },
  {
    suburb: "West Pennant Hills",
    postcode: 2125,
    lga: "The Hills Shire (Area)",
  },
  { suburb: "Winston Hills", postcode: 2153, lga: "The Hills Shire (Area)" },
  { suburb: "Glenhaven", postcode: 2156, lga: "The Hills Shire (Area)" },
  { suburb: "Rouse Hill", postcode: 2155, lga: "The Hills Shire (Area)" },
  { suburb: "Beaumont Hills", postcode: 2155, lga: "The Hills Shire (Area)" },
  { suburb: "Kenthurst", postcode: 2156, lga: "The Hills Shire (Area)" },
  { suburb: "Cattai", postcode: 2756, lga: "The Hills Shire (Area)" },
  { suburb: "Dural", postcode: 2158, lga: "The Hills Shire (Area)" },
  { suburb: "Wisemans Ferry", postcode: 2775, lga: "The Hills Shire (Area)" },
  { suburb: "Lower Portland", postcode: 2756, lga: "The Hills Shire (Area)" },
  { suburb: "Maroota", postcode: 2756, lga: "The Hills Shire (Area)" },
  { suburb: "Sackville North", postcode: 2756, lga: "The Hills Shire (Area)" },
  { suburb: "South Maroota", postcode: 2756, lga: "The Hills Shire (Area)" },
  { suburb: "Glenorie", postcode: 2157, lga: "The Hills Shire (Area)" },
  { suburb: "Maraylya", postcode: 2765, lga: "The Hills Shire (Area)" },
  { suburb: "Middle Dural", postcode: 2158, lga: "The Hills Shire (Area)" },
  { suburb: "Box Hill", postcode: 2765, lga: "The Hills Shire (Area)" },
  { suburb: "Nelson", postcode: 2765, lga: "The Hills Shire (Area)" },
  { suburb: "Annangrove", postcode: 2156, lga: "The Hills Shire (Area)" },
  { suburb: "North Rocks", postcode: 2151, lga: "Parramatta (City)" },
  { suburb: "Oatlands", postcode: 2117, lga: "Parramatta (City)" },
  { suburb: "Carlingford", postcode: 2118, lga: "Parramatta (City)" },
  { suburb: "Northmead", postcode: 2152, lga: "Parramatta (City)" },
  { suburb: "North Parramatta", postcode: 2151, lga: "Parramatta (City)" },
  { suburb: "Wonboyn North", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Mumbulla Mountain", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Angledale", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Frogs Hollow", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Kanoona", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Devils Hole", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Morans Crossing", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Yellow Pinch", postcode: 2548, lga: "Bega Valley (Area)" },
  { suburb: "Bermagui", postcode: 2546, lga: "Bega Valley (Area)" },
  { suburb: "Barragga Bay", postcode: 2546, lga: "Bega Valley (Area)" },
  { suburb: "Cuttagee", postcode: 2546, lga: "Bega Valley (Area)" },
  { suburb: "Green Cape", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Tinpot", postcode: 2546, lga: "Bega Valley (Area)" },
  { suburb: "Tarraganda", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Tanja", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Edrom", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Wolumla", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Quaama", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Pambula", postcode: 2549, lga: "Bega Valley (Area)" },
  { suburb: "Eden", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Bega", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Merimbula", postcode: 2548, lga: "Bega Valley (Area)" },
  { suburb: "Pambula Beach", postcode: 2549, lga: "Bega Valley (Area)" },
  { suburb: "Candelo", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Wyndham", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Kingswood", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "South Pambula", postcode: 2549, lga: "Bega Valley (Area)" },
  { suburb: "Mirador", postcode: 2548, lga: "Bega Valley (Area)" },
  { suburb: "Berrambool", postcode: 2548, lga: "Bega Valley (Area)" },
  { suburb: "Tathra", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Wallaga Lake", postcode: 2546, lga: "Bega Valley (Area)" },
  { suburb: "Wapengo", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Wallagoot", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Boydtown", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Kalaru", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Nungatta", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Nungatta South", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Chinnock", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Nelson", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Bemboka", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Millingandi", postcode: 2549, lga: "Bega Valley (Area)" },
  { suburb: "Nullica", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Steeple Flat", postcode: 2631, lga: "Bega Valley (Area)" },
  { suburb: "Mount Darragh", postcode: 2632, lga: "Bega Valley (Area)" },
  { suburb: "New Buildings", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Kiah", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Greendale", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Numbugga", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Brogo", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Tantawangalo", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Burragate", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "South Wolumla", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Towamba", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Mogilla", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Yowrie", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Lochiel", postcode: 2549, lga: "Bega Valley (Area)" },
  { suburb: "Verona", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Nethercote", postcode: 2549, lga: "Bega Valley (Area)" },
  { suburb: "Buckajo", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Coolagolite", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Tura Beach", postcode: 2548, lga: "Bega Valley (Area)" },
  { suburb: "Cobargo", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Dignams Creek", postcode: 2546, lga: "Bega Valley (Area)" },
  { suburb: "Wandella", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Stony Creek", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Toothdale", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Pericoe", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Myrtle Mountain", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Rocky Hall", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Broadwater", postcode: 2549, lga: "Bega Valley (Area)" },
  { suburb: "Murrah", postcode: 2546, lga: "Bega Valley (Area)" },
  { suburb: "Bournda", postcode: 2548, lga: "Bega Valley (Area)" },
  { suburb: "Wonboyn", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Greigs Flat", postcode: 2549, lga: "Bega Valley (Area)" },
  { suburb: "Black Range", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Narrabarba", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Kameruka", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Bald Hills", postcode: 2549, lga: "Bega Valley (Area)" },
  { suburb: "Yankees Creek", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Coolangubra", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Jellat Jellat", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Wadbilliga", postcode: 2546, lga: "Bega Valley (Area)" },
  { suburb: "Nadgee", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Timbillica", postcode: 2551, lga: "Bega Valley (Area)" },
  { suburb: "Wog Wog", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Cathcart", postcode: 2632, lga: "Bega Valley (Area)" },
  { suburb: "Reedy Swamp", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Bellingen", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Urunga", postcode: 2455, lga: "Bellingen (Area)" },
  { suburb: "Mylestom", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Megan", postcode: 2453, lga: "Bellingen (Area)" },
  { suburb: "Deer Vale", postcode: 2453, lga: "Bellingen (Area)" },
  { suburb: "Cascade", postcode: 2453, lga: "Bellingen (Area)" },
  { suburb: "Kalang", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Spicketts Creek", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Valla", postcode: 2448, lga: "Bellingen (Area)" },
  { suburb: "Bostobrick", postcode: 2453, lga: "Bellingen (Area)" },
  { suburb: "Darkwood", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Brierfield", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Tallowwood Ridge", postcode: 2453, lga: "Bellingen (Area)" },
  { suburb: "Thora", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Gleniffer", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Never Never", postcode: 2453, lga: "Bellingen (Area)" },
  { suburb: "Valery", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Fernmount", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Brinerville", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Dorrigo", postcode: 2453, lga: "Bellingen (Area)" },
  { suburb: "Repton", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Kennaicle Creek", postcode: 2449, lga: "Bellingen (Area)" },
  { suburb: "Fernbrook", postcode: 2453, lga: "Bellingen (Area)" },
  { suburb: "Dorrigo Mountain", postcode: 2453, lga: "Bellingen (Area)" },
  { suburb: "Bielsdown Hills", postcode: 2453, lga: "Bellingen (Area)" },
  { suburb: "North Dorrigo", postcode: 2453, lga: "Bellingen (Area)" },
  { suburb: "Raleigh", postcode: 2454, lga: "Bellingen (Area)" },
  { suburb: "Tocumwal", postcode: 2714, lga: "Berrigan (Area)" },
  { suburb: "Finley", postcode: 2713, lga: "Berrigan (Area)" },
  { suburb: "Berrigan", postcode: 2712, lga: "Berrigan (Area)" },
  { suburb: "Barooga", postcode: 3644, lga: "Berrigan (Area)" },
  { suburb: "Boomanoomana", postcode: 2712, lga: "Berrigan (Area)" },
  { suburb: "Savernake", postcode: 2646, lga: "Berrigan (Area)" },
  { suburb: "Lalalty", postcode: 3644, lga: "Berrigan (Area)" },
  { suburb: "Mulwala", postcode: 2647, lga: "Berrigan (Area)" },
  { suburb: "Dean Park", postcode: 2761, lga: "Blacktown (City)" },
  { suburb: "Riverstone", postcode: 2765, lga: "Blacktown (City)" },
  { suburb: "Quakers Hill", postcode: 2763, lga: "Blacktown (City)" },
  { suburb: "Marayong", postcode: 2148, lga: "Blacktown (City)" },
  { suburb: "Kings Park", postcode: 2148, lga: "Blacktown (City)" },
  { suburb: "Kings Langley", postcode: 2147, lga: "Blacktown (City)" },
  { suburb: "Glenwood", postcode: 2768, lga: "Blacktown (City)" },
  { suburb: "Colebee", postcode: 2761, lga: "Blacktown (City)" },
  { suburb: "Marsden Park", postcode: 2765, lga: "Blacktown (City)" },
  { suburb: "Shanes Park", postcode: 2747, lga: "Blacktown (City)" },
  { suburb: "Acacia Gardens", postcode: 2763, lga: "Blacktown (City)" },
  { suburb: "Parklea", postcode: 2768, lga: "Blacktown (City)" },
  { suburb: "Stanhope Gardens", postcode: 2768, lga: "Blacktown (City)" },
  { suburb: "Schofields", postcode: 2762, lga: "Blacktown (City)" },
  { suburb: "Kellyville Ridge", postcode: 2155, lga: "Blacktown (City)" },
  { suburb: "Fairy Meadow", postcode: 2519, lga: "Wollongong (City)" },
  { suburb: "Balgownie", postcode: 2519, lga: "Wollongong (City)" },
  { suburb: "Towradgi", postcode: 2518, lga: "Wollongong (City)" },
  { suburb: "North Wollongong", postcode: 2500, lga: "Wollongong (City)" },
  { suburb: "East Corrimal", postcode: 2518, lga: "Wollongong (City)" },
  { suburb: "Wollongong", postcode: 2500, lga: "Wollongong (City)" },
  { suburb: "Mount Keira", postcode: 2500, lga: "Wollongong (City)" },
  { suburb: "West Wollongong", postcode: 2500, lga: "Wollongong (City)" },
  { suburb: "Mangerton", postcode: 2500, lga: "Wollongong (City)" },
  { suburb: "Coniston", postcode: 2500, lga: "Wollongong (City)" },
  { suburb: "Mount Saint Thomas", postcode: 2500, lga: "Wollongong (City)" },
  { suburb: "Port Kembla", postcode: 2505, lga: "Wollongong (City)" },
  { suburb: "Doonside", postcode: 2767, lga: "Blacktown (City)" },
  { suburb: "Woodcroft", postcode: 2767, lga: "Blacktown (City)" },
  { suburb: "Blacktown", postcode: 2148, lga: "Blacktown (City)" },
  { suburb: "Prospect", postcode: 2148, lga: "Blacktown (City)" },
  { suburb: "Seven Hills", postcode: 2147, lga: "Blacktown (City)" },
  { suburb: "Lalor Park", postcode: 2147, lga: "Blacktown (City)" },
  { suburb: "Toongabbie", postcode: 2146, lga: "Blacktown (City)" },
  { suburb: "Huntingwood", postcode: 2148, lga: "Blacktown (City)" },
  { suburb: "Arndell Park", postcode: 2148, lga: "Blacktown (City)" },
  { suburb: "Eastern Creek", postcode: 2766, lga: "Blacktown (City)" },
  { suburb: "Tregear", postcode: 2770, lga: "Blacktown (City)" },
  { suburb: "Lethbridge Park", postcode: 2770, lga: "Blacktown (City)" },
  { suburb: "Shalvey", postcode: 2770, lga: "Blacktown (City)" },
  { suburb: "Whalan", postcode: 2770, lga: "Blacktown (City)" },
  { suburb: "Mount Druitt", postcode: 2770, lga: "Blacktown (City)" },
  { suburb: "Blackett", postcode: 2770, lga: "Blacktown (City)" },
  { suburb: "Dharruk", postcode: 2770, lga: "Blacktown (City)" },
  { suburb: "Hebersham", postcode: 2770, lga: "Blacktown (City)" },
  { suburb: "Minchinbury", postcode: 2770, lga: "Blacktown (City)" },
  { suburb: "Rooty Hill", postcode: 2766, lga: "Blacktown (City)" },
  { suburb: "Hassall Grove", postcode: 2761, lga: "Blacktown (City)" },
  { suburb: "Glendenning", postcode: 2761, lga: "Blacktown (City)" },
  { suburb: "Emerton", postcode: 2770, lga: "Blacktown (City)" },
  { suburb: "Bidwill", postcode: 2770, lga: "Blacktown (City)" },
  { suburb: "Willmot", postcode: 2770, lga: "Blacktown (City)" },
  { suburb: "Oakhurst", postcode: 2761, lga: "Blacktown (City)" },
  { suburb: "Plumpton", postcode: 2761, lga: "Blacktown (City)" },
  { suburb: "Bungarribee", postcode: 2767, lga: "Blacktown (City)" },
  { suburb: "Ropes Crossing", postcode: 2760, lga: "Blacktown (City)" },
  { suburb: "Naradhan", postcode: 2669, lga: "Bland (Area)" },
  { suburb: "Weethalle", postcode: 2669, lga: "Bland (Area)" },
  { suburb: "Ungarie", postcode: 2669, lga: "Bland (Area)" },
  { suburb: "West Wyalong", postcode: 2671, lga: "Bland (Area)" },
  { suburb: "Wyalong", postcode: 2671, lga: "Bland (Area)" },
  { suburb: "Girral", postcode: 2669, lga: "Bland (Area)" },
  { suburb: "Back Creek", postcode: 2671, lga: "Bland (Area)" },
  { suburb: "Barmedman", postcode: 2668, lga: "Bland (Area)" },
  { suburb: "Barellan", postcode: 2665, lga: "Bland (Area)" },
  { suburb: "Kikoira", postcode: 2669, lga: "Bland (Area)" },
  { suburb: "Beckom", postcode: 2665, lga: "Bland (Area)" },
  { suburb: "Mirrool", postcode: 2665, lga: "Bland (Area)" },
  { suburb: "Ariah Park", postcode: 2665, lga: "Bland (Area)" },
  { suburb: "Alleena", postcode: 2671, lga: "Bland (Area)" },
  { suburb: "North Yalgogrin", postcode: 2671, lga: "Bland (Area)" },
  { suburb: "Tallimba", postcode: 2669, lga: "Bland (Area)" },
  { suburb: "Lake Cowal", postcode: 2671, lga: "Bland (Area)" },
  { suburb: "Bland", postcode: 2721, lga: "Bland (Area)" },
  { suburb: "Quandialla", postcode: 2721, lga: "Bland (Area)" },
  { suburb: "Morangarell", postcode: 2666, lga: "Bland (Area)" },
  { suburb: "Rankins Springs", postcode: 2669, lga: "Bland (Area)" },
  { suburb: "Ardlethan", postcode: 2665, lga: "Bland (Area)" },
  { suburb: "Kamarah", postcode: 2665, lga: "Bland (Area)" },
  { suburb: "Blayney", postcode: 2799, lga: "Blayney (Area)" },
  { suburb: "Errowanbang", postcode: 2791, lga: "Blayney (Area)" },
  { suburb: "Carcoar", postcode: 2791, lga: "Blayney (Area)" },
  { suburb: "Newbridge", postcode: 2795, lga: "Blayney (Area)" },
  { suburb: "Forest Reefs", postcode: 2798, lga: "Blayney (Area)" },
  { suburb: "Browns Creek", postcode: 2799, lga: "Blayney (Area)" },
  { suburb: "Millthorpe", postcode: 2798, lga: "Blayney (Area)" },
  { suburb: "Kings Plains", postcode: 2799, lga: "Blayney (Area)" },
  { suburb: "Tallwood", postcode: 2798, lga: "Blayney (Area)" },
  { suburb: "Garland", postcode: 2797, lga: "Blayney (Area)" },
  { suburb: "Neville", postcode: 2799, lga: "Blayney (Area)" },
  { suburb: "Barry", postcode: 2799, lga: "Blayney (Area)" },
  { suburb: "Mandurama", postcode: 2792, lga: "Blayney (Area)" },
  { suburb: "Burnt Yards", postcode: 2792, lga: "Blayney (Area)" },
  { suburb: "Panuara", postcode: 2800, lga: "Blayney (Area)" },
  { suburb: "Blackheath", postcode: 2785, lga: "Blue Mountains (City)" },
  { suburb: "Katoomba", postcode: 2780, lga: "Blue Mountains (City)" },
  {
    suburb: "Blue Mountains National Park",
    postcode: 2780,
    lga: "Blue Mountains (City)",
  },
  { suburb: "Leura", postcode: 2780, lga: "Blue Mountains (City)" },
  { suburb: "Wentworth Falls", postcode: 2782, lga: "Blue Mountains (City)" },
  { suburb: "Lawson", postcode: 2783, lga: "Blue Mountains (City)" },
  { suburb: "Hazelbrook", postcode: 2779, lga: "Blue Mountains (City)" },
  { suburb: "Faulconbridge", postcode: 2776, lga: "Blue Mountains (City)" },
  { suburb: "Springwood", postcode: 2777, lga: "Blue Mountains (City)" },
  { suburb: "Winmalee", postcode: 2777, lga: "Blue Mountains (City)" },
  { suburb: "Blaxland", postcode: 2774, lga: "Blue Mountains (City)" },
  { suburb: "Glenbrook", postcode: 2773, lga: "Blue Mountains (City)" },
  { suburb: "Mount Riverview", postcode: 2774, lga: "Blue Mountains (City)" },
  { suburb: "Lapstone", postcode: 2773, lga: "Blue Mountains (City)" },
  { suburb: "Yellow Rock", postcode: 2777, lga: "Blue Mountains (City)" },
  { suburb: "Medlow Bath", postcode: 2780, lga: "Blue Mountains (City)" },
  { suburb: "Bullaburra", postcode: 2784, lga: "Blue Mountains (City)" },
  { suburb: "Woodford", postcode: 2778, lga: "Blue Mountains (City)" },
  { suburb: "Linden", postcode: 2778, lga: "Blue Mountains (City)" },
  { suburb: "Valley Heights", postcode: 2777, lga: "Blue Mountains (City)" },
  { suburb: "Warrimoo", postcode: 2774, lga: "Blue Mountains (City)" },
  { suburb: "Bell", postcode: 2786, lga: "Blue Mountains (City)" },
  { suburb: "Berambing", postcode: 2758, lga: "Blue Mountains (City)" },
  { suburb: "Mount Irvine", postcode: 2786, lga: "Blue Mountains (City)" },
  { suburb: "Mount Victoria", postcode: 2786, lga: "Blue Mountains (City)" },
  { suburb: "Megalong Valley", postcode: 2785, lga: "Blue Mountains (City)" },
  { suburb: "Mount Wilson", postcode: 2786, lga: "Blue Mountains (City)" },
  {
    suburb: "Hawkesbury Heights",
    postcode: 2777,
    lga: "Blue Mountains (City)",
  },
  { suburb: "Sun Valley", postcode: 2777, lga: "Blue Mountains (City)" },
  { suburb: "Mount Tomah", postcode: 2758, lga: "Blue Mountains (City)" },
  { suburb: "Hermidale", postcode: 2831, lga: "Bogan (Area)" },
  { suburb: "Bobadah", postcode: 2877, lga: "Bogan (Area)" },
  { suburb: "Pangee", postcode: 2825, lga: "Bogan (Area)" },
  { suburb: "Five Ways", postcode: 2873, lga: "Bogan (Area)" },
  { suburb: "Miandetta", postcode: 2825, lga: "Bogan (Area)" },
  { suburb: "Nyngan", postcode: 2825, lga: "Bogan (Area)" },
  { suburb: "Honeybugle", postcode: 2825, lga: "Bogan (Area)" },
  { suburb: "Coolabah", postcode: 2831, lga: "Bogan (Area)" },
  { suburb: "Girilambone", postcode: 2831, lga: "Bogan (Area)" },
  { suburb: "Canonba", postcode: 2825, lga: "Bogan (Area)" },
  { suburb: "Babinda", postcode: 2825, lga: "Bogan (Area)" },
  { suburb: "The Marra", postcode: 2831, lga: "Bogan (Area)" },
  { suburb: "Byrock", postcode: 2831, lga: "Bogan (Area)" },
  { suburb: "Murrawombie", postcode: 2825, lga: "Bogan (Area)" },
  { suburb: "Buddabadah", postcode: 2825, lga: "Bogan (Area)" },
  { suburb: "Mulla", postcode: 2825, lga: "Bogan (Area)" },
  { suburb: "Canbelego", postcode: 2835, lga: "Bogan (Area)" },
  { suburb: "Bungarby", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Lords Hill", postcode: 2632, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Bombala", postcode: 2632, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Craigie", postcode: 2632, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Mila", postcode: 2632, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Palarang", postcode: 2632, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Gunningrah", postcode: 2632, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Mount Cooper",
    postcode: 2631,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Tombong", postcode: 2633, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Bondi Forest",
    postcode: 2632,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Bukalong", postcode: 2632, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Cambalong", postcode: 2632, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Holts Flat", postcode: 2631, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Boco", postcode: 2631, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Jincumbilly",
    postcode: 2631,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Glen Allen", postcode: 2631, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Creewah", postcode: 2631, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Paddys Flat",
    postcode: 2632,
    lga: "Snowy Monaro Regional (Area)",
  },
  {
    suburb: "Byadbo Wilderness",
    postcode: 2628,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Corrowong", postcode: 2633, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Delegate", postcode: 2633, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Bibbenluke", postcode: 2632, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Ando", postcode: 2631, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Rockton", postcode: 2632, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Rosemeath", postcode: 2632, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Coolumbooka",
    postcode: 2632,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Quidong", postcode: 2632, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Merriangaah",
    postcode: 2632,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Boorowa", postcode: 2586, lga: "Hilltops (Area)" },
  { suburb: "Wyangala", postcode: 2808, lga: "Hilltops (Area)" },
  { suburb: "Reids Flat", postcode: 2586, lga: "Hilltops (Area)" },
  { suburb: "Mount Collins", postcode: 2794, lga: "Hilltops (Area)" },
  { suburb: "Rugby", postcode: 2583, lga: "Hilltops (Area)" },
  { suburb: "Rye Park", postcode: 2586, lga: "Hilltops (Area)" },
  { suburb: "Hovells Creek", postcode: 2794, lga: "Hilltops (Area)" },
  { suburb: "Frogmore", postcode: 2586, lga: "Hilltops (Area)" },
  { suburb: "Taylors Flat", postcode: 2586, lga: "Hilltops (Area)" },
  { suburb: "Godfreys Creek", postcode: 2586, lga: "Hilltops (Area)" },
  { suburb: "Mascot", postcode: 2020, lga: "Botany Bay (City)" },
  { suburb: "Botany", postcode: 2019, lga: "Botany Bay (City)" },
  { suburb: "Rosebery", postcode: 2018, lga: "Botany Bay (City)" },
  { suburb: "Eastlakes", postcode: 2018, lga: "Botany Bay (City)" },
  { suburb: "Pagewood", postcode: 2035, lga: "Botany Bay (City)" },
  { suburb: "Daceyville", postcode: 2032, lga: "Botany Bay (City)" },
  { suburb: "Hillsdale", postcode: 2036, lga: "Botany Bay (City)" },
  { suburb: "Banksmeadow", postcode: 2019, lga: "Botany Bay (City)" },
  { suburb: "Eastgardens", postcode: 2036, lga: "Botany Bay (City)" },
  { suburb: "Louth", postcode: 2840, lga: "Bourke (Area)" },
  { suburb: "Wanaaring", postcode: 2840, lga: "Bourke (Area)" },
  { suburb: "Gumbalie", postcode: 2840, lga: "Bourke (Area)" },
  { suburb: "Fords Bridge", postcode: 2840, lga: "Bourke (Area)" },
  { suburb: "Enngonia", postcode: 2840, lga: "Bourke (Area)" },
  { suburb: "Bourke", postcode: 2840, lga: "Bourke (Area)" },
  { suburb: "Hungerford", postcode: 2840, lga: "Bourke (Area)" },
  { suburb: "Gunderbooka", postcode: 2840, lga: "Bourke (Area)" },
  { suburb: "Angledool", postcode: 2834, lga: "Brewarrina (Area)" },
  { suburb: "Collerina", postcode: 2839, lga: "Brewarrina (Area)" },
  { suburb: "Narran Lake", postcode: 2839, lga: "Brewarrina (Area)" },
  { suburb: "Talawanta", postcode: 2839, lga: "Brewarrina (Area)" },
  { suburb: "Goodooga", postcode: 2838, lga: "Brewarrina (Area)" },
  { suburb: "Brewarrina", postcode: 2839, lga: "Brewarrina (Area)" },
  { suburb: "Gongolgon", postcode: 2839, lga: "Brewarrina (Area)" },
  { suburb: "Broken Hill", postcode: 2880, lga: "Broken Hill (City)" },
  { suburb: "Enfield", postcode: 2136, lga: "Burwood (Area)" },
  { suburb: "Burwood", postcode: 2134, lga: "Burwood (Area)" },
  { suburb: "Burwood Heights", postcode: 2136, lga: "Burwood (Area)" },
  { suburb: "Strathfield", postcode: 2135, lga: "Burwood (Area)" },
  { suburb: "The Pocket", postcode: 2483, lga: "Byron (Area)" },
  { suburb: "Coopers Shoot", postcode: 2479, lga: "Byron (Area)" },
  { suburb: "Tyagarah", postcode: 2481, lga: "Byron (Area)" },
  { suburb: "New Brighton", postcode: 2483, lga: "Byron (Area)" },
  { suburb: "Koonyum Range", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Palmwoods", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Brunswick Heads", postcode: 2483, lga: "Byron (Area)" },
  { suburb: "Ocean Shores", postcode: 2483, lga: "Byron (Area)" },
  { suburb: "South Golden Beach", postcode: 2483, lga: "Byron (Area)" },
  { suburb: "Middle Pocket", postcode: 2483, lga: "Byron (Area)" },
  { suburb: "Mullumbimby", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Upper Wilsons Creek", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Wilsons Creek", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Upper Main Arm", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Huonbrook", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Goonengerry", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Byron Bay", postcode: 2481, lga: "Byron (Area)" },
  { suburb: "Suffolk Park", postcode: 2481, lga: "Byron (Area)" },
  { suburb: "Skinners Shoot", postcode: 2481, lga: "Byron (Area)" },
  { suburb: "Main Arm", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Federal", postcode: 2480, lga: "Byron (Area)" },
  { suburb: "Coorabell", postcode: 2479, lga: "Byron (Area)" },
  { suburb: "Montecollum", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Mullumbimby Creek", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Wanganui", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Upper Coopers Creek", postcode: 2482, lga: "Byron (Area)" },
  { suburb: "Myocum", postcode: 2481, lga: "Byron (Area)" },
  { suburb: "Billinudgel", postcode: 2483, lga: "Byron (Area)" },
  { suburb: "Yelgun", postcode: 2483, lga: "Byron (Area)" },
  { suburb: "Ewingsdale", postcode: 2481, lga: "Byron (Area)" },
  { suburb: "Bangalow", postcode: 2479, lga: "Byron (Area)" },
  { suburb: "Wooyung", postcode: 2483, lga: "Byron (Area)" },
  { suburb: "Eureka", postcode: 2480, lga: "Byron (Area)" },
  { suburb: "Clunes", postcode: 2480, lga: "Byron (Area)" },
  { suburb: "Nashua", postcode: 2479, lga: "Byron (Area)" },
  { suburb: "McLeods Shoot", postcode: 2479, lga: "Byron (Area)" },
  { suburb: "Binna Burra", postcode: 2479, lga: "Byron (Area)" },
  { suburb: "Possum Creek", postcode: 2479, lga: "Byron (Area)" },
  { suburb: "Hayters Hill", postcode: 2481, lga: "Byron (Area)" },
  { suburb: "Talofa", postcode: 2481, lga: "Byron (Area)" },
  { suburb: "Ophir", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Lidster", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Cargo", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Bowan Park", postcode: 2864, lga: "Cabonne (Area)" },
  { suburb: "Cadia", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Waldegrave", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "March", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Clifton Grove", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Summer Hill Creek", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Four Mile Creek", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Lewis Ponds", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Lower Lewis Ponds", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Borenore", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Nashdale", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Canobolas", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Belgravia", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Springside", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Orange", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Byng", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Spring Terrace", postcode: 2798, lga: "Cabonne (Area)" },
  { suburb: "Kangaroobie", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Emu Swamp", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Eugowra", postcode: 2806, lga: "Cabonne (Area)" },
  { suburb: "Yullundry", postcode: 2867, lga: "Cabonne (Area)" },
  { suburb: "Yeoval", postcode: 2868, lga: "Cabonne (Area)" },
  { suburb: "Canowindra", postcode: 2804, lga: "Cabonne (Area)" },
  { suburb: "Garra", postcode: 2866, lga: "Cabonne (Area)" },
  { suburb: "Molong", postcode: 2866, lga: "Cabonne (Area)" },
  { suburb: "Gumble", postcode: 2865, lga: "Cabonne (Area)" },
  { suburb: "Boomey", postcode: 2866, lga: "Cabonne (Area)" },
  { suburb: "Obley", postcode: 2868, lga: "Cabonne (Area)" },
  { suburb: "Gooloogong", postcode: 2805, lga: "Cabonne (Area)" },
  { suburb: "Nyrang Creek", postcode: 2804, lga: "Cabonne (Area)" },
  { suburb: "Cudal", postcode: 2864, lga: "Cabonne (Area)" },
  { suburb: "Cumnock", postcode: 2867, lga: "Cabonne (Area)" },
  { suburb: "Manildra", postcode: 2865, lga: "Cabonne (Area)" },
  { suburb: "Boree", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Murga", postcode: 2864, lga: "Cabonne (Area)" },
  { suburb: "Moorbel", postcode: 2804, lga: "Cabonne (Area)" },
  { suburb: "Amaroo", postcode: 2866, lga: "Cabonne (Area)" },
  { suburb: "Toogong", postcode: 2864, lga: "Cabonne (Area)" },
  { suburb: "Baldry", postcode: 2867, lga: "Cabonne (Area)" },
  { suburb: "Larras Lee", postcode: 2866, lga: "Cabonne (Area)" },
  { suburb: "Eurimbla", postcode: 2867, lga: "Cabonne (Area)" },
  { suburb: "Mandagery", postcode: 2870, lga: "Cabonne (Area)" },
  { suburb: "Mullion Creek", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Kerrs Creek", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Clergate", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Camden South", postcode: 2570, lga: "Camden (Area)" },
  { suburb: "Camden", postcode: 2570, lga: "Camden (Area)" },
  { suburb: "Elderslie", postcode: 2570, lga: "Camden (Area)" },
  { suburb: "Harrington Park", postcode: 2567, lga: "Camden (Area)" },
  { suburb: "Narellan Vale", postcode: 2567, lga: "Camden (Area)" },
  { suburb: "Narellan", postcode: 2567, lga: "Camden (Area)" },
  { suburb: "Mount Annan", postcode: 2567, lga: "Camden (Area)" },
  { suburb: "Currans Hill", postcode: 2567, lga: "Camden (Area)" },
  { suburb: "Leppington", postcode: 2179, lga: "Camden (Area)" },
  { suburb: "Gregory Hills", postcode: 2557, lga: "Camden (Area)" },
  { suburb: "Rossmore", postcode: 2557, lga: "Camden (Area)" },
  { suburb: "Ellis Lane", postcode: 2570, lga: "Camden (Area)" },
  { suburb: "Cawdor", postcode: 2570, lga: "Camden (Area)" },
  { suburb: "Kirkham", postcode: 2570, lga: "Camden (Area)" },
  { suburb: "Catherine Field", postcode: 2557, lga: "Camden (Area)" },
  { suburb: "Bringelly", postcode: 2556, lga: "Camden (Area)" },
  { suburb: "Cobbitty", postcode: 2570, lga: "Camden (Area)" },
  { suburb: "Smeaton Grange", postcode: 2567, lga: "Camden (Area)" },
  { suburb: "Grasmere", postcode: 2570, lga: "Camden (Area)" },
  { suburb: "Kearns", postcode: 2558, lga: "Campbelltown (City)" },
  { suburb: "Raby", postcode: 2566, lga: "Campbelltown (City)" },
  { suburb: "Eschol Park", postcode: 2558, lga: "Campbelltown (City)" },
  { suburb: "Eagle Vale", postcode: 2558, lga: "Campbelltown (City)" },
  { suburb: "St Andrews", postcode: 2566, lga: "Campbelltown (City)" },
  { suburb: "Ingleburn", postcode: 2565, lga: "Campbelltown (City)" },
  { suburb: "Bow Bowing", postcode: 2566, lga: "Campbelltown (City)" },
  { suburb: "Denham Court", postcode: 2565, lga: "Campbelltown (City)" },
  { suburb: "Minto", postcode: 2566, lga: "Campbelltown (City)" },
  { suburb: "Macquarie Fields", postcode: 2564, lga: "Campbelltown (City)" },
  { suburb: "Glenfield", postcode: 2167, lga: "Campbelltown (City)" },
  { suburb: "Blairmount", postcode: 2559, lga: "Campbelltown (City)" },
  { suburb: "Claymore", postcode: 2559, lga: "Campbelltown (City)" },
  { suburb: "Kentlyn", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Macquarie Links", postcode: 2565, lga: "Campbelltown (City)" },
  { suburb: "Minto Heights", postcode: 2566, lga: "Campbelltown (City)" },
  { suburb: "Varroville", postcode: 2566, lga: "Campbelltown (City)" },
  { suburb: "Long Point", postcode: 2564, lga: "Campbelltown (City)" },
  { suburb: "Campbelltown", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Bradbury", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Wedderburn", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "St Helens Park", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Leumeah", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Ambarvale", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Glen Alpine", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Rosemeadow", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Woodbine", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Gilead", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Holsworthy", postcode: 2173, lga: "Campbelltown (City)" },
  { suburb: "Woronora Dam", postcode: 2508, lga: "Campbelltown (City)" },
  { suburb: "Ruse", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Airds", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Blair Athol", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Menangle Park", postcode: 2563, lga: "Campbelltown (City)" },
  { suburb: "Englorie Park", postcode: 2560, lga: "Campbelltown (City)" },
  { suburb: "Concord West", postcode: 2138, lga: "Canada Bay (Area)" },
  { suburb: "North Strathfield", postcode: 2137, lga: "Canada Bay (Area)" },
  { suburb: "Concord", postcode: 2137, lga: "Canada Bay (Area)" },
  { suburb: "Cabarita", postcode: 2137, lga: "Canada Bay (Area)" },
  { suburb: "Rhodes", postcode: 2138, lga: "Canada Bay (Area)" },
  { suburb: "Breakfast Point", postcode: 2137, lga: "Canada Bay (Area)" },
  { suburb: "Liberty Grove", postcode: 2138, lga: "Canada Bay (Area)" },
  { suburb: "Mortlake", postcode: 2137, lga: "Canada Bay (Area)" },
  { suburb: "Five Dock", postcode: 2046, lga: "Canada Bay (Area)" },
  { suburb: "Abbotsford", postcode: 2046, lga: "Canada Bay (Area)" },
  { suburb: "Chiswick", postcode: 2046, lga: "Canada Bay (Area)" },
  { suburb: "Russell Lea", postcode: 2046, lga: "Canada Bay (Area)" },
  { suburb: "Rodd Point", postcode: 2046, lga: "Canada Bay (Area)" },
  { suburb: "Drummoyne", postcode: 2047, lga: "Canada Bay (Area)" },
  { suburb: "Canada Bay", postcode: 2046, lga: "Canada Bay (Area)" },
  { suburb: "Wareemba", postcode: 2046, lga: "Canada Bay (Area)" },
  { suburb: "Riverwood", postcode: 2210, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Roselands", postcode: 2196, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Belmore", postcode: 2192, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Belfield", postcode: 2191, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Kingsgrove", postcode: 2208, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Campsie", postcode: 2194, lga: "Canterbury-Bankstown (Area)" },
  {
    suburb: "Clemton Park",
    postcode: 2206,
    lga: "Canterbury-Bankstown (Area)",
  },
  { suburb: "Earlwood", postcode: 2206, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Canterbury", postcode: 2193, lga: "Canterbury-Bankstown (Area)" },
  {
    suburb: "Beverly Hills",
    postcode: 2209,
    lga: "Canterbury-Bankstown (Area)",
  },
  { suburb: "Narwee", postcode: 2209, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Wiley Park", postcode: 2195, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Lakemba", postcode: 2195, lga: "Canterbury-Bankstown (Area)" },
  { suburb: "Goolgowi", postcode: 2652, lga: "Carrathool (Area)" },
  { suburb: "Melbergen", postcode: 2669, lga: "Carrathool (Area)" },
  { suburb: "Erigolia", postcode: 2669, lga: "Carrathool (Area)" },
  { suburb: "Merriwagga", postcode: 2652, lga: "Carrathool (Area)" },
  { suburb: "Carrathool", postcode: 2711, lga: "Murrumbidgee (Area)" },
  { suburb: "Willbriggie", postcode: 2680, lga: "Carrathool (Area)" },
  { suburb: "Yenda", postcode: 2681, lga: "Carrathool (Area)" },
  { suburb: "Binya", postcode: 2665, lga: "Carrathool (Area)" },
  { suburb: "Hillston", postcode: 2675, lga: "Carrathool (Area)" },
  { suburb: "Roto", postcode: 2675, lga: "Carrathool (Area)" },
  { suburb: "Wallanthery", postcode: 2675, lga: "Carrathool (Area)" },
  { suburb: "Boorga", postcode: 2652, lga: "Carrathool (Area)" },
  { suburb: "Tabbita", postcode: 2652, lga: "Carrathool (Area)" },
  { suburb: "Benerembah", postcode: 2680, lga: "Carrathool (Area)" },
  { suburb: "Warrawidgee", postcode: 2680, lga: "Carrathool (Area)" },
  { suburb: "Monia Gap", postcode: 2675, lga: "Carrathool (Area)" },
  { suburb: "Yorklea", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Naughtons Gap", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Tomki", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Coraki", postcode: 2471, lga: "Richmond Valley (Area)" },
  { suburb: "Evans Head", postcode: 2473, lga: "Richmond Valley (Area)" },
  { suburb: "New Italy", postcode: 2472, lga: "Richmond Valley (Area)" },
  { suburb: "Stratheden", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Fairy Hill", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Backmede", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "North Casino", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Bentley", postcode: 2480, lga: "Richmond Valley (Area)" },
  { suburb: "Spring Grove", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Irvington", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "McKees Hill", postcode: 2480, lga: "Richmond Valley (Area)" },
  { suburb: "Greenridge", postcode: 2471, lga: "Richmond Valley (Area)" },
  { suburb: "Dobies Bight", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Woodview", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Piora", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Hogarth Range", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Upper Mongogarie", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Wyan", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Coombell", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Mongogarie", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Busbys Flat", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Six Mile Swamp", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Mount Marsh", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Kippenduff", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Myrtle Creek", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Camira", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Clearfield", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Whiporie", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Gibberagee", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Shannon Brook", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Rappville", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Ellangowan", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Leeville", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "West Bungawalbin", postcode: 2471, lga: "Richmond Valley (Area)" },
  { suburb: "Bora Ridge", postcode: 2471, lga: "Richmond Valley (Area)" },
  { suburb: "Tatham", postcode: 2471, lga: "Richmond Valley (Area)" },
  { suburb: "Bungawalbin", postcode: 2469, lga: "Richmond Valley (Area)" },
  { suburb: "Esk", postcode: 2472, lga: "Richmond Valley (Area)" },
  { suburb: "Codrington", postcode: 2471, lga: "Richmond Valley (Area)" },
  { suburb: "West Coraki", postcode: 2471, lga: "Richmond Valley (Area)" },
  { suburb: "East Coraki", postcode: 2471, lga: "Richmond Valley (Area)" },
  { suburb: "Swan Bay", postcode: 2471, lga: "Richmond Valley (Area)" },
  { suburb: "Woodburn", postcode: 2472, lga: "Richmond Valley (Area)" },
  { suburb: "Doonbah", postcode: 2473, lga: "Richmond Valley (Area)" },
  { suburb: "Rileys Hill", postcode: 2472, lga: "Richmond Valley (Area)" },
  { suburb: "Broadwater", postcode: 2472, lga: "Richmond Valley (Area)" },
  { suburb: "Tabbimoble", postcode: 2472, lga: "Richmond Valley (Area)" },
  { suburb: "The Gap", postcode: 2472, lga: "Richmond Valley (Area)" },
  { suburb: "Menindee", postcode: 2879, lga: "Central Darling (Area)" },
  { suburb: "Wilcannia", postcode: 2836, lga: "Central Darling (Area)" },
  { suburb: "Tilpa", postcode: 2840, lga: "Central Darling (Area)" },
  { suburb: "Weston", postcode: 2326, lga: "Cessnock (City)" },
  { suburb: "Loxford", postcode: 2326, lga: "Cessnock (City)" },
  { suburb: "Kurri Kurri", postcode: 2327, lga: "Cessnock (City)" },
  { suburb: "Bellbird", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Abermain", postcode: 2326, lga: "Cessnock (City)" },
  { suburb: "Paxton", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Kitchener", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Abernethy", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Cessnock", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Pelton", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Ellalong", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Mount View", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Greta Main", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Sawyers Gully", postcode: 2326, lga: "Cessnock (City)" },
  { suburb: "Lovedale", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Neath", postcode: 2326, lga: "Cessnock (City)" },
  { suburb: "Kearsley", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Nulkaba", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Cliftleigh", postcode: 2321, lga: "Cessnock (City)" },
  { suburb: "Fernances Crossing", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Keinbah", postcode: 2320, lga: "Cessnock (City)" },
  { suburb: "Black Hill", postcode: 2322, lga: "Cessnock (City)" },
  { suburb: "Paynes Crossing", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Wollombi", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Narone Creek", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Murrays Run", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Watagan", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "North Rothbury", postcode: 2335, lga: "Cessnock (City)" },
  { suburb: "Pokolbin", postcode: 2320, lga: "Cessnock (City)" },
  { suburb: "Greta", postcode: 2334, lga: "Cessnock (City)" },
  { suburb: "Aberdare", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "East Branxton", postcode: 2335, lga: "Cessnock (City)" },
  { suburb: "Branxton", postcode: 2335, lga: "Cessnock (City)" },
  { suburb: "Bellbird Heights", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Elrington", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Mulbring", postcode: 2323, lga: "Cessnock (City)" },
  { suburb: "Stockrington", postcode: 2322, lga: "Cessnock (City)" },
  { suburb: "Yengo National Park", postcode: 2330, lga: "Cessnock (City)" },
  { suburb: "Olney", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Buchanan", postcode: 2323, lga: "Cessnock (City)" },
  { suburb: "Cedar Creek", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Mount Vincent", postcode: 2323, lga: "Cessnock (City)" },
  { suburb: "Bucketty", postcode: 2250, lga: "Cessnock (City)" },
  { suburb: "Bishops Bridge", postcode: 2326, lga: "Cessnock (City)" },
  { suburb: "Richmond Vale", postcode: 2323, lga: "Cessnock (City)" },
  { suburb: "Pelaw Main", postcode: 2327, lga: "Cessnock (City)" },
  { suburb: "Millfield", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Heddon Greta", postcode: 2321, lga: "Cessnock (City)" },
  { suburb: "Stanford Merthyr", postcode: 2327, lga: "Cessnock (City)" },
  { suburb: "Congewai", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Dairy Arm", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Laguna", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Corrabare", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Brunkerville", postcode: 2323, lga: "Cessnock (City)" },
  { suburb: "Quorrobolong", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Buttai", postcode: 2323, lga: "Cessnock (City)" },
  { suburb: "Sweetmans Creek", postcode: 2325, lga: "Cessnock (City)" },
  { suburb: "Allandale", postcode: 2320, lga: "Cessnock (City)" },
  { suburb: "The Sandon", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Diggers Camp", postcode: 2462, lga: "Clarence Valley (Area)" },
  { suburb: "Minnie Water", postcode: 2462, lga: "Clarence Valley (Area)" },
  { suburb: "Barcoongere", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Sandy Crossing", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Sandon", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Pillar Valley", postcode: 2462, lga: "Clarence Valley (Area)" },
  { suburb: "Bookram", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Wooli", postcode: 2462, lga: "Clarence Valley (Area)" },
  { suburb: "Coldstream", postcode: 2462, lga: "Clarence Valley (Area)" },
  { suburb: "Gilletts Ridge", postcode: 2462, lga: "Clarence Valley (Area)" },
  { suburb: "Cowper", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Glenugie", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Halfway Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Tucabia", postcode: 2462, lga: "Clarence Valley (Area)" },
  { suburb: "Ulmarra", postcode: 2462, lga: "Clarence Valley (Area)" },
  { suburb: "Calliope", postcode: 2462, lga: "Clarence Valley (Area)" },
  { suburb: "Maclean", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Freeburn Island", postcode: 2464, lga: "Clarence Valley (Area)" },
  { suburb: "Brooms Head", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Yamba", postcode: 2464, lga: "Clarence Valley (Area)" },
  { suburb: "Yuraygir", postcode: 2464, lga: "Clarence Valley (Area)" },
  { suburb: "Wooloweyah", postcode: 2464, lga: "Clarence Valley (Area)" },
  { suburb: "Iluka", postcode: 2466, lga: "Clarence Valley (Area)" },
  { suburb: "Micalo Island", postcode: 2464, lga: "Clarence Valley (Area)" },
  { suburb: "Lawrence", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Tullymorgan", postcode: 2463, lga: "Clarence Valley (Area)" },
  {
    suburb: "Jacky Bulbin Flat",
    postcode: 2463,
    lga: "Clarence Valley (Area)",
  },
  { suburb: "Mororo", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Warregah Island", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Palmers Channel", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Palmers Island", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Woody Head", postcode: 2466, lga: "Clarence Valley (Area)" },
  { suburb: "Woombah", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Goodwood Island", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Woodford Island", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Ilarwill", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Ashby Island", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "The Freshwater", postcode: 2466, lga: "Clarence Valley (Area)" },
  { suburb: "Brushgrove", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Townsend", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "South Arm", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Tyndale", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Chatsworth", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Harwood", postcode: 2465, lga: "Clarence Valley (Area)" },
  { suburb: "Ashby Heights", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Angourie", postcode: 2464, lga: "Clarence Valley (Area)" },
  { suburb: "Gulmarrad", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Ashby", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Swan Creek", postcode: 2462, lga: "Clarence Valley (Area)" },
  { suburb: "Clarenza", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Braunstone", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Coutts Crossing", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Elland", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Ramornie", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Eatonsville", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Waterview", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Seelands", postcode: 2460, lga: "Clarence Valley (Area)" },
  {
    suburb: "Waterview Heights",
    postcode: 2460,
    lga: "Clarence Valley (Area)",
  },
  { suburb: "Rushforth", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Grafton", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "South Grafton", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Carrs Island", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Junction Hill", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Great Marlow", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Crowther Island", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Alumy Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Carrs Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Southgate", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Carrs Peninsula", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Trenayr", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Bom Bom", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Lanitza", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Sherwood", postcode: 2450, lga: "Clarence Valley (Area)" },
  { suburb: "Glenreagh", postcode: 2450, lga: "Clarence Valley (Area)" },
  { suburb: "Kungala", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Kremnos", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Wells Crossing", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Marengo", postcode: 2453, lga: "Clarence Valley (Area)" },
  { suburb: "Cangai", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Jackadgery", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Coombadjha", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Billys Creek", postcode: 2453, lga: "Clarence Valley (Area)" },
  { suburb: "Lilydale", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Chaelundi", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Heifer Station", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Dundurrabin", postcode: 2453, lga: "Clarence Valley (Area)" },
  { suburb: "Buccarumbi", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Chambigne", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Clouds Creek", postcode: 2453, lga: "Clarence Valley (Area)" },
  { suburb: "Nymboida", postcode: 2460, lga: "Clarence Valley (Area)" },
  {
    suburb: "Wild Cattle Creek",
    postcode: 2453,
    lga: "Clarence Valley (Area)",
  },
  { suburb: "Moonpar", postcode: 2453, lga: "Clarence Valley (Area)" },
  { suburb: "Levenstrath", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Hernani", postcode: 2453, lga: "Clarence Valley (Area)" },
  { suburb: "Towallum", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Tyringham", postcode: 2453, lga: "Clarence Valley (Area)" },
  { suburb: "Blaxlands Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Newton Boyd", postcode: 2370, lga: "Clarence Valley (Area)" },
  { suburb: "Kangaroo Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Shannondale", postcode: 2460, lga: "Clarence Valley (Area)" },
  {
    suburb: "Upper Fine Flower",
    postcode: 2460,
    lga: "Clarence Valley (Area)",
  },
  { suburb: "Stockyard Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Lower Southgate", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Coaldale", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Gurranang", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Ewingar", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Bulldog", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Mookima Wybra", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Louisa Creek", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Pikapene", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Lionsville", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Alice", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Keybarbin", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Coongbar", postcode: 2469, lga: "Clarence Valley (Area)" },
  { suburb: "Deep Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Banyabba", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Clifden", postcode: 2460, lga: "Clarence Valley (Area)" },
  {
    suburb: "Upper Copmanhurst",
    postcode: 2460,
    lga: "Clarence Valley (Area)",
  },
  { suburb: "Whiteman Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "The Whiteman", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Mylneford", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Mountain View", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Koolkhan", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Dilkoon", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Fortis Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Warragai Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Washpool", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Copmanhurst", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Eighteen Mile", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Collum Collum", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Dumbudgery", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Fine Flower", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Carnham", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Barretts Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Pulganbar", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Wombat Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Smiths Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Newbold", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Winegrove", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "The Pinnacles", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Moleville Creek", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Kyarran", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Noona", postcode: 2835, lga: "Cobar (Area)" },
  { suburb: "Cobar", postcode: 2835, lga: "Cobar (Area)" },
  { suburb: "Kerrigundi", postcode: 2835, lga: "Cobar (Area)" },
  { suburb: "Kulwin", postcode: 2835, lga: "Cobar (Area)" },
  { suburb: "Nymagee", postcode: 2831, lga: "Cobar (Area)" },
  { suburb: "Eremerang", postcode: 2877, lga: "Cobar (Area)" },
  { suburb: "Tindarey", postcode: 2835, lga: "Cobar (Area)" },
  { suburb: "Irymple", postcode: 2835, lga: "Cobar (Area)" },
  { suburb: "Cubba", postcode: 2835, lga: "Cobar (Area)" },
  { suburb: "Bulla", postcode: 2835, lga: "Cobar (Area)" },
  { suburb: "Mount Hope", postcode: 2877, lga: "Cobar (Area)" },
  { suburb: "Boambee East", postcode: 2452, lga: "Coffs Harbour (City)" },
  { suburb: "Toormina", postcode: 2452, lga: "Coffs Harbour (City)" },
  { suburb: "Sawtell", postcode: 2452, lga: "Coffs Harbour (City)" },
  { suburb: "Coffs Harbour", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Moonee Beach", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Bucca", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Sapphire Beach", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Korora", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Bundagen", postcode: 2454, lga: "Coffs Harbour (City)" },
  { suburb: "Bonville", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Upper Orara", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Boambee", postcode: 2450, lga: "Coffs Harbour (City)" },
  {
    suburb: "North Boambee Valley",
    postcode: 2450,
    lga: "Coffs Harbour (City)",
  },
  { suburb: "Karangi", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Emerald Beach", postcode: 2456, lga: "Coffs Harbour (City)" },
  { suburb: "Woolgoolga", postcode: 2456, lga: "Coffs Harbour (City)" },
  { suburb: "Corindi Beach", postcode: 2456, lga: "Coffs Harbour (City)" },
  { suburb: "Sandy Beach", postcode: 2456, lga: "Coffs Harbour (City)" },
  { suburb: "Arrawarra Headland", postcode: 2456, lga: "Coffs Harbour (City)" },
  { suburb: "Mullaway", postcode: 2456, lga: "Coffs Harbour (City)" },
  { suburb: "Red Rock", postcode: 2456, lga: "Coffs Harbour (City)" },
  { suburb: "Lowanna", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Brooklana", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Upper Corindi", postcode: 2456, lga: "Coffs Harbour (City)" },
  { suburb: "Coramba", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Nana Glen", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Ulong", postcode: 2450, lga: "Coffs Harbour (City)" },
  { suburb: "Dirty Creek", postcode: 2456, lga: "Coffs Harbour (City)" },
  { suburb: "Arrawarra", postcode: 2456, lga: "Coffs Harbour (City)" },
  { suburb: "Safety Beach", postcode: 2456, lga: "Coffs Harbour (City)" },
  { suburb: "Conargo", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Blighty", postcode: 2713, lga: "Edward River (Area)" },
  { suburb: "Wanganella", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Coree", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Logie Brae", postcode: 2713, lga: "Edward River (Area)" },
  { suburb: "Lindifferon", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Stud Park", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Wandook", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Deniliquin", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Booroorban", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Mabins Well", postcode: 2716, lga: "Edward River (Area)" },
  { suburb: "Moonbria", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Moulamein", postcode: 2733, lga: "Edward River (Area)" },
  { suburb: "Warragoon", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Birganbigil", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Myrtle Park", postcode: 2713, lga: "Edward River (Area)" },
  { suburb: "Tuppal", postcode: 2714, lga: "Edward River (Area)" },
  { suburb: "Pine Lodge", postcode: 2714, lga: "Edward River (Area)" },
  { suburb: "Four Corners", postcode: 2716, lga: "Edward River (Area)" },
  { suburb: "Ganmain", postcode: 2702, lga: "Coolamon (Area)" },
  { suburb: "Coolamon", postcode: 2701, lga: "Coolamon (Area)" },
  { suburb: "Rannock", postcode: 2701, lga: "Coolamon (Area)" },
  { suburb: "Cowabbie", postcode: 2652, lga: "Coolamon (Area)" },
  { suburb: "Marrar", postcode: 2652, lga: "Coolamon (Area)" },
  { suburb: "Walleroobie", postcode: 2665, lga: "Coolamon (Area)" },
  { suburb: "Matong", postcode: 2652, lga: "Coolamon (Area)" },
  { suburb: "Methul", postcode: 2701, lga: "Coolamon (Area)" },
  { suburb: "Berry Jerry", postcode: 2701, lga: "Coolamon (Area)" },
  { suburb: "Murrulebale", postcode: 2652, lga: "Coolamon (Area)" },
  {
    suburb: "Williamsdale",
    postcode: 2620,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "The Angle", postcode: 2620, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Bumbalong", postcode: 2626, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Michelago", postcode: 2620, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Tinderry", postcode: 2620, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Clear Range",
    postcode: 2620,
    lga: "Snowy Monaro Regional (Area)",
  },
  {
    suburb: "Billilingra",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Cooma", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Middle Flat",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  {
    suburb: "Glen Fergus",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Yaouk", postcode: 2629, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Countegany", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Carlaminda", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Chakola", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Rose Valley",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Badja", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Peak View", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Tantangara", postcode: 2629, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Greenlands", postcode: 2631, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Winifred", postcode: 2631, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Jingera", postcode: 2622, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Anembo", postcode: 2621, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Bredbo", postcode: 2626, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Numeralla", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Polo Flat", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Bunyan", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Binjura", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Shannons Flat",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Nimmitabel", postcode: 2631, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Dairymans Plains",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Tuross", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Maffra", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Colinton", postcode: 2626, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Murrumbucca",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  {
    suburb: "Springfield",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Dangelong", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Jerangle", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Kybeyan", postcode: 2631, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Rock Flat", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Burra", postcode: 2620, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Conimbia", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Gungalman", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Macquarie Marshes", postcode: 2831, lga: "Coonamble (Area)" },
  { suburb: "Coonamble", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Gulargambone", postcode: 2828, lga: "Coonamble (Area)" },
  { suburb: "Gilgooma", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Billeroy", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Urawilkie", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Pilliga", postcode: 2388, lga: "Coonamble (Area)" },
  { suburb: "Teridgerie", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Nebea", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Pine Grove", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Magometon", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Tooloon", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Quambone", postcode: 2831, lga: "Coonamble (Area)" },
  { suburb: "Bourbah", postcode: 2828, lga: "Coonamble (Area)" },
  { suburb: "Combara", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Mount Tenandra", postcode: 2828, lga: "Coonamble (Area)" },
  { suburb: "Quanda", postcode: 2828, lga: "Coonamble (Area)" },
  { suburb: "Black Hollow", postcode: 2828, lga: "Coonamble (Area)" },
  { suburb: "Carinda", postcode: 2831, lga: "Coonamble (Area)" },
  { suburb: "Cootamundra", postcode: 2590, lga: "Gundagai (Area)" },
  { suburb: "Milvale", postcode: 2594, lga: "Gundagai (Area)" },
  { suburb: "Stockinbingal", postcode: 2725, lga: "Gundagai (Area)" },
  { suburb: "Wallendbeen", postcode: 2588, lga: "Gundagai (Area)" },
  { suburb: "Muttama", postcode: 2722, lga: "Gundagai (Area)" },
  { suburb: "Coolac", postcode: 2727, lga: "Gundagai (Area)" },
  { suburb: "Howlong", postcode: 2643, lga: "Federation (Area)" },
  { suburb: "Corowa", postcode: 2646, lga: "Federation (Area)" },
  { suburb: "Oaklands", postcode: 2646, lga: "Federation (Area)" },
  { suburb: "Daysdale", postcode: 2646, lga: "Federation (Area)" },
  { suburb: "Coreen", postcode: 2646, lga: "Federation (Area)" },
  { suburb: "Lowesdale", postcode: 2646, lga: "Federation (Area)" },
  { suburb: "Balldale", postcode: 2646, lga: "Federation (Area)" },
  { suburb: "Collendina", postcode: 2646, lga: "Federation (Area)" },
  { suburb: "Hopefield", postcode: 2646, lga: "Federation (Area)" },
  { suburb: "Ringwood", postcode: 2646, lga: "Federation (Area)" },
  { suburb: "Sanger", postcode: 2646, lga: "Federation (Area)" },
  { suburb: "Rennie", postcode: 2646, lga: "Federation (Area)" },
  { suburb: "Cowra", postcode: 2794, lga: "Cowra (Area)" },
  { suburb: "Woodstock", postcode: 2793, lga: "Cowra (Area)" },
  { suburb: "Roseberg", postcode: 2793, lga: "Cowra (Area)" },
  { suburb: "Billimari", postcode: 2804, lga: "Cowra (Area)" },
  { suburb: "Koorawatha", postcode: 2807, lga: "Cowra (Area)" },
  { suburb: "Darbys Falls", postcode: 2793, lga: "Cowra (Area)" },
  { suburb: "Bumbaldry", postcode: 2794, lga: "Cowra (Area)" },
  { suburb: "Wattamondara", postcode: 2794, lga: "Cowra (Area)" },
  { suburb: "Dubbo", postcode: 2830, lga: "Western Plains Regional (Area)" },
  { suburb: "Minore", postcode: 2830, lga: "Western Plains Regional (Area)" },
  {
    suburb: "Brocklehurst",
    postcode: 2830,
    lga: "Western Plains Regional (Area)",
  },
  { suburb: "Toongi", postcode: 2830, lga: "Western Plains Regional (Area)" },
  { suburb: "Mogriguy", postcode: 2830, lga: "Western Plains Regional (Area)" },
  {
    suburb: "Ballimore",
    postcode: 2830,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Wongarbon",
    postcode: 2831,
    lga: "Western Plains Regional (Area)",
  },
  { suburb: "Benolong", postcode: 2818, lga: "Western Plains Regional (Area)" },
  {
    suburb: "Wambangalang",
    postcode: 2830,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Elong Elong",
    postcode: 2831,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Goonoo Forest",
    postcode: 2830,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Terramungamine",
    postcode: 2830,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Eumungerie",
    postcode: 2822,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Rawsonville",
    postcode: 2830,
    lga: "Western Plains Regional (Area)",
  },
  { suburb: "Carrabolla", postcode: 2311, lga: "Dungog (Area)" },
  { suburb: "Upper Allyn", postcode: 2311, lga: "Dungog (Area)" },
  { suburb: "Salisbury", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Lostock", postcode: 2311, lga: "Dungog (Area)" },
  { suburb: "Bingleburra", postcode: 2311, lga: "Dungog (Area)" },
  { suburb: "Dungog", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Marshdale", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Mount Rivers", postcode: 2311, lga: "Dungog (Area)" },
  { suburb: "Chichester", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Main Creek", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Glen Oak", postcode: 2320, lga: "Dungog (Area)" },
  { suburb: "Clarence Town", postcode: 2321, lga: "Dungog (Area)" },
  { suburb: "Fosterton", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Glen William", postcode: 2321, lga: "Dungog (Area)" },
  { suburb: "Allynbrook", postcode: 2311, lga: "Dungog (Area)" },
  { suburb: "Halton", postcode: 2311, lga: "Dungog (Area)" },
  { suburb: "Eccleston", postcode: 2311, lga: "Dungog (Area)" },
  { suburb: "Underbank", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Bandon Grove", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Vacy", postcode: 2421, lga: "Dungog (Area)" },
  { suburb: "Summer Hill", postcode: 2421, lga: "Dungog (Area)" },
  { suburb: "Fishers Hill", postcode: 2421, lga: "Dungog (Area)" },
  { suburb: "Paterson", postcode: 2421, lga: "Dungog (Area)" },
  { suburb: "Martins Creek", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Gresford", postcode: 2311, lga: "Dungog (Area)" },
  { suburb: "East Gresford", postcode: 2311, lga: "Dungog (Area)" },
  { suburb: "Lewinsbrook", postcode: 2311, lga: "Dungog (Area)" },
  { suburb: "Cambra", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Flat Tops", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Bendolba", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Munni", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Alison", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Tabbil Creek", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Hanleys Creek", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Sugarloaf", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Stroud Hill", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Glen Martin", postcode: 2321, lga: "Dungog (Area)" },
  { suburb: "Webbers Creek", postcode: 2421, lga: "Dungog (Area)" },
  { suburb: "Tocal", postcode: 2421, lga: "Dungog (Area)" },
  { suburb: "Torryburn", postcode: 2421, lga: "Dungog (Area)" },
  { suburb: "Wirragulla", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Wallaringa", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Wallarobba", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Brookfield", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Hilldale", postcode: 2420, lga: "Dungog (Area)" },
  { suburb: "Moruya Heads", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Narooma", postcode: 2546, lga: "Eurobodalla (Area)" },
  { suburb: "Broulee", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Kianga", postcode: 2546, lga: "Eurobodalla (Area)" },
  { suburb: "Malua Bay", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Benandarah", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Dalmeny", postcode: 2546, lga: "Eurobodalla (Area)" },
  { suburb: "Tuross Head", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Guerilla Bay", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Mossy Point", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Batehaven", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "South Durras", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Deua River Valley", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Sunshine Bay", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Lilli Pilli", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Rosedale", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "North Narooma", postcode: 2546, lga: "Eurobodalla (Area)" },
  { suburb: "Belowra", postcode: 2545, lga: "Eurobodalla (Area)" },
  { suburb: "Merricumbene", postcode: 2622, lga: "Eurobodalla (Area)" },
  { suburb: "Mystery Bay", postcode: 2546, lga: "Eurobodalla (Area)" },
  { suburb: "Currowan", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Tilba Tilba", postcode: 2546, lga: "Eurobodalla (Area)" },
  { suburb: "Bodalla", postcode: 2545, lga: "Eurobodalla (Area)" },
  { suburb: "Potato Point", postcode: 2545, lga: "Eurobodalla (Area)" },
  { suburb: "Batemans Bay", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Corunna", postcode: 2546, lga: "Eurobodalla (Area)" },
  { suburb: "Neringla", postcode: 2622, lga: "Eurobodalla (Area)" },
  { suburb: "Deua", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Murrengenburg", postcode: 2622, lga: "Eurobodalla (Area)" },
  { suburb: "Buckenbowra", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Denhams Beach", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Surfside", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Akolele", postcode: 2546, lga: "Eurobodalla (Area)" },
  { suburb: "East Lynne", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Central Tilba", postcode: 2546, lga: "Eurobodalla (Area)" },
  { suburb: "Wamban", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Congo", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Moruya", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Long Beach", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Maloneys Beach", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Bingie", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Tomakin", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Nelligen", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "North Batemans Bay", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Catalina", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Kiora", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Jeremadra", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Surf Beach", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Nerrigundah", postcode: 2545, lga: "Eurobodalla (Area)" },
  { suburb: "Bergalia", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Bimbimbie", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Mogo", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Eurobodalla", postcode: 2545, lga: "Eurobodalla (Area)" },
  { suburb: "Turlinjah", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Mogendoura", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Runnyford", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Cadgee", postcode: 2545, lga: "Eurobodalla (Area)" },
  { suburb: "Woodlands", postcode: 2536, lga: "Eurobodalla (Area)" },
  { suburb: "Coila", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Meringo", postcode: 2537, lga: "Eurobodalla (Area)" },
  { suburb: "Mount Pritchard", postcode: 2170, lga: "Fairfield (City)" },
  { suburb: "Canley Heights", postcode: 2166, lga: "Fairfield (City)" },
  { suburb: "Cabramatta West", postcode: 2166, lga: "Fairfield (City)" },
  { suburb: "Fairfield West", postcode: 2165, lga: "Fairfield (City)" },
  { suburb: "Cabramatta", postcode: 2166, lga: "Fairfield (City)" },
  { suburb: "Smithfield", postcode: 2164, lga: "Fairfield (City)" },
  { suburb: "Fairfield", postcode: 2165, lga: "Fairfield (City)" },
  { suburb: "Canley Vale", postcode: 2166, lga: "Fairfield (City)" },
  { suburb: "Fairfield Heights", postcode: 2165, lga: "Fairfield (City)" },
  { suburb: "Lansvale", postcode: 2166, lga: "Fairfield (City)" },
  { suburb: "Fairfield East", postcode: 2165, lga: "Fairfield (City)" },
  { suburb: "Old Guildford", postcode: 2161, lga: "Fairfield (City)" },
  { suburb: "Yennora", postcode: 2161, lga: "Fairfield (City)" },
  { suburb: "Carramar", postcode: 2163, lga: "Fairfield (City)" },
  { suburb: "Greystanes", postcode: 2145, lga: "Cumberland (Area)" },
  { suburb: "Abbotsbury", postcode: 2176, lga: "Fairfield (City)" },
  { suburb: "Bonnyrigg Heights", postcode: 2177, lga: "Fairfield (City)" },
  { suburb: "Edensor Park", postcode: 2176, lga: "Fairfield (City)" },
  { suburb: "Bossley Park", postcode: 2176, lga: "Fairfield (City)" },
  { suburb: "Bonnyrigg", postcode: 2177, lga: "Fairfield (City)" },
  { suburb: "Greenfield Park", postcode: 2176, lga: "Fairfield (City)" },
  { suburb: "St Johns Park", postcode: 2176, lga: "Fairfield (City)" },
  { suburb: "Wetherill Park", postcode: 2164, lga: "Fairfield (City)" },
  { suburb: "Wakeley", postcode: 2176, lga: "Fairfield (City)" },
  { suburb: "Prairiewood", postcode: 2176, lga: "Fairfield (City)" },
  { suburb: "Horsley Park", postcode: 2175, lga: "Fairfield (City)" },
  { suburb: "Cecil Park", postcode: 2178, lga: "Fairfield (City)" },
  { suburb: "Wirrinya", postcode: 2871, lga: "Forbes (Area)" },
  { suburb: "Gunning Gap", postcode: 2871, lga: "Forbes (Area)" },
  { suburb: "Forbes", postcode: 2871, lga: "Forbes (Area)" },
  { suburb: "Cookamidgera", postcode: 2870, lga: "Forbes (Area)" },
  { suburb: "Mulyandry", postcode: 2871, lga: "Forbes (Area)" },
  { suburb: "Ootha", postcode: 2875, lga: "Forbes (Area)" },
  { suburb: "Corinella", postcode: 2871, lga: "Forbes (Area)" },
  { suburb: "Yarrabandai", postcode: 2875, lga: "Forbes (Area)" },
  { suburb: "Ooma", postcode: 2871, lga: "Forbes (Area)" },
  { suburb: "Paytens Bridge", postcode: 2871, lga: "Forbes (Area)" },
  { suburb: "Garema", postcode: 2871, lga: "Forbes (Area)" },
  { suburb: "Jemalong", postcode: 2871, lga: "Forbes (Area)" },
  { suburb: "Warroo", postcode: 2871, lga: "Forbes (Area)" },
  { suburb: "Mulguthrie", postcode: 2877, lga: "Forbes (Area)" },
  { suburb: "Derriwong", postcode: 2877, lga: "Forbes (Area)" },
  { suburb: "Gilgandra", postcode: 2827, lga: "Gilgandra (Area)" },
  { suburb: "Bearbong", postcode: 2827, lga: "Gilgandra (Area)" },
  { suburb: "Armatree", postcode: 2828, lga: "Gilgandra (Area)" },
  { suburb: "Tooraweenah", postcode: 2817, lga: "Gilgandra (Area)" },
  { suburb: "Kickabil", postcode: 2830, lga: "Gilgandra (Area)" },
  { suburb: "Collie", postcode: 2827, lga: "Gilgandra (Area)" },
  { suburb: "Tonderburine", postcode: 2817, lga: "Gilgandra (Area)" },
  { suburb: "Balladoran", postcode: 2822, lga: "Gilgandra (Area)" },
  { suburb: "Curban", postcode: 2827, lga: "Gilgandra (Area)" },
  { suburb: "Breelong", postcode: 2827, lga: "Gilgandra (Area)" },
  { suburb: "Mendooran", postcode: 2842, lga: "Gilgandra (Area)" },
  { suburb: "Biddon", postcode: 2827, lga: "Gilgandra (Area)" },
  { suburb: "Merrigal", postcode: 2827, lga: "Gilgandra (Area)" },
  { suburb: "Glen Innes", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Matheson", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Rangers Valley", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Reddestone", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Diehard", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Kingsgate", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Glen Elgin", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Kookabookra", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Bald Nob", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  {
    suburb: "Wellington Vale",
    postcode: 2371,
    lga: "Glen Innes Severn (Area)",
  },
  { suburb: "Torrington", postcode: 2371, lga: "Glen Innes Severn (Area)" },
  { suburb: "Stannum", postcode: 2371, lga: "Glen Innes Severn (Area)" },
  { suburb: "Emmaville", postcode: 2371, lga: "Glen Innes Severn (Area)" },
  { suburb: "Deepwater", postcode: 2371, lga: "Glen Innes Severn (Area)" },
  { suburb: "Dundee", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Shannon Vale", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Red Range", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Lambs Valley", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Furracabad", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Stonehenge", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Glencoe", postcode: 2365, lga: "Glen Innes Severn (Area)" },
  { suburb: "Mount Mitchell", postcode: 2365, lga: "Glen Innes Severn (Area)" },
  { suburb: "Yarrowford", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Capoompeta", postcode: 2371, lga: "Glen Innes Severn (Area)" },
  { suburb: "Ben Lomond", postcode: 2365, lga: "Glen Innes Severn (Area)" },
  { suburb: "Maybole", postcode: 2365, lga: "Glen Innes Severn (Area)" },
  {
    suburb: "Gibraltar Range",
    postcode: 2370,
    lga: "Glen Innes Severn (Area)",
  },
  { suburb: "Moggs Swamp", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Pinkett", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Moogem", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Morven", postcode: 2370, lga: "Glen Innes Severn (Area)" },
  { suburb: "Gloucester", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Wallanbah", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Cobark", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Rawdon Vale", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Berrico", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Stratford", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Craven", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Invergordon", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Bundook", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Barrington", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Forbesdale", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Tugrabakh", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Waukivory", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Mograni", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Copeland", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Bindera", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Tibbuc", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Woko", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Giro", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Bretti", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Callaghans Creek", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Bowman Farm", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Terreel", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Bowman", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Mares Run", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Baxters Ridge", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Mernot", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Curricabark", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Dewitt", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Coneac", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Tomalla", postcode: 2337, lga: "Mid-Coast (Area)" },
  { suburb: "Barrington Tops", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Craven Plateau", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Titaatee Creek", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Belbora", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Bakers Creek", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Gangat", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Kia Ora", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Bulliac", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Back Creek", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Tiri", postcode: 2424, lga: "Mid-Coast (Area)" },
  { suburb: "Davistown", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Saratoga", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Empire Bay", postcode: 2257, lga: "Central Coast (City)" },
  { suburb: "Green Point", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Killcare", postcode: 2257, lga: "Central Coast (City)" },
  { suburb: "Killcare Heights", postcode: 2257, lga: "Central Coast (City)" },
  { suburb: "Kincumber", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Copacabana", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Terrigal", postcode: 2260, lga: "Central Coast (City)" },
  { suburb: "Wamberal", postcode: 2260, lga: "Central Coast (City)" },
  { suburb: "Forresters Beach", postcode: 2260, lga: "Central Coast (City)" },
  { suburb: "Holgate", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Macmasters Beach", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Box Head", postcode: 2257, lga: "Central Coast (City)" },
  { suburb: "Pretty Beach", postcode: 2257, lga: "Central Coast (City)" },
  { suburb: "Hardys Bay", postcode: 2257, lga: "Central Coast (City)" },
  { suburb: "North Avoca", postcode: 2260, lga: "Central Coast (City)" },
  { suburb: "Matcham", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Erina Heights", postcode: 2260, lga: "Central Coast (City)" },
  { suburb: "Erina", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Yattalunga", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Bensville", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Avoca Beach", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Picketts Valley", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Bouddi", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Daleys Point", postcode: 2257, lga: "Central Coast (City)" },
  { suburb: "Green Point", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Lisarow", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Mount Elliot", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Wyoming", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "East Gosford", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Wagstaffe", postcode: 2257, lga: "Central Coast (City)" },
  { suburb: "St Huberts Island", postcode: 2257, lga: "Central Coast (City)" },
  { suburb: "Kincumber South", postcode: 2251, lga: "Central Coast (City)" },
  { suburb: "Kariong", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Pearl Beach", postcode: 2256, lga: "Central Coast (City)" },
  { suburb: "Umina Beach", postcode: 2257, lga: "Central Coast (City)" },
  { suburb: "Point Clare", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Woy Woy", postcode: 2256, lga: "Central Coast (City)" },
  { suburb: "West Gosford", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Ettalong Beach", postcode: 2257, lga: "Central Coast (City)" },
  { suburb: "Blackwall", postcode: 2256, lga: "Central Coast (City)" },
  { suburb: "Gosford", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Narara", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Point Frederick", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Booker Bay", postcode: 2257, lga: "Central Coast (City)" },
  { suburb: "North Gosford", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Niagara Park", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Springfield", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Wondabyne", postcode: 2256, lga: "Central Coast (City)" },
  { suburb: "Ten Mile Hollow", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Kulnura", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Upper Mangrove", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Mangrove Mountain", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Mangrove Creek", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Peats Ridge", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Central Mangrove", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Somersby", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Gunderman", postcode: 2775, lga: "Central Coast (City)" },
  { suburb: "Lower Mangrove", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Spencer", postcode: 2775, lga: "Central Coast (City)" },
  { suburb: "Glenworth Valley", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Wendoree Park", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Marlow", postcode: 2775, lga: "Central Coast (City)" },
  { suburb: "Bar Point", postcode: 2083, lga: "Central Coast (City)" },
  { suburb: "Mooney Mooney", postcode: 2083, lga: "Central Coast (City)" },
  { suburb: "Cheero Point", postcode: 2083, lga: "Central Coast (City)" },
  { suburb: "Cogra Bay", postcode: 2083, lga: "Central Coast (City)" },
  { suburb: "Woy Woy Bay", postcode: 2256, lga: "Central Coast (City)" },
  {
    suburb: "Mooney Mooney Creek",
    postcode: 2250,
    lga: "Central Coast (City)",
  },
  { suburb: "Patonga", postcode: 2256, lga: "Central Coast (City)" },
  { suburb: "Calga", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Mount White", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Tascott", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Koolewong", postcode: 2256, lga: "Central Coast (City)" },
  { suburb: "Horsfield Bay", postcode: 2256, lga: "Central Coast (City)" },
  { suburb: "Phegans Bay", postcode: 2256, lga: "Central Coast (City)" },
  { suburb: "Goulburn", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Lake Bathurst", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Greenwich Park", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Wayo", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Woodhouselee", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Marulan", postcode: 2579, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Bungonia", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Tallong", postcode: 2579, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Carrick", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Brayton", postcode: 2579, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Lower Boro", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Oallen", postcode: 2622, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Middle Arm", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Tarlo", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Towrang", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Boxers Creek", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Pomeroy", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Mummel", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Baw Baw", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Kingsdale", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Parkesbourne", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Yarra", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Tirrannaville", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Wollogorang", postcode: 2581, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Tarago", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Windellama", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Quialigo", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Brisbane Grove", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Run-o-Waters", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Gundary", postcode: 2580, lga: "Goulburn Mulwaree (Area)" },
  { suburb: "Kundibakh", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Dollys Flat", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Strathcedar", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Wingham", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Tinonee", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Taree", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Purfleet", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Cundletown", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Waitui", postcode: 2443, lga: "Mid-Coast (Area)" },
  { suburb: "Diamond Beach", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Red Head", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Black Head", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Wallabi Point", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Old Bar", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Mitchells Island", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Coopernook", postcode: 2426, lga: "Mid-Coast (Area)" },
  { suburb: "Harrington", postcode: 2427, lga: "Mid-Coast (Area)" },
  { suburb: "Cells River", postcode: 2424, lga: "Mid-Coast (Area)" },
  { suburb: "Cooplacurripa", postcode: 2424, lga: "Mid-Coast (Area)" },
  { suburb: "Cundle Flat", postcode: 2424, lga: "Mid-Coast (Area)" },
  { suburb: "Bulga Forest", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Knorrit Flat", postcode: 2424, lga: "Mid-Coast (Area)" },
  { suburb: "Karaak Flat", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "The Bight", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Killabakh", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Dumaresq Island", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Tallwoods Village", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Koorainghat", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Rainbow Flat", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Manning Point", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Lansdowne", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Langley Vale", postcode: 2426, lga: "Mid-Coast (Area)" },
  { suburb: "Croki", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Cabbage Tree Island", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Jones Island", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Coralville", postcode: 2443, lga: "Mid-Coast (Area)" },
  { suburb: "Johns River", postcode: 2443, lga: "Mid-Coast (Area)" },
  { suburb: "Caffreys Flat", postcode: 2424, lga: "Mid-Coast (Area)" },
  { suburb: "Bucca Wauka", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Tipperary", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Knorrit Forest", postcode: 2424, lga: "Mid-Coast (Area)" },
  { suburb: "Caparra", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Hillville", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Possum Brush", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Wang Wauk", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Mooral Creek", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Kippaxs", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Upper Lansdowne", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Brimbin", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Darawank", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Hallidays Point", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Lansdowne Forest", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Bohnock", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Wherrol Flat", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Nabiac", postcode: 2312, lga: "Mid-Coast (Area)" },
  { suburb: "Mount George", postcode: 2424, lga: "Mid-Coast (Area)" },
  { suburb: "Ghinni Ghinni", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Crowdy Head", postcode: 2427, lga: "Mid-Coast (Area)" },
  { suburb: "Krambach", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Dingo Forest", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Kimbriki", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Kundle Kundle", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Glenthorne", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Firefly", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Cedar Party", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Marlee", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Oxley Island", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Bootawa", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Taree South", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Pampoolah", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Number One", postcode: 2424, lga: "Mid-Coast (Area)" },
  { suburb: "Burrell Creek", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Hannam Vale", postcode: 2443, lga: "Mid-Coast (Area)" },
  { suburb: "Stewarts River", postcode: 2443, lga: "Mid-Coast (Area)" },
  { suburb: "Melinga", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Moorland", postcode: 2443, lga: "Mid-Coast (Area)" },
  { suburb: "Elands", postcode: 2429, lga: "Mid-Coast (Area)" },
  {
    suburb: "Crowdy Bay National Park",
    postcode: 2443,
    lga: "Mid-Coast (Area)",
  },
  { suburb: "Khatambuhl", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Moto", postcode: 2426, lga: "Mid-Coast (Area)" },
  { suburb: "Saltwater", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Kiwarrak", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Yarratt Forest", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Bunyah", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Gerogery", postcode: 2642, lga: "Greater Hume Shire (Area)" },
  { suburb: "Brocklesby", postcode: 2642, lga: "Greater Hume Shire (Area)" },
  { suburb: "Walla Walla", postcode: 2659, lga: "Greater Hume Shire (Area)" },
  { suburb: "Bowna", postcode: 2644, lga: "Greater Hume Shire (Area)" },
  { suburb: "Goombargana", postcode: 2646, lga: "Greater Hume Shire (Area)" },
  { suburb: "Mullengandra", postcode: 2644, lga: "Greater Hume Shire (Area)" },
  { suburb: "Bungowannah", postcode: 2640, lga: "Greater Hume Shire (Area)" },
  { suburb: "Burrumbuttock", postcode: 2642, lga: "Greater Hume Shire (Area)" },
  { suburb: "Wymah", postcode: 2640, lga: "Greater Hume Shire (Area)" },
  { suburb: "Moorwatha", postcode: 2640, lga: "Greater Hume Shire (Area)" },
  { suburb: "Holbrook", postcode: 2644, lga: "Greater Hume Shire (Area)" },
  { suburb: "Talmalmo", postcode: 2640, lga: "Greater Hume Shire (Area)" },
  { suburb: "Lankeys Creek", postcode: 2644, lga: "Greater Hume Shire (Area)" },
  { suburb: "Jingellic", postcode: 2642, lga: "Greater Hume Shire (Area)" },
  { suburb: "Alma Park", postcode: 2659, lga: "Greater Hume Shire (Area)" },
  { suburb: "Cookardinia", postcode: 2650, lga: "Greater Hume Shire (Area)" },
  { suburb: "Humula", postcode: 2652, lga: "Greater Hume Shire (Area)" },
  { suburb: "Rosewood", postcode: 2652, lga: "Greater Hume Shire (Area)" },
  { suburb: "Coppabella", postcode: 2644, lga: "Greater Hume Shire (Area)" },
  { suburb: "Yarara", postcode: 2644, lga: "Greater Hume Shire (Area)" },
  { suburb: "Carabost", postcode: 2650, lga: "Greater Hume Shire (Area)" },
  { suburb: "Woomargama", postcode: 2644, lga: "Greater Hume Shire (Area)" },
  {
    suburb: "Mountain Creek",
    postcode: 2644,
    lga: "Greater Hume Shire (Area)",
  },
  { suburb: "Culcairn", postcode: 2660, lga: "Greater Hume Shire (Area)" },
  { suburb: "Henty", postcode: 2658, lga: "Greater Hume Shire (Area)" },
  { suburb: "Morven", postcode: 2660, lga: "Greater Hume Shire (Area)" },
  { suburb: "Wantagong", postcode: 2644, lga: "Greater Hume Shire (Area)" },
  {
    suburb: "Little Billabong",
    postcode: 2644,
    lga: "Greater Hume Shire (Area)",
  },
  { suburb: "Rand", postcode: 2642, lga: "Greater Hume Shire (Area)" },
  { suburb: "Walbundrie", postcode: 2642, lga: "Greater Hume Shire (Area)" },
  { suburb: "Limeburners Creek", postcode: 2324, lga: "Mid-Coast (Area)" },
  { suburb: "North Arm Cove", postcode: 2324, lga: "Mid-Coast (Area)" },
  { suburb: "Tea Gardens", postcode: 2324, lga: "Mid-Coast (Area)" },
  { suburb: "Hawks Nest", postcode: 2324, lga: "Mid-Coast (Area)" },
  { suburb: "Markwell", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Bulahdelah", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Coolongolook", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Wallis Lake", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Coomba Park", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Tuncurry", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Smiths Lake", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Forster", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Green Point", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Seal Rocks", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Bombah Point", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Blueys Beach", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Allworth", postcode: 2425, lga: "Mid-Coast (Area)" },
  { suburb: "Weismantels", postcode: 2415, lga: "Mid-Coast (Area)" },
  { suburb: "Upper Myall", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Wootton", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Wallingat", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Bungwahl", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Elizabeth Beach", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Monkerai", postcode: 2415, lga: "Mid-Coast (Area)" },
  { suburb: "Booral", postcode: 2425, lga: "Mid-Coast (Area)" },
  { suburb: "Girvan", postcode: 2425, lga: "Mid-Coast (Area)" },
  { suburb: "Nerong", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Crawford River", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Warranulla", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Boolambayte", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Topi Topi", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Shallow Bay", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Coomba Bay", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Yagon", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Whoota", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Tiona", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Boomerang Beach", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Stroud", postcode: 2425, lga: "Mid-Coast (Area)" },
  { suburb: "Tahlee", postcode: 2324, lga: "Mid-Coast (Area)" },
  { suburb: "Washpool", postcode: 2425, lga: "Mid-Coast (Area)" },
  { suburb: "Carrington", postcode: 2324, lga: "Mid-Coast (Area)" },
  { suburb: "Wards River", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Tarbuck Bay", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Myall Lake", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Stroud Road", postcode: 2415, lga: "Mid-Coast (Area)" },
  { suburb: "The Branch", postcode: 2425, lga: "Mid-Coast (Area)" },
  { suburb: "Failford", postcode: 2430, lga: "Mid-Coast (Area)" },
  { suburb: "Pindimar", postcode: 2324, lga: "Mid-Coast (Area)" },
  { suburb: "Karuah", postcode: 2324, lga: "Mid-Coast (Area)" },
  { suburb: "Bundabah", postcode: 2324, lga: "Mid-Coast (Area)" },
  { suburb: "Sandbar", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Booti Booti", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Minimbah", postcode: 2312, lga: "Mid-Coast (Area)" },
  { suburb: "Mayers Flat", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Violet Hill", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Charlotte Bay", postcode: 2428, lga: "Mid-Coast (Area)" },
  { suburb: "Mungo Brush", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Willina", postcode: 2423, lga: "Mid-Coast (Area)" },
  { suburb: "Upper Karuah River", postcode: 2415, lga: "Mid-Coast (Area)" },
  { suburb: "Nooroo", postcode: 2415, lga: "Mid-Coast (Area)" },
  { suburb: "Griffith", postcode: 2680, lga: "Griffith (City)" },
  { suburb: "Hanwood", postcode: 2680, lga: "Griffith (City)" },
  { suburb: "Yoogali", postcode: 2680, lga: "Griffith (City)" },
  { suburb: "Beelbangera", postcode: 2680, lga: "Griffith (City)" },
  { suburb: "Bilbul", postcode: 2680, lga: "Griffith (City)" },
  { suburb: "Lake Wyangan", postcode: 2680, lga: "Griffith (City)" },
  { suburb: "Whitton", postcode: 2705, lga: "Griffith (City)" },
  { suburb: "Kooba", postcode: 2680, lga: "Griffith (City)" },
  { suburb: "Nericon", postcode: 2680, lga: "Griffith (City)" },
  { suburb: "Warburn", postcode: 2680, lga: "Griffith (City)" },
  { suburb: "Widgelli", postcode: 2680, lga: "Griffith (City)" },
  { suburb: "Tharbogang", postcode: 2680, lga: "Griffith (City)" },
  { suburb: "Gundagai", postcode: 2722, lga: "Gundagai (Area)" },
  { suburb: "South Gundagai", postcode: 2722, lga: "Gundagai (Area)" },
  { suburb: "Burra Creek", postcode: 2722, lga: "Gundagai (Area)" },
  { suburb: "Tumblong", postcode: 2729, lga: "Gundagai (Area)" },
  { suburb: "Darbalara", postcode: 2722, lga: "Gundagai (Area)" },
  { suburb: "Mundarlo", postcode: 2729, lga: "Gundagai (Area)" },
  { suburb: "Jones Creek", postcode: 2722, lga: "Gundagai (Area)" },
  { suburb: "Adjungbilly", postcode: 2727, lga: "Gundagai (Area)" },
  { suburb: "Nangus", postcode: 2722, lga: "Gundagai (Area)" },
  { suburb: "Gunnedah", postcode: 2380, lga: "Gunnedah (Area)" },
  { suburb: "Kelvin", postcode: 2380, lga: "Gunnedah (Area)" },
  { suburb: "Curlewis", postcode: 2381, lga: "Gunnedah (Area)" },
  { suburb: "The Pilliga", postcode: 2388, lga: "Gunnedah (Area)" },
  { suburb: "Tambar Springs", postcode: 2381, lga: "Gunnedah (Area)" },
  { suburb: "Carroll", postcode: 2340, lga: "Gunnedah (Area)" },
  { suburb: "Keepit", postcode: 2340, lga: "Gunnedah (Area)" },
  { suburb: "Goolhi", postcode: 2379, lga: "Gunnedah (Area)" },
  { suburb: "Wean", postcode: 2382, lga: "Gunnedah (Area)" },
  { suburb: "Breeza", postcode: 2381, lga: "Gunnedah (Area)" },
  { suburb: "Orange Grove", postcode: 2380, lga: "Gunnedah (Area)" },
  { suburb: "Marys Mount", postcode: 2380, lga: "Gunnedah (Area)" },
  { suburb: "Rangari", postcode: 2380, lga: "Gunnedah (Area)" },
  { suburb: "Mullaley", postcode: 2379, lga: "Gunnedah (Area)" },
  { suburb: "Premer", postcode: 2381, lga: "Gunnedah (Area)" },
  { suburb: "Willala", postcode: 2382, lga: "Gunnedah (Area)" },
  { suburb: "Ghoolendaadi", postcode: 2380, lga: "Gunnedah (Area)" },
  { suburb: "Emerald Hill", postcode: 2380, lga: "Gunnedah (Area)" },
  { suburb: "Boggabri", postcode: 2382, lga: "Gunnedah (Area)" },
  { suburb: "Milroy", postcode: 2380, lga: "Gunnedah (Area)" },
  { suburb: "Piallaway", postcode: 2342, lga: "Gunnedah (Area)" },
  { suburb: "Spring Ridge", postcode: 2343, lga: "Gunnedah (Area)" },
  { suburb: "Caroona", postcode: 2343, lga: "Gunnedah (Area)" },
  { suburb: "Blue Vale", postcode: 2380, lga: "Gunnedah (Area)" },
  { suburb: "Brushy Creek", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "South Guyra", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Guyra", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Tubbamurra", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "New Valley", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Llangothlin", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Baldersleigh", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Wandsworth", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Howell", postcode: 2360, lga: "Armidale Regional (Area)" },
  { suburb: "Falconer", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Bassendean", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Georges Creek", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Bundarra", postcode: 2359, lga: "Armidale Regional (Area)" },
  { suburb: "Tingha", postcode: 2369, lga: "Armidale Regional (Area)" },
  { suburb: "Wards Mistake", postcode: 2350, lga: "Armidale Regional (Area)" },
  { suburb: "Stanborough", postcode: 2360, lga: "Armidale Regional (Area)" },
  { suburb: "Tenterden", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Briarbrook", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "The Gulf", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Backwater", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Oban", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Bald Blair", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Green Hills", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Brockley", postcode: 2365, lga: "Armidale Regional (Area)" },
  { suburb: "Bingara", postcode: 2404, lga: "Gwydir (Area)" },
  { suburb: "Whitlow", postcode: 2404, lga: "Gwydir (Area)" },
  { suburb: "Copeton", postcode: 2360, lga: "Gwydir (Area)" },
  { suburb: "Rocky Creek", postcode: 2390, lga: "Gwydir (Area)" },
  { suburb: "Myall Creek", postcode: 2403, lga: "Gwydir (Area)" },
  { suburb: "Dinoga", postcode: 2404, lga: "Gwydir (Area)" },
  { suburb: "Bangheet", postcode: 2404, lga: "Gwydir (Area)" },
  { suburb: "Gineroi", postcode: 2404, lga: "Gwydir (Area)" },
  { suburb: "Elcombe", postcode: 2404, lga: "Gwydir (Area)" },
  { suburb: "Back Creek", postcode: 2390, lga: "Gwydir (Area)" },
  { suburb: "Upper Horton", postcode: 2347, lga: "Gwydir (Area)" },
  { suburb: "Pallal", postcode: 2404, lga: "Gwydir (Area)" },
  { suburb: "Keera", postcode: 2404, lga: "Gwydir (Area)" },
  { suburb: "Warialda Rail", postcode: 2402, lga: "Gwydir (Area)" },
  { suburb: "Warialda", postcode: 2402, lga: "Gwydir (Area)" },
  { suburb: "Coolatai", postcode: 2402, lga: "Gwydir (Area)" },
  { suburb: "Delungra", postcode: 2403, lga: "Gwydir (Area)" },
  { suburb: "Pallamallawa", postcode: 2399, lga: "Gwydir (Area)" },
  { suburb: "Gravesend", postcode: 2401, lga: "Gwydir (Area)" },
  { suburb: "Croppa Creek", postcode: 2411, lga: "Gwydir (Area)" },
  { suburb: "North Star", postcode: 2408, lga: "Gwydir (Area)" },
  { suburb: "Yallaroi", postcode: 2408, lga: "Gwydir (Area)" },
  { suburb: "Crooble", postcode: 2400, lga: "Gwydir (Area)" },
  { suburb: "Balfours Peak", postcode: 2403, lga: "Gwydir (Area)" },
  { suburb: "Graman", postcode: 2360, lga: "Gwydir (Area)" },
  { suburb: "Boonal", postcode: 2409, lga: "Gwydir (Area)" },
  { suburb: "Tulloona", postcode: 2400, lga: "Gwydir (Area)" },
  { suburb: "Blue Nobby", postcode: 2408, lga: "Gwydir (Area)" },
  { suburb: "Gundamulda", postcode: 2347, lga: "Gwydir (Area)" },
  { suburb: "Gulf Creek", postcode: 2347, lga: "Gwydir (Area)" },
  { suburb: "Cobbadah", postcode: 2347, lga: "Gwydir (Area)" },
  { suburb: "Barraba", postcode: 2347, lga: "Gwydir (Area)" },
  { suburb: "Lindesay", postcode: 2347, lga: "Tamworth Regional (Area)" },
  { suburb: "Kingsvale", postcode: 2587, lga: "Hilltops (Area)" },
  { suburb: "Jugiong", postcode: 2726, lga: "Hilltops (Area)" },
  { suburb: "Galong", postcode: 2585, lga: "Hilltops (Area)" },
  { suburb: "Cunningar", postcode: 2587, lga: "Hilltops (Area)" },
  { suburb: "Harden", postcode: 2587, lga: "Hilltops (Area)" },
  { suburb: "Murrumburrah", postcode: 2587, lga: "Hilltops (Area)" },
  { suburb: "Wombat", postcode: 2587, lga: "Hilltops (Area)" },
  { suburb: "Barwang", postcode: 2594, lga: "Hilltops (Area)" },
  { suburb: "Nubba", postcode: 2587, lga: "Hilltops (Area)" },
  { suburb: "Beggan Beggan", postcode: 2587, lga: "Hilltops (Area)" },
  { suburb: "McMahons Reef", postcode: 2587, lga: "Hilltops (Area)" },
  { suburb: "Berremangra", postcode: 2582, lga: "Hilltops (Area)" },
  {
    suburb: "Port Macquarie",
    postcode: 2444,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Fernbank Creek",
    postcode: 2444,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Thrumster",
    postcode: 2444,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Riverside",
    postcode: 2444,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "North Shore",
    postcode: 2444,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Innes View",
    postcode: 2429,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Upper Pappinbarra",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Bril Bril",
    postcode: 2441,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Bellangry",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Lake Cathie",
    postcode: 2445,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Bago", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Cairncross",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Kendall", postcode: 2439, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Yippin Creek",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Wauchope", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Diamond Head",
    postcode: 2443,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "King Creek",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "West Haven",
    postcode: 2443,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Laurieton",
    postcode: 2443,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Dunbogan", postcode: 2443, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "North Haven",
    postcode: 2443,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Camden Head",
    postcode: 2443,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Limeburners Creek",
    postcode: 2444,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Blackmans Point",
    postcode: 2444,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Byabarra", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  { suburb: "Comboyne", postcode: 2429, lga: "Port Macquarie-Hastings (Area)" },
  { suburb: "Lorne", postcode: 2439, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Frazers Creek",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Marlo Merrican",
    postcode: 2441,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Middle Brother",
    postcode: 2443,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Upsalls Creek",
    postcode: 2439,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Pembrooke",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Lake Innes",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Dondingalong",
    postcode: 2440,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Cooperabung",
    postcode: 2441,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Kindee", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Swans Crossing",
    postcode: 2439,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Kerewong", postcode: 2439, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Hyndmans Creek",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Upper Rollands Plains",
    postcode: 2441,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Black Creek",
    postcode: 2439,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Herons Creek",
    postcode: 2443,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Logans Crossing",
    postcode: 2439,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Bobs Creek",
    postcode: 2443,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Grants Beach",
    postcode: 2445,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Lakewood", postcode: 2443, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Rawdon Island",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "The Hatch",
    postcode: 2444,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Sancrox", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Hacks Ferry",
    postcode: 2441,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Mount Seaview",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Werrikimbe",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Debenham", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  { suburb: "Yarras", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  { suburb: "Birdwood", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Boorganna",
    postcode: 2429,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Pappinbarra",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Hartys Plains",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Pipeclay", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Gum Scrub",
    postcode: 2441,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Batar Creek",
    postcode: 2439,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Ballengarra",
    postcode: 2441,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Beechwood",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Rossglen", postcode: 2439, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Crosslands",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Telegraph Point",
    postcode: 2441,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Long Flat",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Kew", postcode: 2439, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Hollisdale",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Deauville",
    postcode: 2443,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Ellenborough",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Lower Pappinbarra",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Bagnoo", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Forbes River",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Rosewood", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "Mortons Creek",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Redbank", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  {
    suburb: "North Brother",
    postcode: 2443,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Toms Creek",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Jolly Nose",
    postcode: 2445,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Huntingdon",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Gearys Flat",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Doyles River",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  {
    suburb: "Banda Banda",
    postcode: 2446,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Kippara", postcode: 2441, lga: "Port Macquarie-Hastings (Area)" },
  { suburb: "Brombin", postcode: 2446, lga: "Port Macquarie-Hastings (Area)" },
  { suburb: "Bilpin", postcode: 2758, lga: "Hawkesbury (City)" },
  { suburb: "Kurrajong", postcode: 2758, lga: "Hawkesbury (City)" },
  { suburb: "Oakville", postcode: 2765, lga: "Hawkesbury (City)" },
  { suburb: "Freemans Reach", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Mountain Lagoon", postcode: 2758, lga: "Hawkesbury (City)" },
  { suburb: "Grose Vale", postcode: 2753, lga: "Hawkesbury (City)" },
  { suburb: "Blaxlands Ridge", postcode: 2758, lga: "Hawkesbury (City)" },
  { suburb: "Lower Macdonald", postcode: 2775, lga: "Hawkesbury (City)" },
  { suburb: "Pitt Town Bottoms", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Webbs Creek", postcode: 2775, lga: "Hawkesbury (City)" },
  { suburb: "South Windsor", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Bligh Park", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Higher Macdonald", postcode: 2775, lga: "Hawkesbury (City)" },
  { suburb: "Sackville", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Yarramundi", postcode: 2753, lga: "Hawkesbury (City)" },
  { suburb: "Windsor Downs", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Mogo Creek", postcode: 2775, lga: "Hawkesbury (City)" },
  { suburb: "Vineyard", postcode: 2765, lga: "Hawkesbury (City)" },
  { suburb: "Leets Vale", postcode: 2775, lga: "Hawkesbury (City)" },
  { suburb: "Mellong", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Perrys Crossing", postcode: 2775, lga: "Hawkesbury (City)" },
  { suburb: "The Devils Wilderness", postcode: 2758, lga: "Hawkesbury (City)" },
  { suburb: "Ebenezer", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Cumberland Reach", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Wrights Creek", postcode: 2775, lga: "Hawkesbury (City)" },
  { suburb: "Agnes Banks", postcode: 2753, lga: "Hawkesbury (City)" },
  { suburb: "St Albans", postcode: 2775, lga: "Hawkesbury (City)" },
  { suburb: "Wilberforce", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "East Kurrajong", postcode: 2758, lga: "Hawkesbury (City)" },
  { suburb: "Kurrajong Hills", postcode: 2758, lga: "Hawkesbury (City)" },
  { suburb: "Central Macdonald", postcode: 2775, lga: "Hawkesbury (City)" },
  { suburb: "Clarendon", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Colo Heights", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Hobartville", postcode: 2753, lga: "Hawkesbury (City)" },
  { suburb: "North Richmond", postcode: 2754, lga: "Hawkesbury (City)" },
  { suburb: "Richmond", postcode: 2753, lga: "Hawkesbury (City)" },
  { suburb: "Mulgrave", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "McGraths Hill", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Bowen Mountain", postcode: 2753, lga: "Hawkesbury (City)" },
  { suburb: "Glossodia", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Kurrajong Heights", postcode: 2758, lga: "Hawkesbury (City)" },
  { suburb: "Kurmond", postcode: 2757, lga: "Hawkesbury (City)" },
  { suburb: "Central Colo", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Windsor", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Pitt Town", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Scheyville", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Upper Macdonald", postcode: 2775, lga: "Hawkesbury (City)" },
  { suburb: "Wheeny Creek", postcode: 2758, lga: "Hawkesbury (City)" },
  { suburb: "The Slopes", postcode: 2754, lga: "Hawkesbury (City)" },
  { suburb: "Grose Wold", postcode: 2753, lga: "Hawkesbury (City)" },
  { suburb: "Tennyson", postcode: 2754, lga: "Hawkesbury (City)" },
  { suburb: "Upper Colo", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Womerah", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Fernances", postcode: 2775, lga: "Hawkesbury (City)" },
  { suburb: "Putty", postcode: 2330, lga: "Hawkesbury (City)" },
  { suburb: "Colo", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Maude", postcode: 2711, lga: "Hay (Area)" },
  { suburb: "Hay", postcode: 2711, lga: "Hay (Area)" },
  { suburb: "One Tree", postcode: 2711, lga: "Hay (Area)" },
  { suburb: "Hay South", postcode: 2711, lga: "Hay (Area)" },
  { suburb: "Woodpark", postcode: 2164, lga: "Cumberland (Area)" },
  { suburb: "South Wentworthville", postcode: 2145, lga: "Cumberland (Area)" },
  { suburb: "Girraween", postcode: 2145, lga: "Cumberland (Area)" },
  { suburb: "Westmead", postcode: 2145, lga: "Cumberland (Area)" },
  { suburb: "Mays Hill", postcode: 2145, lga: "Parramatta (City)" },
  { suburb: "Guildford", postcode: 2161, lga: "Cumberland (Area)" },
  { suburb: "Merrylands", postcode: 2160, lga: "Cumberland (Area)" },
  { suburb: "Merrylands West", postcode: 2160, lga: "Cumberland (Area)" },
  { suburb: "Guildford West", postcode: 2161, lga: "Cumberland (Area)" },
  { suburb: "Pendle Hill", postcode: 2145, lga: "Cumberland (Area)" },
  { suburb: "Granville", postcode: 2142, lga: "Parramatta (City)" },
  { suburb: "Holroyd", postcode: 2142, lga: "Cumberland (Area)" },
  { suburb: "Wentworthville", postcode: 2145, lga: "Cumberland (Area)" },
  { suburb: "Pemulwuy", postcode: 2145, lga: "Cumberland (Area)" },
  { suburb: "Parramatta", postcode: 2150, lga: "Parramatta (City)" },
  { suburb: "Cherrybrook", postcode: 2126, lga: "Hornsby (Area)" },
  { suburb: "Arcadia", postcode: 2159, lga: "Hornsby (Area)" },
  { suburb: "Hornsby Heights", postcode: 2077, lga: "Hornsby (Area)" },
  { suburb: "Asquith", postcode: 2077, lga: "Hornsby (Area)" },
  { suburb: "Mount Colah", postcode: 2079, lga: "Hornsby (Area)" },
  { suburb: "Berowra", postcode: 2081, lga: "Hornsby (Area)" },
  { suburb: "Cowan", postcode: 2081, lga: "Hornsby (Area)" },
  { suburb: "Galston", postcode: 2159, lga: "Hornsby (Area)" },
  { suburb: "Berrilee", postcode: 2159, lga: "Hornsby (Area)" },
  { suburb: "Berowra Waters", postcode: 2082, lga: "Hornsby (Area)" },
  { suburb: "Berowra Heights", postcode: 2082, lga: "Hornsby (Area)" },
  { suburb: "Mount Kuring-Gai", postcode: 2080, lga: "Hornsby (Area)" },
  { suburb: "Fiddletown", postcode: 2159, lga: "Hornsby (Area)" },
  { suburb: "Brooklyn", postcode: 2083, lga: "Hornsby (Area)" },
  { suburb: "Dangar Island", postcode: 2083, lga: "Hornsby (Area)" },
  { suburb: "Canoelands", postcode: 2157, lga: "Hornsby (Area)" },
  { suburb: "Laughtondale", postcode: 2775, lga: "Hornsby (Area)" },
  { suburb: "Singletons Mill", postcode: 2775, lga: "Hornsby (Area)" },
  { suburb: "Milsons Passage", postcode: 2083, lga: "Hornsby (Area)" },
  { suburb: "Berowra Creek", postcode: 2082, lga: "Hornsby (Area)" },
  { suburb: "Forest Glen", postcode: 2157, lga: "Hornsby (Area)" },
  { suburb: "Epping", postcode: 2121, lga: "Parramatta (City)" },
  { suburb: "Beecroft", postcode: 2119, lga: "Hornsby (Area)" },
  { suburb: "Pennant Hills", postcode: 2120, lga: "Hornsby (Area)" },
  { suburb: "Westleigh", postcode: 2120, lga: "Hornsby (Area)" },
  { suburb: "Cheltenham", postcode: 2119, lga: "Hornsby (Area)" },
  { suburb: "Thornleigh", postcode: 2120, lga: "Hornsby (Area)" },
  { suburb: "North Epping", postcode: 2121, lga: "Hornsby (Area)" },
  { suburb: "Waitara", postcode: 2077, lga: "Hornsby (Area)" },
  { suburb: "Wahroonga", postcode: 2076, lga: "Hornsby (Area)" },
  { suburb: "Eastwood", postcode: 2122, lga: "Parramatta (City)" },
  { suburb: "Normanhurst", postcode: 2076, lga: "Hornsby (Area)" },
  { suburb: "Gladesville", postcode: 2111, lga: "Hunters Hill (Area)" },
  { suburb: "Hunters Hill", postcode: 2110, lga: "Hunters Hill (Area)" },
  { suburb: "Woolwich", postcode: 2110, lga: "Hunters Hill (Area)" },
  { suburb: "Huntleys Point", postcode: 2111, lga: "Hunters Hill (Area)" },
  { suburb: "Huntleys Cove", postcode: 2111, lga: "Hunters Hill (Area)" },
  { suburb: "Henley", postcode: 2111, lga: "Hunters Hill (Area)" },
  { suburb: "Lugarno", postcode: 2210, lga: "Georges River (Area)" },
  { suburb: "Peakhurst", postcode: 2210, lga: "Georges River (Area)" },
  { suburb: "Carlton", postcode: 2218, lga: "Georges River (Area)" },
  { suburb: "Oatley", postcode: 2223, lga: "Georges River (Area)" },
  { suburb: "Mortdale", postcode: 2223, lga: "Georges River (Area)" },
  { suburb: "Penshurst", postcode: 2222, lga: "Georges River (Area)" },
  { suburb: "Hurstville", postcode: 2220, lga: "Georges River (Area)" },
  { suburb: "Peakhurst Heights", postcode: 2210, lga: "Georges River (Area)" },
  { suburb: "Rob Roy", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Auburn Vale", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Gilgai", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Inverell", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Gum Flat", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Mount Russell", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Bukkulla", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Yetman", postcode: 2410, lga: "Inverell (Area)" },
  { suburb: "Camp Creek", postcode: 4385, lga: "Inverell (Area)" },
  { suburb: "Texas", postcode: 4385, lga: "Inverell (Area)" },
  { suburb: "Ashford", postcode: 2361, lga: "Inverell (Area)" },
  { suburb: "Gragin", postcode: 2403, lga: "Inverell (Area)" },
  { suburb: "Cherry Tree Hill", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Oakwood", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Pindaroi", postcode: 2361, lga: "Inverell (Area)" },
  { suburb: "Wallangra", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Atholwood", postcode: 2361, lga: "Inverell (Area)" },
  { suburb: "Bonshaw", postcode: 2361, lga: "Inverell (Area)" },
  { suburb: "Twin Rivers", postcode: 2410, lga: "Inverell (Area)" },
  { suburb: "Limestone", postcode: 2361, lga: "Inverell (Area)" },
  { suburb: "Yellow Dam", postcode: 2371, lga: "Inverell (Area)" },
  { suburb: "Rocky Creek", postcode: 2371, lga: "Inverell (Area)" },
  { suburb: "Wellingrove", postcode: 2370, lga: "Inverell (Area)" },
  { suburb: "Nullamanna", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Stannifer", postcode: 2369, lga: "Inverell (Area)" },
  { suburb: "Old Mill", postcode: 2369, lga: "Inverell (Area)" },
  { suburb: "Little Plain", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Sapphire", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Woodstock", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Long Plain", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Swanbrook", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Elsmore", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Brodies Plains", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Kings Plains", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Kingsland", postcode: 2370, lga: "Inverell (Area)" },
  { suburb: "Swan Vale", postcode: 2370, lga: "Inverell (Area)" },
  { suburb: "Newstead", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Paradise", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Spring Mountain", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Gala Vale", postcode: 2716, lga: "Murrumbidgee (Area)" },
  { suburb: "Mairjimmy", postcode: 2716, lga: "Murrumbidgee (Area)" },
  { suburb: "Jerilderie", postcode: 2716, lga: "Murrumbidgee (Area)" },
  { suburb: "Argoon", postcode: 2707, lga: "Murrumbidgee (Area)" },
  { suburb: "Bundure", postcode: 2700, lga: "Murrumbidgee (Area)" },
  { suburb: "Coleambally", postcode: 2707, lga: "Murrumbidgee (Area)" },
  { suburb: "Junee", postcode: 2663, lga: "Junee (Area)" },
  { suburb: "Dirnaseer", postcode: 2666, lga: "Junee (Area)" },
  { suburb: "Bethungra", postcode: 2590, lga: "Junee (Area)" },
  { suburb: "Illabo", postcode: 2590, lga: "Junee (Area)" },
  { suburb: "Old Junee", postcode: 2652, lga: "Junee (Area)" },
  { suburb: "Erin Vale", postcode: 2663, lga: "Junee (Area)" },
  { suburb: "Junee Reefs", postcode: 2666, lga: "Junee (Area)" },
  { suburb: "Harefield", postcode: 2650, lga: "Junee (Area)" },
  { suburb: "Yathella", postcode: 2650, lga: "Junee (Area)" },
  { suburb: "Wantabadgery", postcode: 2650, lga: "Junee (Area)" },
  { suburb: "Eurongilly", postcode: 2663, lga: "Junee (Area)" },
  { suburb: "Wantiool", postcode: 2663, lga: "Junee (Area)" },
  { suburb: "Marinna", postcode: 2663, lga: "Junee (Area)" },
  { suburb: "Willi Willi", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Moparrabah", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Comara", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Bellbrook", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Deep Creek", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "South Kempsey", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "West Kempsey", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "East Kempsey", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Frederickton", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Old Station", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Crescent Head", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "South West Rocks", postcode: 2431, lga: "Kempsey (Area)" },
  { suburb: "Arakoon", postcode: 2431, lga: "Kempsey (Area)" },
  { suburb: "Millbank", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Wittitrin", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Mungay Creek", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Skillion Flat", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Collombatti", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Kundabung", postcode: 2441, lga: "Kempsey (Area)" },
  { suburb: "Pola Creek", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Stuarts Point", postcode: 2441, lga: "Kempsey (Area)" },
  { suburb: "Hampden Hall", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Hat Head", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Barraganyatti", postcode: 2441, lga: "Kempsey (Area)" },
  { suburb: "Grassy Head", postcode: 2441, lga: "Kempsey (Area)" },
  { suburb: "Summer Island", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Rainbow Reach", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Fishermans Reach", postcode: 2441, lga: "Kempsey (Area)" },
  { suburb: "Jerseyville", postcode: 2431, lga: "Kempsey (Area)" },
  { suburb: "Yarrahapinni", postcode: 2441, lga: "Kempsey (Area)" },
  { suburb: "Toorooka", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Temagog", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Willawarrin", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Corangula", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Mooneba", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Turners Flat", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Sherwood", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Bellimbopinni", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Seven Oaks", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Austral Eden", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Yarravel", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Gladstone", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Greenhill", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Kinchela", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Smithtown", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Kempsey", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Verges Creek", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Euroka", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Belmore River", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Aldavilla", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Yessabah", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Clybucca", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Hickeys Creek", postcode: 2440, lga: "Kempsey (Area)" },
  { suburb: "Rose Valley", postcode: 2534, lga: "Kiama (Area)" },
  { suburb: "Gerringong", postcode: 2534, lga: "Kiama (Area)" },
  { suburb: "Werri Beach", postcode: 2534, lga: "Kiama (Area)" },
  { suburb: "Kiama Downs", postcode: 2533, lga: "Kiama (Area)" },
  { suburb: "Kiama Heights", postcode: 2533, lga: "Kiama (Area)" },
  { suburb: "Kiama", postcode: 2533, lga: "Kiama (Area)" },
  { suburb: "Upper Kangaroo River", postcode: 2577, lga: "Kiama (Area)" },
  { suburb: "Minnamurra", postcode: 2533, lga: "Kiama (Area)" },
  { suburb: "Upper Kangaroo Valley", postcode: 2577, lga: "Kiama (Area)" },
  { suburb: "Carrington Falls", postcode: 2577, lga: "Kiama (Area)" },
  { suburb: "Barren Grounds", postcode: 2577, lga: "Kiama (Area)" },
  { suburb: "Budderoo", postcode: 2535, lga: "Kiama (Area)" },
  { suburb: "Yellow Rock", postcode: 2527, lga: "Kiama (Area)" },
  { suburb: "Knights Hill", postcode: 2577, lga: "Kiama (Area)" },
  { suburb: "Jamberoo", postcode: 2533, lga: "Kiama (Area)" },
  { suburb: "Curramore", postcode: 2533, lga: "Kiama (Area)" },
  { suburb: "Croom", postcode: 2527, lga: "Kiama (Area)" },
  { suburb: "Foxground", postcode: 2534, lga: "Kiama (Area)" },
  { suburb: "Toolijooa", postcode: 2534, lga: "Kiama (Area)" },
  { suburb: "Broughton Village", postcode: 2534, lga: "Kiama (Area)" },
  { suburb: "Gerroa", postcode: 2534, lga: "Kiama (Area)" },
  { suburb: "Willow Vale", postcode: 2534, lga: "Kiama (Area)" },
  { suburb: "Jerrara", postcode: 2533, lga: "Kiama (Area)" },
  { suburb: "Bombo", postcode: 2533, lga: "Kiama (Area)" },
  { suburb: "Saddleback Mountain", postcode: 2533, lga: "Kiama (Area)" },
  { suburb: "Ramsgate", postcode: 2217, lga: "Georges River (Area)" },
  { suburb: "Hurstville Grove", postcode: 2220, lga: "Georges River (Area)" },
  { suburb: "Connells Point", postcode: 2221, lga: "Georges River (Area)" },
  { suburb: "South Hurstville", postcode: 2221, lga: "Georges River (Area)" },
  { suburb: "Blakehurst", postcode: 2221, lga: "Georges River (Area)" },
  { suburb: "Allawah", postcode: 2218, lga: "Georges River (Area)" },
  { suburb: "Carss Park", postcode: 2221, lga: "Georges River (Area)" },
  { suburb: "Beverley Park", postcode: 2217, lga: "Georges River (Area)" },
  { suburb: "Kogarah Bay", postcode: 2217, lga: "Georges River (Area)" },
  { suburb: "Sans Souci", postcode: 2219, lga: "Georges River (Area)" },
  { suburb: "Kogarah", postcode: 2217, lga: "Georges River (Area)" },
  { suburb: "Kyle Bay", postcode: 2221, lga: "Georges River (Area)" },
  { suburb: "South Turramurra", postcode: 2074, lga: "Ku-ring-gai (Area)" },
  { suburb: "North Wahroonga", postcode: 2076, lga: "Ku-ring-gai (Area)" },
  { suburb: "Turramurra", postcode: 2074, lga: "Ku-ring-gai (Area)" },
  { suburb: "West Pymble", postcode: 2073, lga: "Ku-ring-gai (Area)" },
  { suburb: "Warrawee", postcode: 2074, lga: "Ku-ring-gai (Area)" },
  { suburb: "Pymble", postcode: 2073, lga: "Ku-ring-gai (Area)" },
  { suburb: "Killara", postcode: 2071, lga: "Ku-ring-gai (Area)" },
  { suburb: "North Turramurra", postcode: 2074, lga: "Ku-ring-gai (Area)" },
  { suburb: "Lindfield", postcode: 2070, lga: "Ku-ring-gai (Area)" },
  { suburb: "St Ives", postcode: 2075, lga: "Ku-ring-gai (Area)" },
  { suburb: "Gordon", postcode: 2072, lga: "Ku-ring-gai (Area)" },
  { suburb: "St Ives Chase", postcode: 2075, lga: "Ku-ring-gai (Area)" },
  { suburb: "Roseville", postcode: 2069, lga: "Ku-ring-gai (Area)" },
  { suburb: "East Killara", postcode: 2071, lga: "Ku-ring-gai (Area)" },
  { suburb: "East Lindfield", postcode: 2070, lga: "Ku-ring-gai (Area)" },
  { suburb: "Roseville Chase", postcode: 2069, lga: "Ku-ring-gai (Area)" },
  { suburb: "Kilgra", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Gorge Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Peacock Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Sextonville", postcode: 2470, lga: "Kyogle (Area)" },
  { suburb: "Tabulam", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Edenville", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Doubtful Creek", postcode: 2470, lga: "Kyogle (Area)" },
  { suburb: "Tooloom", postcode: 2475, lga: "Kyogle (Area)" },
  { suburb: "Mummulgum", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Lower Dyraaba", postcode: 2470, lga: "Kyogle (Area)" },
  { suburb: "Lower Peacock", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Lower Bottle Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Jacksons Flat", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Dyraaba", postcode: 2470, lga: "Kyogle (Area)" },
  { suburb: "Culmaran Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Terrace Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Sawpit Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Findon Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Ettrick", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Kyogle", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Bonalbo", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Ghinni Ghi", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Deep Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Upper Horseshoe Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Paddys Flat", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Pagans Flat", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Sherwood", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Unumgar", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Dairy Flat", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Mallanganee", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Urbenville", postcode: 2475, lga: "Kyogle (Area)" },
  { suburb: "Upper Duck Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Iron Pot Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Roseberry", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Wyneden", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Cambridge Plateau", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Theresa Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Roseberry Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Green Pigeon", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Geneva", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Yabbra", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Simpkins Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Bingeebeebra Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "West Wiangaree", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Woodenbong", postcode: 2476, lga: "Kyogle (Area)" },
  { suburb: "Sandilands", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Gradys Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Toonumbar", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Wiangaree", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Tunglebung", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Horse Station Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Horseshoe Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Old Bonalbo", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Joes Box", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Woolners Arm", postcode: 2470, lga: "Kyogle (Area)" },
  { suburb: "Collins Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Homeleigh", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Boorabee Park", postcode: 2480, lga: "Kyogle (Area)" },
  { suburb: "Stony Chute", postcode: 2480, lga: "Kyogle (Area)" },
  { suburb: "Capeen Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Brumby Plains", postcode: 2476, lga: "Kyogle (Area)" },
  { suburb: "Lindesay Creek", postcode: 2476, lga: "Kyogle (Area)" },
  { suburb: "Bean Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "The Glen", postcode: 2476, lga: "Kyogle (Area)" },
  { suburb: "Rukenvale", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "The Risk", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Afterlee", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Smiths Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Cougal", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Loadstone", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Lynchs Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Warrazambil Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Boomi Creek", postcode: 2476, lga: "Kyogle (Area)" },
  { suburb: "Babyl Creek", postcode: 2470, lga: "Kyogle (Area)" },
  { suburb: "Lower Duck Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Haystack", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Border Ranges", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Little Back Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Cawongla", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Duck Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Larnook", postcode: 2480, lga: "Kyogle (Area)" },
  { suburb: "Grevillia", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Bottle Creek", postcode: 2469, lga: "Kyogle (Area)" },
  { suburb: "Old Grevillia", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Cedar Point", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Upper Eden Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Lillian Rock", postcode: 2480, lga: "Kyogle (Area)" },
  { suburb: "Muli Muli", postcode: 2476, lga: "Kyogle (Area)" },
  { suburb: "Lake Cargelligo", postcode: 2672, lga: "Lachlan (Area)" },
  { suburb: "Condobolin", postcode: 2877, lga: "Lachlan (Area)" },
  { suburb: "Burcher", postcode: 2671, lga: "Lachlan (Area)" },
  { suburb: "Fifield", postcode: 2875, lga: "Lachlan (Area)" },
  { suburb: "Tottenham", postcode: 2873, lga: "Lachlan (Area)" },
  { suburb: "Boona Mount", postcode: 2877, lga: "Lachlan (Area)" },
  { suburb: "Albert", postcode: 2873, lga: "Lachlan (Area)" },
  { suburb: "Tullamore", postcode: 2874, lga: "Lachlan (Area)" },
  { suburb: "Curlew Waters", postcode: 2672, lga: "Lachlan (Area)" },
  { suburb: "Tullibigeal", postcode: 2669, lga: "Lachlan (Area)" },
  { suburb: "Fairholme", postcode: 2871, lga: "Lachlan (Area)" },
  { suburb: "Kiacatoo", postcode: 2877, lga: "Lachlan (Area)" },
  { suburb: "Miamley", postcode: 2873, lga: "Lachlan (Area)" },
  { suburb: "Valentine", postcode: 2280, lga: "Lake Macquarie (City)" },
  { suburb: "Swansea", postcode: 2281, lga: "Lake Macquarie (City)" },
  { suburb: "Belmont", postcode: 2280, lga: "Lake Macquarie (City)" },
  { suburb: "Caves Beach", postcode: 2281, lga: "Lake Macquarie (City)" },
  { suburb: "Pelican", postcode: 2281, lga: "Lake Macquarie (City)" },
  { suburb: "Blacksmiths", postcode: 2281, lga: "Lake Macquarie (City)" },
  { suburb: "Marks Point", postcode: 2280, lga: "Lake Macquarie (City)" },
  { suburb: "Swansea Heads", postcode: 2281, lga: "Lake Macquarie (City)" },
  { suburb: "Belmont North", postcode: 2280, lga: "Lake Macquarie (City)" },
  { suburb: "Windale", postcode: 2306, lga: "Lake Macquarie (City)" },
  { suburb: "Mount Hutton", postcode: 2290, lga: "Lake Macquarie (City)" },
  { suburb: "Jewells", postcode: 2280, lga: "Lake Macquarie (City)" },
  { suburb: "Gateshead", postcode: 2290, lga: "Lake Macquarie (City)" },
  { suburb: "Redhead", postcode: 2290, lga: "Lake Macquarie (City)" },
  { suburb: "Nords Wharf", postcode: 2281, lga: "Lake Macquarie (City)" },
  { suburb: "Lake Macquarie", postcode: 2280, lga: "Lake Macquarie (City)" },
  { suburb: "Croudace Bay", postcode: 2280, lga: "Lake Macquarie (City)" },
  { suburb: "Belmont South", postcode: 2280, lga: "Lake Macquarie (City)" },
  { suburb: "Eleebana", postcode: 2282, lga: "Lake Macquarie (City)" },
  { suburb: "Charlestown", postcode: 2290, lga: "Lake Macquarie (City)" },
  {
    suburb: "Catherine Hill Bay",
    postcode: 2281,
    lga: "Lake Macquarie (City)",
  },
  { suburb: "Pinny Beach", postcode: 2281, lga: "Lake Macquarie (City)" },
  { suburb: "Cams Wharf", postcode: 2281, lga: "Lake Macquarie (City)" },
  { suburb: "Little Pelican", postcode: 2281, lga: "Lake Macquarie (City)" },
  { suburb: "Dudley", postcode: 2290, lga: "Lake Macquarie (City)" },
  { suburb: "Bennetts Green", postcode: 2290, lga: "Lake Macquarie (City)" },
  { suburb: "Floraville", postcode: 2280, lga: "Lake Macquarie (City)" },
  { suburb: "Warners Bay", postcode: 2282, lga: "Lake Macquarie (City)" },
  { suburb: "Tingira Heights", postcode: 2290, lga: "Lake Macquarie (City)" },
  { suburb: "Seahampton", postcode: 2286, lga: "Lake Macquarie (City)" },
  { suburb: "West Wallsend", postcode: 2286, lga: "Lake Macquarie (City)" },
  { suburb: "Highfields", postcode: 2289, lga: "Lake Macquarie (City)" },
  { suburb: "Cardiff Heights", postcode: 2285, lga: "Lake Macquarie (City)" },
  { suburb: "Cardiff", postcode: 2285, lga: "Lake Macquarie (City)" },
  { suburb: "Barnsley", postcode: 2278, lga: "Lake Macquarie (City)" },
  { suburb: "Edgeworth", postcode: 2285, lga: "Lake Macquarie (City)" },
  { suburb: "Glendale", postcode: 2285, lga: "Lake Macquarie (City)" },
  { suburb: "Whitebridge", postcode: 2290, lga: "Lake Macquarie (City)" },
  { suburb: "Speers Point", postcode: 2284, lga: "Lake Macquarie (City)" },
  { suburb: "Holmesville", postcode: 2286, lga: "Lake Macquarie (City)" },
  { suburb: "Hillsborough", postcode: 2290, lga: "Lake Macquarie (City)" },
  { suburb: "Cameron Park", postcode: 2285, lga: "Lake Macquarie (City)" },
  { suburb: "Wakefield", postcode: 2278, lga: "Lake Macquarie (City)" },
  { suburb: "Teralba", postcode: 2284, lga: "Lake Macquarie (City)" },
  { suburb: "Killingworth", postcode: 2278, lga: "Lake Macquarie (City)" },
  { suburb: "Argenton", postcode: 2284, lga: "Lake Macquarie (City)" },
  { suburb: "Boolaroo", postcode: 2284, lga: "Lake Macquarie (City)" },
  { suburb: "Kotara South", postcode: 2289, lga: "Lake Macquarie (City)" },
  { suburb: "Adamstown Heights", postcode: 2289, lga: "Lake Macquarie (City)" },
  { suburb: "Rankin Park", postcode: 2287, lga: "Lake Macquarie (City)" },
  { suburb: "Garden Suburb", postcode: 2289, lga: "Lake Macquarie (City)" },
  {
    suburb: "New Lambton Heights",
    postcode: 2305,
    lga: "Lake Macquarie (City)",
  },
  { suburb: "Macquarie Hills", postcode: 2285, lga: "Lake Macquarie (City)" },
  { suburb: "Cardiff South", postcode: 2285, lga: "Lake Macquarie (City)" },
  { suburb: "Kahibah", postcode: 2290, lga: "Lake Macquarie (City)" },
  { suburb: "Lakelands", postcode: 2282, lga: "Lake Macquarie (City)" },
  { suburb: "Dora Creek", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Wyee", postcode: 2259, lga: "Lake Macquarie (City)" },
  { suburb: "Windermere Park", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Bonnells Bay", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Awaba", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Brightwaters", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Balcolyn", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Sunshine", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Toronto", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Wangi Wangi", postcode: 2267, lga: "Lake Macquarie (City)" },
  { suburb: "Blackalls Park", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Buttaba", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Arcadia Vale", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Rathmines", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Fishing Point", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Fennell Bay", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Bolton Point", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Booragul", postcode: 2284, lga: "Lake Macquarie (City)" },
  { suburb: "Marmong Point", postcode: 2284, lga: "Lake Macquarie (City)" },
  { suburb: "Myuna Bay", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Eraring", postcode: 2264, lga: "Lake Macquarie (City)" },
  {
    suburb: "Freemans Waterhole",
    postcode: 2323,
    lga: "Lake Macquarie (City)",
  },
  { suburb: "Fassifern", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Woodrising", postcode: 2284, lga: "Lake Macquarie (City)" },
  { suburb: "Carey Bay", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Kilaben Bay", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Coal Point", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Balmoral", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Cooranbong", postcode: 2265, lga: "Lake Macquarie (City)" },
  { suburb: "Morisset", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Martinsville", postcode: 2265, lga: "Lake Macquarie (City)" },
  { suburb: "Mandalong", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Wyee Point", postcode: 2259, lga: "Lake Macquarie (City)" },
  { suburb: "Morisset Park", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Yarrawonga Park", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Silverwater", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Mirrabooka", postcode: 2264, lga: "Lake Macquarie (City)" },
  { suburb: "Lane Cove West", postcode: 2066, lga: "Lane Cove (Area)" },
  { suburb: "Lane Cove", postcode: 2066, lga: "Lane Cove (Area)" },
  { suburb: "Riverview", postcode: 2066, lga: "Lane Cove (Area)" },
  { suburb: "Lane Cove North", postcode: 2066, lga: "Lane Cove (Area)" },
  { suburb: "Longueville", postcode: 2066, lga: "Lane Cove (Area)" },
  { suburb: "Greenwich", postcode: 2065, lga: "Lane Cove (Area)" },
  { suburb: "St Leonards", postcode: 2065, lga: "Lane Cove (Area)" },
  { suburb: "Northwood", postcode: 2066, lga: "Lane Cove (Area)" },
  { suburb: "Linley Point", postcode: 2066, lga: "Lane Cove (Area)" },
  { suburb: "Euroley", postcode: 2700, lga: "Leeton (Area)" },
  { suburb: "Leeton", postcode: 2705, lga: "Leeton (Area)" },
  { suburb: "Gogeldrie", postcode: 2705, lga: "Leeton (Area)" },
  { suburb: "Yanco", postcode: 2703, lga: "Leeton (Area)" },
  { suburb: "Murrami", postcode: 2705, lga: "Leeton (Area)" },
  { suburb: "Cudgel", postcode: 2700, lga: "Leeton (Area)" },
  { suburb: "Corbie Hill", postcode: 2705, lga: "Leeton (Area)" },
  { suburb: "Merungle Hill", postcode: 2705, lga: "Leeton (Area)" },
  { suburb: "Stanbridge", postcode: 2705, lga: "Leeton (Area)" },
  { suburb: "Leichhardt", postcode: 2040, lga: "Inner West (Area)" },
  { suburb: "Lilyfield", postcode: 2040, lga: "Inner West (Area)" },
  { suburb: "Annandale", postcode: 2038, lga: "Inner West (Area)" },
  { suburb: "Rozelle", postcode: 2039, lga: "Inner West (Area)" },
  { suburb: "Balmain", postcode: 2041, lga: "Inner West (Area)" },
  { suburb: "Birchgrove", postcode: 2041, lga: "Inner West (Area)" },
  { suburb: "Balmain East", postcode: 2041, lga: "Inner West (Area)" },
  { suburb: "East Lismore", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Lismore", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Goonellabah", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "South Lismore", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "North Lismore", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Girards Hill", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Lismore Heights", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Loftville", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Howards Grass", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Lagoon Grass", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Chilcotts Grass", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Wyrallah", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "South Gundurimba", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Caniaba", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Boat Harbour", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Richmond Hill", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Lindendale", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Tregeagle", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Monaltrie", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Tucki Tucki", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Keerrong", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Mountain Top", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Tuckurimba", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Modanville", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Whian Whian", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Repentance Creek", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Ruthven", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Nimbin", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Blue Knob", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Georgica", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Terania Creek", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Tuntable Creek", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Coffee Camp", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Koonorigan", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Jiggi", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Rock Valley", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Bungabbee", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Blakebrook", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Tullera", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Woodlawn", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Numulgi", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Dunoon", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Dorroughby", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Rosebank", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Corndale", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Pearces Creek", postcode: 2477, lga: "Lismore (City)" },
  { suburb: "Bexhill", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Fernside", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Tuncester", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "North Woodburn", postcode: 2471, lga: "Lismore (City)" },
  { suburb: "Buckendoon", postcode: 2472, lga: "Lismore (City)" },
  { suburb: "Kilgin", postcode: 2472, lga: "Lismore (City)" },
  { suburb: "The Channon", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Eltham", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Dungarubba", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Green Forest", postcode: 2471, lga: "Lismore (City)" },
  { suburb: "Nightcap", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Goolmangar", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Leycester", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Bowenfels", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Hampton", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Sodwalls", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Running Stream", postcode: 2850, lga: "Lithgow (City)" },
  { suburb: "Brogans Creek", postcode: 2848, lga: "Lithgow (City)" },
  { suburb: "Upper Nile", postcode: 2849, lga: "Lithgow (City)" },
  { suburb: "Palmers Oaky", postcode: 2795, lga: "Lithgow (City)" },
  { suburb: "Hartley Vale", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Dargan", postcode: 2786, lga: "Lithgow (City)" },
  { suburb: "Round Swamp", postcode: 2846, lga: "Lithgow (City)" },
  { suburb: "Capertee", postcode: 2846, lga: "Lithgow (City)" },
  { suburb: "Portland", postcode: 2847, lga: "Lithgow (City)" },
  { suburb: "Little Hartley", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Ganbenang", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Wirraba", postcode: 2849, lga: "Lithgow (City)" },
  { suburb: "Kanimbla", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Glen Davis", postcode: 2846, lga: "Lithgow (City)" },
  { suburb: "Newnes", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Cullen Bullen", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Wollemi", postcode: 2330, lga: "Lithgow (City)" },
  { suburb: "Dark Corner", postcode: 2795, lga: "Lithgow (City)" },
  { suburb: "Wollangambe", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "McKellars Park", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Bogee", postcode: 2849, lga: "Lithgow (City)" },
  { suburb: "Mount Marsden", postcode: 2849, lga: "Lithgow (City)" },
  { suburb: "South Littleton", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Oakey Park", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Vale Of Clwydd", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Corney Town", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Lidsdale", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Hermitage Flat", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Lithgow", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Cobar Park", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Pottery Estate", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Sheedys Gully", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Littleton", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "South Bowenfels", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Morts Estate", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Wallerawang", postcode: 2845, lga: "Lithgow (City)" },
  { suburb: "Marrangaroo", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Hassans Walls", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Clarence", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Newnes Plateau", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "State Mine Gully", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Ben Bullen", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Springvale", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Hartley", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Glen Alice", postcode: 2849, lga: "Lithgow (City)" },
  { suburb: "Dunville Loop", postcode: 2849, lga: "Lithgow (City)" },
  { suburb: "Rydal", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Lowther", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Wolgan Valley", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Mount Lambie", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Doctors Gap", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Blackmans Flat", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Good Forest", postcode: 2790, lga: "Lithgow (City)" },
  { suburb: "Busby", postcode: 2168, lga: "Liverpool (City)" },
  { suburb: "Miller", postcode: 2168, lga: "Liverpool (City)" },
  { suburb: "Casula", postcode: 2170, lga: "Liverpool (City)" },
  { suburb: "Cartwright", postcode: 2168, lga: "Liverpool (City)" },
  { suburb: "Heckenberg", postcode: 2168, lga: "Liverpool (City)" },
  { suburb: "Sadleir", postcode: 2168, lga: "Liverpool (City)" },
  { suburb: "Lurnea", postcode: 2170, lga: "Liverpool (City)" },
  { suburb: "Ashcroft", postcode: 2168, lga: "Liverpool (City)" },
  { suburb: "Liverpool", postcode: 2170, lga: "Liverpool (City)" },
  { suburb: "Moorebank", postcode: 2170, lga: "Liverpool (City)" },
  { suburb: "Warwick Farm", postcode: 2170, lga: "Liverpool (City)" },
  { suburb: "Wattle Grove", postcode: 2173, lga: "Liverpool (City)" },
  { suburb: "Chipping Norton", postcode: 2170, lga: "Liverpool (City)" },
  { suburb: "Hammondville", postcode: 2170, lga: "Liverpool (City)" },
  { suburb: "Prestons", postcode: 2170, lga: "Liverpool (City)" },
  { suburb: "Voyager Point", postcode: 2172, lga: "Liverpool (City)" },
  { suburb: "Pleasure Point", postcode: 2172, lga: "Liverpool (City)" },
  { suburb: "West Hoxton", postcode: 2171, lga: "Liverpool (City)" },
  { suburb: "Cecil Hills", postcode: 2171, lga: "Liverpool (City)" },
  { suburb: "Hoxton Park", postcode: 2171, lga: "Liverpool (City)" },
  { suburb: "Hinchinbrook", postcode: 2168, lga: "Liverpool (City)" },
  { suburb: "Green Valley", postcode: 2168, lga: "Liverpool (City)" },
  { suburb: "Horningsea Park", postcode: 2171, lga: "Liverpool (City)" },
  { suburb: "Kemps Creek", postcode: 2178, lga: "Liverpool (City)" },
  { suburb: "Austral", postcode: 2179, lga: "Liverpool (City)" },
  { suburb: "Middleton Grange", postcode: 2171, lga: "Liverpool (City)" },
  { suburb: "Edmondson Park", postcode: 2174, lga: "Liverpool (City)" },
  { suburb: "Badgerys Creek", postcode: 2555, lga: "Liverpool (City)" },
  { suburb: "Luddenham", postcode: 2745, lga: "Liverpool (City)" },
  { suburb: "Greendale", postcode: 2745, lga: "Liverpool (City)" },
  { suburb: "Wallacia", postcode: 2745, lga: "Liverpool (City)" },
  { suburb: "Blackville", postcode: 2343, lga: "Liverpool Plains (Area)" },
  { suburb: "Warrah Creek", postcode: 2339, lga: "Liverpool Plains (Area)" },
  { suburb: "Big Jacks Creek", postcode: 2339, lga: "Liverpool Plains (Area)" },
  { suburb: "Ardglen", postcode: 2338, lga: "Liverpool Plains (Area)" },
  { suburb: "Willow Tree", postcode: 2339, lga: "Liverpool Plains (Area)" },
  { suburb: "Werris Creek", postcode: 2341, lga: "Liverpool Plains (Area)" },
  { suburb: "Quirindi", postcode: 2343, lga: "Liverpool Plains (Area)" },
  { suburb: "Quipolly", postcode: 2343, lga: "Liverpool Plains (Area)" },
  { suburb: "Wallabadah", postcode: 2343, lga: "Liverpool Plains (Area)" },
  { suburb: "Pine Ridge", postcode: 2343, lga: "Liverpool Plains (Area)" },
  { suburb: "Currabubula", postcode: 2342, lga: "Liverpool Plains (Area)" },
  { suburb: "Bundella", postcode: 2343, lga: "Liverpool Plains (Area)" },
  { suburb: "Braefield", postcode: 2339, lga: "Liverpool Plains (Area)" },
  { suburb: "Warrah Ridge", postcode: 2343, lga: "Liverpool Plains (Area)" },
  { suburb: "Yannergee", postcode: 2343, lga: "Liverpool Plains (Area)" },
  { suburb: "Colly Blue", postcode: 2343, lga: "Liverpool Plains (Area)" },
  { suburb: "Yarraman", postcode: 2343, lga: "Liverpool Plains (Area)" },
  { suburb: "Coomoo Coomoo", postcode: 2343, lga: "Liverpool Plains (Area)" },
  { suburb: "Windy", postcode: 2343, lga: "Liverpool Plains (Area)" },
  { suburb: "Cattle Creek", postcode: 2339, lga: "Liverpool Plains (Area)" },
  { suburb: "Parraweena", postcode: 2339, lga: "Liverpool Plains (Area)" },
  {
    suburb: "Macdonalds Creek",
    postcode: 2339,
    lga: "Liverpool Plains (Area)",
  },
  {
    suburb: "Little Jacks Creek",
    postcode: 2339,
    lga: "Liverpool Plains (Area)",
  },
  { suburb: "Blandford", postcode: 2338, lga: "Liverpool Plains (Area)" },
  { suburb: "Chilcotts Creek", postcode: 2339, lga: "Liverpool Plains (Area)" },
  { suburb: "Gowrie", postcode: 2340, lga: "Liverpool Plains (Area)" },
  { suburb: "Warrah", postcode: 2339, lga: "Liverpool Plains (Area)" },
  { suburb: "Lockhart", postcode: 2656, lga: "Lockhart (Area)" },
  { suburb: "Cullivel", postcode: 2645, lga: "Lockhart (Area)" },
  { suburb: "Yerong Creek", postcode: 2642, lga: "Lockhart (Area)" },
  { suburb: "Milbrulong", postcode: 2656, lga: "Lockhart (Area)" },
  { suburb: "The Rock", postcode: 2655, lga: "Lockhart (Area)" },
  { suburb: "Boree Creek", postcode: 2652, lga: "Lockhart (Area)" },
  { suburb: "Osborne", postcode: 2656, lga: "Lockhart (Area)" },
  { suburb: "Fargunyah", postcode: 2656, lga: "Lockhart (Area)" },
  { suburb: "Urana", postcode: 2645, lga: "Lockhart (Area)" },
  { suburb: "Urangeline", postcode: 2656, lga: "Lockhart (Area)" },
  { suburb: "Bidgeemia", postcode: 2642, lga: "Lockhart (Area)" },
  { suburb: "Urangeline East", postcode: 2656, lga: "Lockhart (Area)" },
  { suburb: "Pleasant Hills", postcode: 2658, lga: "Lockhart (Area)" },
  { suburb: "Ryan", postcode: 2658, lga: "Lockhart (Area)" },
  { suburb: "Munyabla", postcode: 2658, lga: "Lockhart (Area)" },
  { suburb: "Wrathall", postcode: 2642, lga: "Lockhart (Area)" },
  { suburb: "French Park", postcode: 2655, lga: "Lockhart (Area)" },
  { suburb: "Tootool", postcode: 2655, lga: "Lockhart (Area)" },
  { suburb: "Gillieston Heights", postcode: 2321, lga: "Maitland (City)" },
  { suburb: "Lambs Valley", postcode: 2335, lga: "Maitland (City)" },
  { suburb: "Mindaribba", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Metford", postcode: 2323, lga: "Maitland (City)" },
  { suburb: "Lochinvar", postcode: 2321, lga: "Maitland (City)" },
  { suburb: "Woodberry", postcode: 2322, lga: "Maitland (City)" },
  { suburb: "Maitland", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Windella", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Harpers Hill", postcode: 2321, lga: "Maitland (City)" },
  { suburb: "Thornton", postcode: 2322, lga: "Maitland (City)" },
  { suburb: "Anambah", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Rutherford", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "South Maitland", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "East Maitland", postcode: 2323, lga: "Maitland (City)" },
  { suburb: "Louth Park", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Woodville", postcode: 2321, lga: "Maitland (City)" },
  { suburb: "Farley", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Bolwarra", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Rosebrook", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Berry Park", postcode: 2321, lga: "Maitland (City)" },
  { suburb: "Morpeth", postcode: 2321, lga: "Maitland (City)" },
  { suburb: "Millers Forest", postcode: 2324, lga: "Maitland (City)" },
  { suburb: "Duckenfield", postcode: 2321, lga: "Maitland (City)" },
  { suburb: "Oswald", postcode: 2321, lga: "Maitland (City)" },
  { suburb: "Melville", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Phoenix Park", postcode: 2321, lga: "Maitland (City)" },
  { suburb: "Luskintyre", postcode: 2321, lga: "Maitland (City)" },
  { suburb: "Gosforth", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Lorn", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Bolwarra Heights", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Telarah", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Raworth", postcode: 2321, lga: "Maitland (City)" },
  { suburb: "Ashtonfield", postcode: 2323, lga: "Maitland (City)" },
  { suburb: "Aberglasslyn", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Tenambit", postcode: 2323, lga: "Maitland (City)" },
  { suburb: "Hillsborough", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Largs", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Pitnacree", postcode: 2323, lga: "Maitland (City)" },
  { suburb: "Horseshoe Bend", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Mount Dee", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Windermere", postcode: 2321, lga: "Maitland (City)" },
  { suburb: "Chisholm", postcode: 2322, lga: "Maitland (City)" },
  { suburb: "Oakhampton Heights", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Oakhampton", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Maitland Vale", postcode: 2320, lga: "Maitland (City)" },
  { suburb: "Seaforth", postcode: 2092, lga: "Northern Beaches (Area)" },
  { suburb: "Balgowlah", postcode: 2093, lga: "Northern Beaches (Area)" },
  {
    suburb: "Balgowlah Heights",
    postcode: 2093,
    lga: "Northern Beaches (Area)",
  },
  { suburb: "Fairlight", postcode: 2094, lga: "Northern Beaches (Area)" },
  { suburb: "Manly", postcode: 2095, lga: "Northern Beaches (Area)" },
  { suburb: "Clontarf", postcode: 2093, lga: "Northern Beaches (Area)" },
  { suburb: "Dulwich Hill", postcode: 2203, lga: "Inner West (Area)" },
  { suburb: "Marrickville", postcode: 2204, lga: "Inner West (Area)" },
  { suburb: "Lewisham", postcode: 2049, lga: "Inner West (Area)" },
  { suburb: "Petersham", postcode: 2049, lga: "Inner West (Area)" },
  { suburb: "Tempe", postcode: 2044, lga: "Inner West (Area)" },
  { suburb: "Stanmore", postcode: 2048, lga: "Inner West (Area)" },
  { suburb: "Sydenham", postcode: 2044, lga: "Inner West (Area)" },
  { suburb: "St Peters", postcode: 2044, lga: "Inner West (Area)" },
  { suburb: "Enmore", postcode: 2042, lga: "Inner West (Area)" },
  { suburb: "Newtown", postcode: 2042, lga: "Inner West (Area)" },
  { suburb: "Camperdown", postcode: 2050, lga: "Inner West (Area)" },
  { suburb: "Green Gully", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Windeyer", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Gulgong", postcode: 2852, lga: "Mid-Western Regional (Area)" },
  { suburb: "Mudgee", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Stubbo", postcode: 2852, lga: "Mid-Western Regional (Area)" },
  { suburb: "Linburn", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Stony Creek", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Turill", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Bara", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Yarrabin", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Cudgegong", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Tallawang", postcode: 2852, lga: "Mid-Western Regional (Area)" },
  { suburb: "Cope", postcode: 2852, lga: "Mid-Western Regional (Area)" },
  { suburb: "Bombira", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Beryl", postcode: 2852, lga: "Mid-Western Regional (Area)" },
  { suburb: "Mebul", postcode: 2852, lga: "Mid-Western Regional (Area)" },
  { suburb: "Birriwa", postcode: 2844, lga: "Mid-Western Regional (Area)" },
  { suburb: "Merotherie", postcode: 2852, lga: "Mid-Western Regional (Area)" },
  {
    suburb: "Barneys Reef",
    postcode: 2852,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "Moolarben", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Cumbo", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Mogo", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Ulan", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Wilpinjong", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Wollar", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Yarrawonga", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Cumbandry", postcode: 2852, lga: "Mid-Western Regional (Area)" },
  { suburb: "Home Rule", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Frog Rock", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "St Fillans", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Eurunderee", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  {
    suburb: "Apple Tree Flat",
    postcode: 2850,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "Meroo", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Carcalgong", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Bocoble", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Pyramul", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Hargraves", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Bungaba", postcode: 2852, lga: "Mid-Western Regional (Area)" },
  { suburb: "Twelve Mile", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Worlds End", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Menah", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Burrundulla", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Spring Flat", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  {
    suburb: "Mount Knowles",
    postcode: 2850,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "Barigan", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Tichular", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Avisford", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Buckaroo", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  {
    suburb: "Totnes Valley",
    postcode: 2850,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "Erudgere", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  {
    suburb: "Budgee Budgee",
    postcode: 2850,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "Botobolar", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Cooyal", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Cross Roads", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Hayes Gap", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Lue", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Mount Frome", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Galambine", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Cullenbone", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Wilbetree", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Triamble", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Grattai", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  {
    suburb: "Maitland Bar",
    postcode: 2850,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "Goolma", postcode: 2852, lga: "Mid-Western Regional (Area)" },
  {
    suburb: "Two Mile Flat",
    postcode: 2852,
    lga: "Mid-Western Regional (Area)",
  },
  {
    suburb: "Queens Pinch",
    postcode: 2850,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "Milroy", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Putta Bucca", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Munghorn", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Kains Flat", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Piambong", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Cooks Gap", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Collingwood", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Kandos", postcode: 2848, lga: "Mid-Western Regional (Area)" },
  { suburb: "Rylstone", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  { suburb: "Bylong", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  { suburb: "Murrumbo", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  { suburb: "Lee Creek", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  { suburb: "Coggan", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  { suburb: "Budden", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  {
    suburb: "Nullo Mountain",
    postcode: 2849,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "Ginghi", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  {
    suburb: "Upper Growee",
    postcode: 2849,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "Pyangle", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  { suburb: "Monivae", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  {
    suburb: "Pinnacle Swamp",
    postcode: 2849,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "Coxs Crown", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  { suburb: "Olinda", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  { suburb: "Clandulla", postcode: 2848, lga: "Mid-Western Regional (Area)" },
  { suburb: "Dabee", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  { suburb: "Charbon", postcode: 2848, lga: "Mid-Western Regional (Area)" },
  { suburb: "Ilford", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Aarons Pass", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Carwell", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  { suburb: "Camboon", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  {
    suburb: "Breakfast Creek",
    postcode: 2849,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "Growee", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  { suburb: "Weemelah", postcode: 2406, lga: "Moree Plains (Area)" },
  { suburb: "Bullarah", postcode: 2400, lga: "Moree Plains (Area)" },
  { suburb: "Collarenebri", postcode: 2833, lga: "Moree Plains (Area)" },
  { suburb: "Rowena", postcode: 2387, lga: "Moree Plains (Area)" },
  { suburb: "Mallowa", postcode: 2400, lga: "Moree Plains (Area)" },
  { suburb: "Millie", postcode: 2397, lga: "Moree Plains (Area)" },
  { suburb: "Gurley", postcode: 2398, lga: "Moree Plains (Area)" },
  { suburb: "Boomi", postcode: 2405, lga: "Moree Plains (Area)" },
  { suburb: "Moree", postcode: 2400, lga: "Moree Plains (Area)" },
  { suburb: "Berrigal", postcode: 2390, lga: "Moree Plains (Area)" },
  { suburb: "Terry Hie Hie", postcode: 2400, lga: "Moree Plains (Area)" },
  { suburb: "Garah", postcode: 2405, lga: "Moree Plains (Area)" },
  { suburb: "Ashley", postcode: 2400, lga: "Moree Plains (Area)" },
  { suburb: "Mungindi", postcode: 2406, lga: "Moree Plains (Area)" },
  { suburb: "Boggabilla", postcode: 2409, lga: "Moree Plains (Area)" },
  { suburb: "Biniguy", postcode: 2399, lga: "Moree Plains (Area)" },
  { suburb: "Bellata", postcode: 2397, lga: "Moree Plains (Area)" },
  { suburb: "Mosman", postcode: 2088, lga: "Mosman (Area)" },
  { suburb: "Cremorne Point", postcode: 2090, lga: "North Sydney (Area)" },
  { suburb: "Moama", postcode: 2731, lga: "Murray River (Area)" },
  { suburb: "Calimo", postcode: 2710, lga: "Murray River (Area)" },
  { suburb: "Thule", postcode: 2732, lga: "Murray River (Area)" },
  { suburb: "Mathoura", postcode: 2710, lga: "Murray River (Area)" },
  { suburb: "Bullatale", postcode: 2710, lga: "Murray River (Area)" },
  { suburb: "Caldwell", postcode: 2710, lga: "Murray River (Area)" },
  { suburb: "Tantonan", postcode: 2731, lga: "Murray River (Area)" },
  { suburb: "Womboota", postcode: 2731, lga: "Murray River (Area)" },
  { suburb: "Aratula", postcode: 2714, lga: "Murray River (Area)" },
  { suburb: "Bunnaloo", postcode: 2731, lga: "Murray River (Area)" },
  { suburb: "Steam Plains", postcode: 2710, lga: "Murrumbidgee (Area)" },
  { suburb: "Darlington Point", postcode: 2706, lga: "Murrumbidgee (Area)" },
  { suburb: "McCullys Gap", postcode: 2333, lga: "Muswellbrook (Area)" },
  { suburb: "Bowmans Creek", postcode: 2330, lga: "Muswellbrook (Area)" },
  { suburb: "Jerrys Plains", postcode: 2330, lga: "Muswellbrook (Area)" },
  { suburb: "Denman", postcode: 2328, lga: "Muswellbrook (Area)" },
  { suburb: "Bengalla", postcode: 2333, lga: "Muswellbrook (Area)" },
  { suburb: "Muswellbrook", postcode: 2333, lga: "Muswellbrook (Area)" },
  { suburb: "Baerami Creek", postcode: 2333, lga: "Muswellbrook (Area)" },
  { suburb: "Hebden", postcode: 2330, lga: "Muswellbrook (Area)" },
  { suburb: "Edderton", postcode: 2333, lga: "Muswellbrook (Area)" },
  { suburb: "Muscle Creek", postcode: 2333, lga: "Muswellbrook (Area)" },
  { suburb: "Kayuga", postcode: 2333, lga: "Muswellbrook (Area)" },
  { suburb: "Manobalai", postcode: 2333, lga: "Muswellbrook (Area)" },
  { suburb: "Castle Rock", postcode: 2333, lga: "Muswellbrook (Area)" },
  { suburb: "Giants Creek", postcode: 2328, lga: "Muswellbrook (Area)" },
  { suburb: "Wybong", postcode: 2333, lga: "Muswellbrook (Area)" },
  { suburb: "Mangoola", postcode: 2328, lga: "Muswellbrook (Area)" },
  { suburb: "Sandy Hollow", postcode: 2333, lga: "Muswellbrook (Area)" },
  { suburb: "Hollydeen", postcode: 2328, lga: "Muswellbrook (Area)" },
  { suburb: "Bureen", postcode: 2328, lga: "Muswellbrook (Area)" },
  { suburb: "Dalswinton", postcode: 2328, lga: "Muswellbrook (Area)" },
  { suburb: "Martindale", postcode: 2328, lga: "Muswellbrook (Area)" },
  { suburb: "Widden", postcode: 2328, lga: "Muswellbrook (Area)" },
  { suburb: "Kerrabee", postcode: 2328, lga: "Muswellbrook (Area)" },
  { suburb: "Baerami", postcode: 2333, lga: "Muswellbrook (Area)" },
  { suburb: "Yarrawa", postcode: 2328, lga: "Muswellbrook (Area)" },
  { suburb: "Rouchel Brook", postcode: 2336, lga: "Muswellbrook (Area)" },
  { suburb: "Nambucca Heads", postcode: 2448, lga: "Nambucca (Area)" },
  { suburb: "Scotts Head", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Killiekrankie", postcode: 2449, lga: "Nambucca (Area)" },
  { suburb: "Thumb Creek", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Upper Taylors Arm", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Bakers Creek", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Allgomera", postcode: 2441, lga: "Nambucca (Area)" },
  { suburb: "Way Way", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Hyland Park", postcode: 2448, lga: "Nambucca (Area)" },
  { suburb: "Valla Beach", postcode: 2448, lga: "Nambucca (Area)" },
  { suburb: "Gumma", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "North Macksville", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Burrapine", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Buckra Bendinni", postcode: 2449, lga: "Nambucca (Area)" },
  { suburb: "Argents Hill", postcode: 2449, lga: "Nambucca (Area)" },
  { suburb: "Girralong", postcode: 2449, lga: "Nambucca (Area)" },
  { suburb: "Tewinga", postcode: 2449, lga: "Nambucca (Area)" },
  { suburb: "Eungai Creek", postcode: 2441, lga: "Nambucca (Area)" },
  { suburb: "Macksville", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Wirrimbi", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Tamban", postcode: 2441, lga: "Nambucca (Area)" },
  { suburb: "Newee Creek", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Bowraville", postcode: 2449, lga: "Nambucca (Area)" },
  { suburb: "Taylors Arm", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "South Arm", postcode: 2449, lga: "Nambucca (Area)" },
  { suburb: "Missabotti", postcode: 2449, lga: "Nambucca (Area)" },
  { suburb: "Congarinni North", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Warrell Creek", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Congarinni", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Utungun", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Eungai Rail", postcode: 2441, lga: "Nambucca (Area)" },
  { suburb: "Yarranbella", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Talarm", postcode: 2447, lga: "Nambucca (Area)" },
  { suburb: "Bulyeroi", postcode: 2387, lga: "Narrabri (Area)" },
  { suburb: "Spring Plains", postcode: 2388, lga: "Narrabri (Area)" },
  { suburb: "Wee Waa", postcode: 2388, lga: "Narrabri (Area)" },
  { suburb: "Jacks Creek", postcode: 2390, lga: "Narrabri (Area)" },
  { suburb: "Narrabri", postcode: 2390, lga: "Narrabri (Area)" },
  { suburb: "Eulah Creek", postcode: 2390, lga: "Narrabri (Area)" },
  { suburb: "Maules Creek", postcode: 2382, lga: "Narrabri (Area)" },
  { suburb: "Harparary", postcode: 2390, lga: "Narrabri (Area)" },
  { suburb: "Baan Baa", postcode: 2390, lga: "Narrabri (Area)" },
  { suburb: "Bohena Creek", postcode: 2390, lga: "Narrabri (Area)" },
  { suburb: "Gwabegar", postcode: 2356, lga: "Narrabri (Area)" },
  { suburb: "Couradda", postcode: 2390, lga: "Narrabri (Area)" },
  { suburb: "Merah North", postcode: 2388, lga: "Narrabri (Area)" },
  { suburb: "Tarriaro", postcode: 2390, lga: "Narrabri (Area)" },
  { suburb: "Turrawan", postcode: 2390, lga: "Narrabri (Area)" },
  { suburb: "Bullawa Creek", postcode: 2390, lga: "Narrabri (Area)" },
  { suburb: "Kaputar", postcode: 2390, lga: "Narrabri (Area)" },
  { suburb: "Yarrie Lake", postcode: 2388, lga: "Narrabri (Area)" },
  { suburb: "Drildool", postcode: 2386, lga: "Narrabri (Area)" },
  { suburb: "Cuttabri", postcode: 2388, lga: "Narrabri (Area)" },
  { suburb: "Jews Lagoon", postcode: 2397, lga: "Narrabri (Area)" },
  { suburb: "Nowley", postcode: 2386, lga: "Narrabri (Area)" },
  { suburb: "Boolcarroll", postcode: 2388, lga: "Narrabri (Area)" },
  { suburb: "Corobimilla", postcode: 2700, lga: "Narrandera (Area)" },
  { suburb: "Sandigo", postcode: 2700, lga: "Narrandera (Area)" },
  { suburb: "Moombooldool", postcode: 2665, lga: "Narrandera (Area)" },
  { suburb: "Galore", postcode: 2650, lga: "Narrandera (Area)" },
  { suburb: "Grong Grong", postcode: 2652, lga: "Narrandera (Area)" },
  { suburb: "Narrandera", postcode: 2700, lga: "Narrandera (Area)" },
  { suburb: "Brobenah", postcode: 2705, lga: "Narrandera (Area)" },
  { suburb: "Colinroobie", postcode: 2700, lga: "Narrandera (Area)" },
  { suburb: "Morundah", postcode: 2700, lga: "Narrandera (Area)" },
  { suburb: "Gillenbah", postcode: 2700, lga: "Narrandera (Area)" },
  { suburb: "Landervale", postcode: 2652, lga: "Narrandera (Area)" },
  { suburb: "Narromine", postcode: 2821, lga: "Narromine (Area)" },
  { suburb: "Trangie", postcode: 2823, lga: "Narromine (Area)" },
  { suburb: "Tomingley", postcode: 2869, lga: "Narromine (Area)" },
  { suburb: "Burroway", postcode: 2821, lga: "Narromine (Area)" },
  { suburb: "Dandaloo", postcode: 2823, lga: "Narromine (Area)" },
  { suburb: "Gin Gin", postcode: 2823, lga: "Narromine (Area)" },
  { suburb: "Bundemar", postcode: 2823, lga: "Narromine (Area)" },
  { suburb: "Merewether", postcode: 2291, lga: "Newcastle (City)" },
  { suburb: "Mayfield West", postcode: 2304, lga: "Newcastle (City)" },
  { suburb: "Mayfield", postcode: 2304, lga: "Newcastle (City)" },
  { suburb: "Wickham", postcode: 2293, lga: "Newcastle (City)" },
  { suburb: "Maryville", postcode: 2293, lga: "Newcastle (City)" },
  { suburb: "Merewether Heights", postcode: 2291, lga: "Newcastle (City)" },
  { suburb: "The Junction", postcode: 2291, lga: "Newcastle (City)" },
  { suburb: "Carrington", postcode: 2294, lga: "Newcastle (City)" },
  { suburb: "Mayfield North", postcode: 2304, lga: "Newcastle (City)" },
  { suburb: "Stockton", postcode: 2295, lga: "Newcastle (City)" },
  { suburb: "The Hill", postcode: 2300, lga: "Newcastle (City)" },
  { suburb: "Newcastle West", postcode: 2302, lga: "Newcastle (City)" },
  { suburb: "Kooragang", postcode: 2304, lga: "Newcastle (City)" },
  { suburb: "Newcastle East", postcode: 2300, lga: "Newcastle (City)" },
  { suburb: "Newcastle", postcode: 2300, lga: "Newcastle (City)" },
  { suburb: "Bar Beach", postcode: 2300, lga: "Newcastle (City)" },
  { suburb: "Islington", postcode: 2296, lga: "Newcastle (City)" },
  { suburb: "Warabrook", postcode: 2304, lga: "Newcastle (City)" },
  { suburb: "Tighes Hill", postcode: 2297, lga: "Newcastle (City)" },
  { suburb: "Cooks Hill", postcode: 2300, lga: "Newcastle (City)" },
  { suburb: "Mayfield East", postcode: 2304, lga: "Newcastle (City)" },
  { suburb: "Beresfield", postcode: 2322, lga: "Newcastle (City)" },
  { suburb: "Maryland", postcode: 2287, lga: "Newcastle (City)" },
  { suburb: "Elermore Vale", postcode: 2287, lga: "Newcastle (City)" },
  { suburb: "Wallsend", postcode: 2287, lga: "Newcastle (City)" },
  { suburb: "Jesmond", postcode: 2299, lga: "Newcastle (City)" },
  { suburb: "Birmingham Gardens", postcode: 2287, lga: "Newcastle (City)" },
  { suburb: "Sandgate", postcode: 2304, lga: "Newcastle (City)" },
  { suburb: "Shortland", postcode: 2307, lga: "Newcastle (City)" },
  { suburb: "Tarro", postcode: 2322, lga: "Newcastle (City)" },
  { suburb: "Minmi", postcode: 2287, lga: "Newcastle (City)" },
  { suburb: "Hexham", postcode: 2322, lga: "Newcastle (City)" },
  { suburb: "Fletcher", postcode: 2287, lga: "Newcastle (City)" },
  { suburb: "Lenaghan", postcode: 2322, lga: "Newcastle (City)" },
  { suburb: "Callaghan", postcode: 2308, lga: "Newcastle (City)" },
  { suburb: "Hamilton East", postcode: 2303, lga: "Newcastle (City)" },
  { suburb: "Kotara", postcode: 2289, lga: "Newcastle (City)" },
  { suburb: "Lambton", postcode: 2299, lga: "Newcastle (City)" },
  { suburb: "Hamilton South", postcode: 2303, lga: "Newcastle (City)" },
  { suburb: "North Lambton", postcode: 2299, lga: "Newcastle (City)" },
  { suburb: "New Lambton", postcode: 2305, lga: "Newcastle (City)" },
  { suburb: "Waratah West", postcode: 2298, lga: "Newcastle (City)" },
  { suburb: "Adamstown", postcode: 2289, lga: "Newcastle (City)" },
  { suburb: "Waratah", postcode: 2298, lga: "Newcastle (City)" },
  { suburb: "Georgetown", postcode: 2298, lga: "Newcastle (City)" },
  { suburb: "Hamilton North", postcode: 2292, lga: "Newcastle (City)" },
  { suburb: "Hamilton", postcode: 2303, lga: "Newcastle (City)" },
  { suburb: "Broadmeadow", postcode: 2292, lga: "Newcastle (City)" },
  { suburb: "Waverton", postcode: 2060, lga: "North Sydney (Area)" },
  { suburb: "Wollstonecraft", postcode: 2065, lga: "North Sydney (Area)" },
  { suburb: "Neutral Bay", postcode: 2089, lga: "North Sydney (Area)" },
  { suburb: "Kirribilli", postcode: 2061, lga: "North Sydney (Area)" },
  { suburb: "Crows Nest", postcode: 2065, lga: "North Sydney (Area)" },
  { suburb: "Milsons Point", postcode: 2061, lga: "North Sydney (Area)" },
  { suburb: "Cammeray", postcode: 2062, lga: "North Sydney (Area)" },
  { suburb: "North Sydney", postcode: 2060, lga: "North Sydney (Area)" },
  { suburb: "Lavender Bay", postcode: 2060, lga: "North Sydney (Area)" },
  { suburb: "Kurraba Point", postcode: 2089, lga: "North Sydney (Area)" },
  { suburb: "McMahons Point", postcode: 2060, lga: "North Sydney (Area)" },
  { suburb: "Cremorne", postcode: 2090, lga: "North Sydney (Area)" },
  { suburb: "Burraga", postcode: 2795, lga: "Oberon (Area)" },
  { suburb: "Isabella", postcode: 2795, lga: "Oberon (Area)" },
  { suburb: "Rockley", postcode: 2795, lga: "Oberon (Area)" },
  { suburb: "Shooters Hill", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Porters Retreat", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Mozart", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Arkstone", postcode: 2795, lga: "Oberon (Area)" },
  { suburb: "Black Springs", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Judds Creek", postcode: 2795, lga: "Oberon (Area)" },
  { suburb: "Mount David", postcode: 2795, lga: "Oberon (Area)" },
  { suburb: "Jeremy", postcode: 2795, lga: "Oberon (Area)" },
  { suburb: "Gingkin", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Jaunter", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Edith", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Chatham Valley", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Norway", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Oberon", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Dog Rocks", postcode: 2795, lga: "Oberon (Area)" },
  { suburb: "Essington", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Twenty Forests", postcode: 2795, lga: "Oberon (Area)" },
  { suburb: "Mayfield", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Hazelgrove", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "The Meadows", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Duckmaloi", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Wiarborough", postcode: 2580, lga: "Oberon (Area)" },
  { suburb: "Kanangra", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Mount Olive", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Gurnang", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Mount Werong", postcode: 2787, lga: "Oberon (Area)" },
  { suburb: "Jenolan", postcode: 2790, lga: "Oberon (Area)" },
  { suburb: "Ballyroe", postcode: 2795, lga: "Oberon (Area)" },
  { suburb: "Paling Yards", postcode: 2580, lga: "Oberon (Area)" },
  { suburb: "Jerrong", postcode: 2580, lga: "Oberon (Area)" },
  { suburb: "Lucknow", postcode: 2800, lga: "Orange (City)" },
  { suburb: "Spring Creek", postcode: 2800, lga: "Orange (City)" },
  { suburb: "Spring Hill", postcode: 2800, lga: "Orange (City)" },
  { suburb: "Huntley", postcode: 2800, lga: "Orange (City)" },
  { suburb: "Shadforth", postcode: 2800, lga: "Orange (City)" },
  {
    suburb: "Forbes Creek",
    postcode: 2621,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Yarrow",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Urila",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Googong",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Lake George",
    postcode: 2581,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Rossi",
    postcode: 2621,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Hoskinstown",
    postcode: 2621,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Captains Flat",
    postcode: 2623,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Bungendore",
    postcode: 2621,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Royalla",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Bywong",
    postcode: 2621,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Sutton",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Carwoola",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Wamboin",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Primrose Valley",
    postcode: 2621,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Jembaicumbene",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Braidwood",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Jinden",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Snowball",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Hereford Hall",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Back Creek",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Mongarlowe",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Monga",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Mulloon",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Farringdon",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Harolds Cross",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Kindervale",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Palerang",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Bombay",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Northangera",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Majors Creek",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Berlang",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Wyanbene",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Jerrabattgulla",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Manar",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Warri",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Boro",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Collector",
    postcode: 2581,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Currawang",
    postcode: 2580,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Bendoura",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Mayfield",
    postcode: 2580,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Marlowe",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Corang",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Wog Wog",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Charleys Forest",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Durran Durra",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Araluen",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Nerriga",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Budawang",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Tomboye",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  { suburb: "Cooks Myalls", postcode: 2870, lga: "Parkes (Area)" },
  { suburb: "Goonumbla", postcode: 2870, lga: "Parkes (Area)" },
  { suburb: "Parkes", postcode: 2870, lga: "Parkes (Area)" },
  { suburb: "Peak Hill", postcode: 2869, lga: "Parkes (Area)" },
  { suburb: "Trundle", postcode: 2875, lga: "Parkes (Area)" },
  { suburb: "Bogan Gate", postcode: 2876, lga: "Parkes (Area)" },
  { suburb: "Nelungaloo", postcode: 2876, lga: "Parkes (Area)" },
  { suburb: "Bruie Plains", postcode: 2875, lga: "Parkes (Area)" },
  { suburb: "Gunningbland", postcode: 2876, lga: "Parkes (Area)" },
  { suburb: "Trewilga", postcode: 2869, lga: "Parkes (Area)" },
  { suburb: "Harris Park", postcode: 2150, lga: "Parramatta (City)" },
  { suburb: "Rosehill", postcode: 2142, lga: "Parramatta (City)" },
  { suburb: "Clyde", postcode: 2142, lga: "Parramatta (City)" },
  { suburb: "Rydalmere", postcode: 2116, lga: "Parramatta (City)" },
  { suburb: "Camellia", postcode: 2142, lga: "Parramatta (City)" },
  { suburb: "Telopea", postcode: 2117, lga: "Parramatta (City)" },
  { suburb: "Dundas", postcode: 2117, lga: "Parramatta (City)" },
  { suburb: "Dundas Valley", postcode: 2117, lga: "Parramatta (City)" },
  { suburb: "Ermington", postcode: 2115, lga: "Parramatta (City)" },
  { suburb: "Melrose Park", postcode: 2114, lga: "Parramatta (City)" },
  { suburb: "Old Toongabbie", postcode: 2146, lga: "Parramatta (City)" },
  { suburb: "Constitution Hill", postcode: 2145, lga: "Parramatta (City)" },
  { suburb: "South Granville", postcode: 2142, lga: "Cumberland (Area)" },
  { suburb: "Kingswood", postcode: 2747, lga: "Penrith (City)" },
  { suburb: "Cambridge Park", postcode: 2747, lga: "Penrith (City)" },
  { suburb: "Cambridge Gardens", postcode: 2747, lga: "Penrith (City)" },
  { suburb: "Werrington Downs", postcode: 2747, lga: "Penrith (City)" },
  { suburb: "Londonderry", postcode: 2753, lga: "Penrith (City)" },
  { suburb: "Werrington County", postcode: 2747, lga: "Penrith (City)" },
  { suburb: "Werrington", postcode: 2747, lga: "Penrith (City)" },
  { suburb: "St Clair", postcode: 2759, lga: "Penrith (City)" },
  { suburb: "St Marys", postcode: 2760, lga: "Penrith (City)" },
  { suburb: "North St Marys", postcode: 2760, lga: "Penrith (City)" },
  { suburb: "Colyton", postcode: 2760, lga: "Penrith (City)" },
  { suburb: "Oxley Park", postcode: 2760, lga: "Penrith (City)" },
  { suburb: "Erskine Park", postcode: 2759, lga: "Penrith (City)" },
  { suburb: "Mulgoa", postcode: 2745, lga: "Penrith (City)" },
  { suburb: "Caddens", postcode: 2747, lga: "Penrith (City)" },
  { suburb: "Jordan Springs", postcode: 2747, lga: "Penrith (City)" },
  { suburb: "Berkshire Park", postcode: 2765, lga: "Penrith (City)" },
  { suburb: "Llandilo", postcode: 2747, lga: "Penrith (City)" },
  { suburb: "Claremont Meadows", postcode: 2747, lga: "Penrith (City)" },
  { suburb: "Mount Vernon", postcode: 2178, lga: "Penrith (City)" },
  { suburb: "Orchard Hills", postcode: 2748, lga: "Penrith (City)" },
  { suburb: "Emu Heights", postcode: 2750, lga: "Penrith (City)" },
  { suburb: "Emu Plains", postcode: 2750, lga: "Penrith (City)" },
  { suburb: "Leonay", postcode: 2750, lga: "Penrith (City)" },
  { suburb: "Glenmore Park", postcode: 2745, lga: "Penrith (City)" },
  { suburb: "Regentville", postcode: 2745, lga: "Penrith (City)" },
  { suburb: "Jamisontown", postcode: 2750, lga: "Penrith (City)" },
  { suburb: "Penrith", postcode: 2750, lga: "Penrith (City)" },
  { suburb: "South Penrith", postcode: 2750, lga: "Penrith (City)" },
  { suburb: "Cranebrook", postcode: 2749, lga: "Penrith (City)" },
  { suburb: "Castlereagh", postcode: 2749, lga: "Penrith (City)" },
  { suburb: "North Narrabeen", postcode: 2101, lga: "Northern Beaches (Area)" },
  { suburb: "Warriewood", postcode: 2102, lga: "Northern Beaches (Area)" },
  { suburb: "Mona Vale", postcode: 2103, lga: "Northern Beaches (Area)" },
  { suburb: "Bayview", postcode: 2104, lga: "Northern Beaches (Area)" },
  { suburb: "Newport", postcode: 2106, lga: "Northern Beaches (Area)" },
  { suburb: "Bilgola Plateau", postcode: 2107, lga: "Northern Beaches (Area)" },
  { suburb: "Avalon Beach", postcode: 2107, lga: "Northern Beaches (Area)" },
  { suburb: "Whale Beach", postcode: 2107, lga: "Northern Beaches (Area)" },
  {
    suburb: "Great Mackerel Beach",
    postcode: 2108,
    lga: "Northern Beaches (Area)",
  },
  { suburb: "Lovett Bay", postcode: 2105, lga: "Northern Beaches (Area)" },
  { suburb: "Morning Bay", postcode: 2105, lga: "Northern Beaches (Area)" },
  { suburb: "Church Point", postcode: 2105, lga: "Northern Beaches (Area)" },
  { suburb: "Ingleside", postcode: 2101, lga: "Northern Beaches (Area)" },
  { suburb: "Bilgola Beach", postcode: 2107, lga: "Northern Beaches (Area)" },
  { suburb: "Palm Beach", postcode: 2108, lga: "Northern Beaches (Area)" },
  { suburb: "Scotland Island", postcode: 2105, lga: "Northern Beaches (Area)" },
  { suburb: "Clareville", postcode: 2107, lga: "Northern Beaches (Area)" },
  { suburb: "Elanora Heights", postcode: 2101, lga: "Northern Beaches (Area)" },
  { suburb: "Elvina Bay", postcode: 2105, lga: "Northern Beaches (Area)" },
  {
    suburb: "Ku-Ring-Gai Chase",
    postcode: 2084,
    lga: "Northern Beaches (Area)",
  },
  { suburb: "Raymond Terrace", postcode: 2324, lga: "Port Stephens (Area)" },
  { suburb: "Medowie", postcode: 2318, lga: "Port Stephens (Area)" },
  { suburb: "Port Stephens", postcode: 2324, lga: "Port Stephens (Area)" },
  { suburb: "Tanilba Bay", postcode: 2319, lga: "Port Stephens (Area)" },
  { suburb: "Tilligerry Creek", postcode: 2319, lga: "Port Stephens (Area)" },
  { suburb: "Mallabula", postcode: 2319, lga: "Port Stephens (Area)" },
  { suburb: "Lemon Tree Passage", postcode: 2319, lga: "Port Stephens (Area)" },
  { suburb: "Soldiers Point", postcode: 2317, lga: "Port Stephens (Area)" },
  { suburb: "Salamander Bay", postcode: 2317, lga: "Port Stephens (Area)" },
  { suburb: "Anna Bay", postcode: 2316, lga: "Port Stephens (Area)" },
  { suburb: "Fishermans Bay", postcode: 2316, lga: "Port Stephens (Area)" },
  { suburb: "Corlette", postcode: 2315, lga: "Port Stephens (Area)" },
  { suburb: "Boat Harbour", postcode: 2316, lga: "Port Stephens (Area)" },
  { suburb: "Nelson Bay", postcode: 2315, lga: "Port Stephens (Area)" },
  { suburb: "Fingal Bay", postcode: 2315, lga: "Port Stephens (Area)" },
  { suburb: "Shoal Bay", postcode: 2315, lga: "Port Stephens (Area)" },
  { suburb: "Taylors Beach", postcode: 2316, lga: "Port Stephens (Area)" },
  { suburb: "One Mile", postcode: 2316, lga: "Port Stephens (Area)" },
  { suburb: "Bobs Farm", postcode: 2319, lga: "Port Stephens (Area)" },
  { suburb: "Salt Ash", postcode: 2318, lga: "Port Stephens (Area)" },
  { suburb: "Fullerton Cove", postcode: 2318, lga: "Port Stephens (Area)" },
  { suburb: "Williamtown", postcode: 2314, lga: "Port Stephens (Area)" },
  { suburb: "Fern Bay", postcode: 2295, lga: "Port Stephens (Area)" },
  { suburb: "Tomago", postcode: 2322, lga: "Port Stephens (Area)" },
  { suburb: "Heatherbrae", postcode: 2324, lga: "Port Stephens (Area)" },
  { suburb: "Nelsons Plains", postcode: 2324, lga: "Port Stephens (Area)" },
  { suburb: "Ferodale", postcode: 2318, lga: "Port Stephens (Area)" },
  { suburb: "Osterley", postcode: 2324, lga: "Port Stephens (Area)" },
  { suburb: "Hinton", postcode: 2321, lga: "Port Stephens (Area)" },
  { suburb: "Wallalong", postcode: 2320, lga: "Port Stephens (Area)" },
  { suburb: "Butterwick", postcode: 2321, lga: "Port Stephens (Area)" },
  { suburb: "Seaham", postcode: 2324, lga: "Port Stephens (Area)" },
  { suburb: "Brandy Hill", postcode: 2324, lga: "Port Stephens (Area)" },
  { suburb: "Duns Creek", postcode: 2321, lga: "Port Stephens (Area)" },
  { suburb: "East Seaham", postcode: 2324, lga: "Port Stephens (Area)" },
  { suburb: "Eagleton", postcode: 2324, lga: "Port Stephens (Area)" },
  { suburb: "Balickera", postcode: 2324, lga: "Port Stephens (Area)" },
  { suburb: "Swan Bay", postcode: 2324, lga: "Port Stephens (Area)" },
  { suburb: "Campvale", postcode: 2318, lga: "Port Stephens (Area)" },
  { suburb: "Twelve Mile Creek", postcode: 2324, lga: "Port Stephens (Area)" },
  {
    suburb: "Queanbeyan West",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Crestwood",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Karabar",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Jerrabomberra",
    postcode: 2619,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Queanbeyan",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Queanbeyan East",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Tralee",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Greenleigh",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "The Ridgeway",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  { suburb: "Kensington", postcode: 2033, lga: "Randwick (City)" },
  { suburb: "Kingsford", postcode: 2032, lga: "Randwick (City)" },
  { suburb: "Maroubra", postcode: 2035, lga: "Randwick (City)" },
  { suburb: "Matraville", postcode: 2036, lga: "Randwick (City)" },
  { suburb: "Clovelly", postcode: 2031, lga: "Randwick (City)" },
  { suburb: "Phillip Bay", postcode: 2036, lga: "Randwick (City)" },
  { suburb: "Randwick", postcode: 2031, lga: "Randwick (City)" },
  { suburb: "Little Bay", postcode: 2036, lga: "Randwick (City)" },
  { suburb: "Chifley", postcode: 2036, lga: "Randwick (City)" },
  { suburb: "Centennial Park", postcode: 2021, lga: "Randwick (City)" },
  { suburb: "Malabar", postcode: 2036, lga: "Randwick (City)" },
  { suburb: "Coogee", postcode: 2034, lga: "Randwick (City)" },
  { suburb: "South Coogee", postcode: 2034, lga: "Randwick (City)" },
  { suburb: "La Perouse", postcode: 2036, lga: "Randwick (City)" },
  { suburb: "Port Botany", postcode: 2036, lga: "Randwick (City)" },
  { suburb: "Casino", postcode: 2470, lga: "Richmond Valley (Area)" },
  { suburb: "Bexley North", postcode: 2207, lga: "Rockdale (City)" },
  { suburb: "Bexley", postcode: 2207, lga: "Rockdale (City)" },
  { suburb: "Bardwell Park", postcode: 2207, lga: "Rockdale (City)" },
  { suburb: "Arncliffe", postcode: 2205, lga: "Rockdale (City)" },
  { suburb: "Rockdale", postcode: 2216, lga: "Rockdale (City)" },
  { suburb: "Bardwell Valley", postcode: 2207, lga: "Rockdale (City)" },
  { suburb: "Banksia", postcode: 2216, lga: "Rockdale (City)" },
  { suburb: "Sandringham", postcode: 2219, lga: "Rockdale (City)" },
  { suburb: "Turrella", postcode: 2205, lga: "Rockdale (City)" },
  { suburb: "Brighton-le-Sands", postcode: 2216, lga: "Rockdale (City)" },
  { suburb: "Wolli Creek", postcode: 2205, lga: "Rockdale (City)" },
  { suburb: "Kyeemagh", postcode: 2216, lga: "Rockdale (City)" },
  { suburb: "Monterey", postcode: 2217, lga: "Rockdale (City)" },
  { suburb: "Dolls Point", postcode: 2219, lga: "Rockdale (City)" },
  { suburb: "Ramsgate Beach", postcode: 2217, lga: "Rockdale (City)" },
  { suburb: "West Ryde", postcode: 2114, lga: "Ryde (City)" },
  { suburb: "Denistone", postcode: 2114, lga: "Ryde (City)" },
  { suburb: "Denistone East", postcode: 2112, lga: "Ryde (City)" },
  { suburb: "Ryde", postcode: 2112, lga: "Ryde (City)" },
  { suburb: "Marsfield", postcode: 2122, lga: "Ryde (City)" },
  { suburb: "Putney", postcode: 2112, lga: "Ryde (City)" },
  { suburb: "North Ryde", postcode: 2113, lga: "Ryde (City)" },
  { suburb: "Tennyson Point", postcode: 2111, lga: "Ryde (City)" },
  { suburb: "East Ryde", postcode: 2113, lga: "Ryde (City)" },
  { suburb: "Chatswood West", postcode: 2067, lga: "Ryde (City)" },
  { suburb: "Macquarie Park", postcode: 2113, lga: "Ryde (City)" },
  { suburb: "Denistone West", postcode: 2114, lga: "Ryde (City)" },
  { suburb: "Meadowbank", postcode: 2114, lga: "Ryde (City)" },
  { suburb: "Blackbutt", postcode: 2529, lga: "Shellharbour (City)" },
  { suburb: "Flinders", postcode: 2529, lga: "Shellharbour (City)" },
  { suburb: "Shellharbour", postcode: 2529, lga: "Shellharbour (City)" },
  { suburb: "Warilla", postcode: 2528, lga: "Shellharbour (City)" },
  { suburb: "Barrack Heights", postcode: 2528, lga: "Shellharbour (City)" },
  { suburb: "Oak Flats", postcode: 2529, lga: "Shellharbour (City)" },
  { suburb: "Mount Warrigal", postcode: 2528, lga: "Shellharbour (City)" },
  { suburb: "Lake Illawarra", postcode: 2528, lga: "Shellharbour (City)" },
  { suburb: "Albion Park", postcode: 2527, lga: "Shellharbour (City)" },
  { suburb: "Albion Park Rail", postcode: 2527, lga: "Shellharbour (City)" },
  { suburb: "Shell Cove", postcode: 2529, lga: "Shellharbour (City)" },
  { suburb: "Barrack Point", postcode: 2528, lga: "Shellharbour (City)" },
  {
    suburb: "Shellharbour City Centre",
    postcode: 2529,
    lga: "Shellharbour (City)",
  },
  { suburb: "Dunmore", postcode: 2529, lga: "Shellharbour (City)" },
  { suburb: "North Macquarie", postcode: 2527, lga: "Shellharbour (City)" },
  { suburb: "Tullimbar", postcode: 2527, lga: "Shellharbour (City)" },
  { suburb: "Numbaa", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "South Nowra", postcode: 2541, lga: "Shoalhaven (City)" },
  { suburb: "Comberton", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Nowra", postcode: 2541, lga: "Shoalhaven (City)" },
  { suburb: "North Nowra", postcode: 2541, lga: "Shoalhaven (City)" },
  { suburb: "Watersleigh", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Mayfield", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Brundee", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Bolong", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Bomaderry", postcode: 2541, lga: "Shoalhaven (City)" },
  { suburb: "Longreach", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Terara", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Worrigee", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "West Nowra", postcode: 2541, lga: "Shoalhaven (City)" },
  { suburb: "Cambewarra", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Meroo Meadow", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Jaspers Brush", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "Nowra Hill", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Tapitallee", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Mundamia", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Bamarang", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Cambewarra Village", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Bangalee", postcode: 2541, lga: "Shoalhaven (City)" },
  { suburb: "Yerriyong", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Parma", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Beaumont", postcode: 2577, lga: "Shoalhaven (City)" },
  { suburb: "Pyree", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Burrill Lake", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Old Erowal Bay", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Callala Bay", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Ulladulla", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Currarong", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Durras North", postcode: 2536, lga: "Shoalhaven (City)" },
  { suburb: "Ettrema", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Tallowal", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Kangaroo Valley", postcode: 2577, lga: "Shoalhaven (City)" },
  { suburb: "Barrengarry", postcode: 2577, lga: "Shoalhaven (City)" },
  { suburb: "Sussex Inlet", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Budgong", postcode: 2577, lga: "Shoalhaven (City)" },
  { suburb: "Beecroft Peninsula", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Mollymook Beach", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Orient Point", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Lake Conjola", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Vincentia", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Milton", postcode: 2538, lga: "Shoalhaven (City)" },
  { suburb: "Cunjurong Point", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Callala Beach", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Sanctuary Point", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Falls Creek", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Huskisson", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Bendalong", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Woollamia", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Culburra Beach", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Mollymook", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Shoalhaven Heads", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "St Georges Basin", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Greenwell Point", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Berry", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "Cudmirrah", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Manyana", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Lake Tabourie", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Basin View", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Kings Point", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Woodstock", postcode: 2538, lga: "Shoalhaven (City)" },
  { suburb: "Berrara", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Tullarwalla", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Wollumboola", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Hyams Beach", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Bellawongarah", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "Narrawallee", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Tomerong", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Kioloa", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Bawley Point", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Worrowing Heights", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Erowal Bay", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Wandandian", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Kinghorne", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Broughton Vale", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "Far Meadow", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "Coolangatta", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "Woodburn", postcode: 2538, lga: "Shoalhaven (City)" },
  { suburb: "Jerrawangala", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Wrights Beach", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Mogood", postcode: 2538, lga: "Shoalhaven (City)" },
  { suburb: "Fishermans Paradise", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Touga", postcode: 2622, lga: "Shoalhaven (City)" },
  { suburb: "Conjola Park", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Sassafras", postcode: 2622, lga: "Shoalhaven (City)" },
  { suburb: "Broughton", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "St George", postcode: 2622, lga: "Shoalhaven (City)" },
  { suburb: "Pretty Beach", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Morton", postcode: 2538, lga: "Shoalhaven (City)" },
  { suburb: "Yadboro", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Termeil", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Brooman", postcode: 2538, lga: "Shoalhaven (City)" },
  { suburb: "Yatte Yattah", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Comerong Island", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Conjola", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Croobyar", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Little Forest", postcode: 2538, lga: "Shoalhaven (City)" },
  { suburb: "Pointer Mountain", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Red Rocks", postcode: 2577, lga: "Shoalhaven (City)" },
  { suburb: "Endrick", postcode: 2622, lga: "Shoalhaven (City)" },
  { suburb: "Depot Beach", postcode: 2536, lga: "Shoalhaven (City)" },
  { suburb: "Pebbly Beach", postcode: 2536, lga: "Shoalhaven (City)" },
  { suburb: "Dolphin Point", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Swanhaven", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Tolwong", postcode: 2622, lga: "Shoalhaven (City)" },
  { suburb: "Coolumburra", postcode: 2622, lga: "Shoalhaven (City)" },
  { suburb: "Bulee", postcode: 2622, lga: "Shoalhaven (City)" },
  { suburb: "Bundewallah", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "Back Forest", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "Bream Beach", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Brogers Creek", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "Myola", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Bewong", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Quiera", postcode: 2622, lga: "Shoalhaven (City)" },
  { suburb: "Wattamolla", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "Berry Mountain", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "Browns Mountain", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Berringer Lake", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Yalwal", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Porters Creek", postcode: 2538, lga: "Shoalhaven (City)" },
  { suburb: "Mount Kingiman", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Boolijah", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Cockwhy", postcode: 2539, lga: "Shoalhaven (City)" },
  { suburb: "Twelve Mile Peg", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Tianjara", postcode: 2622, lga: "Shoalhaven (City)" },
  { suburb: "Buangla", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Barringella", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Burrier", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "St Clair", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Mount Olive", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Falbrook", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Singleton Heights", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Singleton", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Dyrring", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Sedgefield", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Mitchells Flat", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Garland Valley", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Howes Valley", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Doyles Creek", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Dural", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Appletree Flat", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Milbrodale", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Bulga", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Broke", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Fordwich", postcode: 2330, lga: "Singleton (Area)" },
  {
    suburb: "Singleton Military Area",
    postcode: 2331,
    lga: "Singleton (Area)",
  },
  { suburb: "Belford", postcode: 2335, lga: "Singleton (Area)" },
  { suburb: "Howick", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Liddell", postcode: 2333, lga: "Singleton (Area)" },
  { suburb: "Ravensworth", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Mount Royal", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Goorangoola", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Mirannie", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Dalwood", postcode: 2335, lga: "Singleton (Area)" },
  { suburb: "Stanhope", postcode: 2335, lga: "Singleton (Area)" },
  { suburb: "Carrowbrook", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Greenlands", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Glennies Creek", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Rixs Creek", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Obanvale", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Camberwell", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Gouldsville", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Long Point", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Lemington", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Reedy Creek", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Westbrook", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Bridgman", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Glendon Brook", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Leconfield", postcode: 2335, lga: "Singleton (Area)" },
  { suburb: "Elderslie", postcode: 2335, lga: "Singleton (Area)" },
  { suburb: "Clydesdale", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Big Ridge", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Roughit", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Scotts Flat", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Glendon", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Maison Dieu", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Gowrie", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Glenridding", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Hambledon Hill", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Wylies Flat", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Mount Thorley", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Lower Belford", postcode: 2335, lga: "Singleton (Area)" },
  { suburb: "Whittingham", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Redbournberry", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Dunolly", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Darlington", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "McDougalls Hill", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Hunterview", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Fern Gully", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Wattle Ponds", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Middle Falbrook", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Jindabyne", postcode: 2627, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Beloka", postcode: 2628, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Braemar Bay",
    postcode: 2628,
    lga: "Snowy Monaro Regional (Area)",
  },
  {
    suburb: "Buckenderra",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Frying Pan", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Numbla Vale",
    postcode: 2628,
    lga: "Snowy Monaro Regional (Area)",
  },
  {
    suburb: "Cootralantra",
    postcode: 2628,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Berridale", postcode: 2628, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Rhine Falls",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Arable", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Eucumbene", postcode: 2628, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Rocky Plain",
    postcode: 2628,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Adaminaby", postcode: 2629, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Bobundara", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Wambrook", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "The Brothers",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Myalla", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Paupong", postcode: 2628, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Ironmungy", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Pine Valley",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  {
    suburb: "Grosses Plain",
    postcode: 2627,
    lga: "Snowy Monaro Regional (Area)",
  },
  {
    suburb: "Snowy Plain",
    postcode: 2628,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Moonbah", postcode: 2627, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Nimmo", postcode: 2628, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Kosciuszko National Park",
    postcode: 2627,
    lga: "Snowy Monaro Regional (Area)",
  },
  {
    suburb: "Coolringdon",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Kalkite", postcode: 2627, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "East Jindabyne",
    postcode: 2627,
    lga: "Snowy Monaro Regional (Area)",
  },
  {
    suburb: "Middlingbank",
    postcode: 2630,
    lga: "Snowy Monaro Regional (Area)",
  },
  {
    suburb: "Old Adaminaby",
    postcode: 2629,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Dry Plain", postcode: 2630, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Ingebirah", postcode: 2627, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Dalgety", postcode: 2628, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Anglers Reach",
    postcode: 2629,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Avonside", postcode: 2628, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Jimenbuen", postcode: 2628, lga: "Snowy Monaro Regional (Area)" },
  {
    suburb: "Crackenback",
    postcode: 2627,
    lga: "Snowy Monaro Regional (Area)",
  },
  { suburb: "Homebush West", postcode: 2140, lga: "Strathfield (Area)" },
  { suburb: "Strathfield South", postcode: 2136, lga: "Strathfield (Area)" },
  { suburb: "Homebush", postcode: 2140, lga: "Strathfield (Area)" },
  { suburb: "Sylvania Waters", postcode: 2224, lga: "Sutherland Shire (Area)" },
  { suburb: "Caringbah South", postcode: 2229, lga: "Sutherland Shire (Area)" },
  { suburb: "Burraneer", postcode: 2230, lga: "Sutherland Shire (Area)" },
  { suburb: "Cronulla", postcode: 2230, lga: "Sutherland Shire (Area)" },
  { suburb: "Taren Point", postcode: 2229, lga: "Sutherland Shire (Area)" },
  { suburb: "Caringbah", postcode: 2229, lga: "Sutherland Shire (Area)" },
  { suburb: "Lilli Pilli", postcode: 2229, lga: "Sutherland Shire (Area)" },
  { suburb: "Dolans Bay", postcode: 2229, lga: "Sutherland Shire (Area)" },
  { suburb: "Kurnell", postcode: 2231, lga: "Sutherland Shire (Area)" },
  { suburb: "Gymea Bay", postcode: 2227, lga: "Sutherland Shire (Area)" },
  { suburb: "Gymea", postcode: 2227, lga: "Sutherland Shire (Area)" },
  { suburb: "Miranda", postcode: 2228, lga: "Sutherland Shire (Area)" },
  { suburb: "Woolooware", postcode: 2230, lga: "Sutherland Shire (Area)" },
  { suburb: "Port Hacking", postcode: 2229, lga: "Sutherland Shire (Area)" },
  { suburb: "Sylvania", postcode: 2224, lga: "Sutherland Shire (Area)" },
  { suburb: "Yowie Bay", postcode: 2228, lga: "Sutherland Shire (Area)" },
  { suburb: "Maianbar", postcode: 2230, lga: "Sutherland Shire (Area)" },
  { suburb: "Kirrawee", postcode: 2232, lga: "Sutherland Shire (Area)" },
  {
    suburb: "Royal National Park",
    postcode: 2233,
    lga: "Sutherland Shire (Area)",
  },
  { suburb: "Bundeena", postcode: 2230, lga: "Sutherland Shire (Area)" },
  { suburb: "Kangaroo Point", postcode: 2224, lga: "Sutherland Shire (Area)" },
  { suburb: "Kareela", postcode: 2232, lga: "Sutherland Shire (Area)" },
  {
    suburb: "Greenhills Beach",
    postcode: 2230,
    lga: "Sutherland Shire (Area)",
  },
  { suburb: "Grays Point", postcode: 2232, lga: "Sutherland Shire (Area)" },
  { suburb: "Bonnet Bay", postcode: 2226, lga: "Sutherland Shire (Area)" },
  { suburb: "Woronora", postcode: 2232, lga: "Sutherland Shire (Area)" },
  { suburb: "Sutherland", postcode: 2232, lga: "Sutherland Shire (Area)" },
  { suburb: "Jannali", postcode: 2226, lga: "Sutherland Shire (Area)" },
  { suburb: "Loftus", postcode: 2232, lga: "Sutherland Shire (Area)" },
  { suburb: "Como", postcode: 2226, lga: "Sutherland Shire (Area)" },
  { suburb: "Bangor", postcode: 2234, lga: "Sutherland Shire (Area)" },
  { suburb: "Yarrawarrah", postcode: 2233, lga: "Sutherland Shire (Area)" },
  { suburb: "Illawong", postcode: 2234, lga: "Sutherland Shire (Area)" },
  { suburb: "Heathcote", postcode: 2233, lga: "Sutherland Shire (Area)" },
  { suburb: "Menai", postcode: 2234, lga: "Sutherland Shire (Area)" },
  { suburb: "Engadine", postcode: 2233, lga: "Sutherland Shire (Area)" },
  {
    suburb: "Woronora Heights",
    postcode: 2233,
    lga: "Sutherland Shire (Area)",
  },
  { suburb: "Waterfall", postcode: 2233, lga: "Sutherland Shire (Area)" },
  { suburb: "Sandy Point", postcode: 2172, lga: "Sutherland Shire (Area)" },
  { suburb: "Barden Ridge", postcode: 2234, lga: "Sutherland Shire (Area)" },
  { suburb: "Oyster Bay", postcode: 2225, lga: "Sutherland Shire (Area)" },
  { suburb: "Lucas Heights", postcode: 2234, lga: "Sutherland Shire (Area)" },
  { suburb: "Alfords Point", postcode: 2234, lga: "Sutherland Shire (Area)" },
  { suburb: "Sydney", postcode: 2000, lga: "Sydney (City)" },
  { suburb: "Haymarket", postcode: 2000, lga: "Sydney (City)" },
  { suburb: "Darlinghurst", postcode: 2010, lga: "Sydney (City)" },
  { suburb: "Dawes Point", postcode: 2000, lga: "Sydney (City)" },
  { suburb: "Millers Point", postcode: 2000, lga: "Sydney (City)" },
  { suburb: "The Rocks", postcode: 2000, lga: "Sydney (City)" },
  { suburb: "Potts Point", postcode: 2011, lga: "Sydney (City)" },
  { suburb: "Surry Hills", postcode: 2010, lga: "Sydney (City)" },
  { suburb: "Woolloomooloo", postcode: 2011, lga: "Sydney (City)" },
  { suburb: "Rushcutters Bay", postcode: 2011, lga: "Sydney (City)" },
  { suburb: "Elizabeth Bay", postcode: 2011, lga: "Sydney (City)" },
  { suburb: "Moore Park", postcode: 2021, lga: "Sydney (City)" },
  { suburb: "Paddington", postcode: 2021, lga: "Sydney (City)" },
  { suburb: "Darlington", postcode: 2008, lga: "Sydney (City)" },
  { suburb: "Redfern", postcode: 2016, lga: "Sydney (City)" },
  { suburb: "Waterloo", postcode: 2017, lga: "Sydney (City)" },
  { suburb: "Erskineville", postcode: 2043, lga: "Sydney (City)" },
  { suburb: "Zetland", postcode: 2017, lga: "Sydney (City)" },
  { suburb: "Alexandria", postcode: 2015, lga: "Sydney (City)" },
  { suburb: "Beaconsfield", postcode: 2015, lga: "Sydney (City)" },
  { suburb: "Eveleigh", postcode: 2015, lga: "Sydney (City)" },
  { suburb: "Glebe", postcode: 2037, lga: "Sydney (City)" },
  { suburb: "Ultimo", postcode: 2007, lga: "Sydney (City)" },
  { suburb: "Chippendale", postcode: 2008, lga: "Sydney (City)" },
  { suburb: "Forest Lodge", postcode: 2037, lga: "Sydney (City)" },
  { suburb: "Pyrmont", postcode: 2009, lga: "Sydney (City)" },
  { suburb: "West Tamworth", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Hillvue", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Oxley Vale", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "South Tamworth", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "North Tamworth", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Tamworth", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "East Tamworth", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Calala", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Kootingal", postcode: 2352, lga: "Tamworth Regional (Area)" },
  { suburb: "Moonbi", postcode: 2353, lga: "Tamworth Regional (Area)" },
  { suburb: "Nemingha", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Moore Creek", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Tintinhull", postcode: 2352, lga: "Tamworth Regional (Area)" },
  { suburb: "Taminda", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Westdale", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Kingswood", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Warral", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Daruka", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Attunga", postcode: 2345, lga: "Tamworth Regional (Area)" },
  { suburb: "Hallsville", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "New Mexico", postcode: 2346, lga: "Tamworth Regional (Area)" },
  { suburb: "Manilla", postcode: 2346, lga: "Tamworth Regional (Area)" },
  { suburb: "Timbumburi", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Garoo", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Watsons Creek", postcode: 2355, lga: "Tamworth Regional (Area)" },
  { suburb: "Thirldene", postcode: 2347, lga: "Tamworth Regional (Area)" },
  { suburb: "Ogunbil", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Wongo Creek", postcode: 2346, lga: "Tamworth Regional (Area)" },
  { suburb: "Mulla Creek", postcode: 2352, lga: "Tamworth Regional (Area)" },
  { suburb: "Mayvale", postcode: 2347, lga: "Tamworth Regional (Area)" },
  { suburb: "Upper Manilla", postcode: 2346, lga: "Tamworth Regional (Area)" },
  { suburb: "Woodsreef", postcode: 2347, lga: "Tamworth Regional (Area)" },
  { suburb: "Warrabah", postcode: 2346, lga: "Tamworth Regional (Area)" },
  { suburb: "Retreat", postcode: 2355, lga: "Tamworth Regional (Area)" },
  { suburb: "Bendemeer", postcode: 2355, lga: "Tamworth Regional (Area)" },
  { suburb: "Limbri", postcode: 2352, lga: "Tamworth Regional (Area)" },
  { suburb: "Garthowen", postcode: 2345, lga: "Tamworth Regional (Area)" },
  { suburb: "Namoi River", postcode: 2346, lga: "Tamworth Regional (Area)" },
  { suburb: "Wimborne", postcode: 2346, lga: "Tamworth Regional (Area)" },
  { suburb: "Halls Creek", postcode: 2346, lga: "Tamworth Regional (Area)" },
  {
    suburb: "Bowling Alley Point",
    postcode: 2340,
    lga: "Tamworth Regional (Area)",
  },
  { suburb: "Klori", postcode: 2346, lga: "Tamworth Regional (Area)" },
  { suburb: "Rushes Creek", postcode: 2346, lga: "Tamworth Regional (Area)" },
  { suburb: "Somerton", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Bective", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Wallamore", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Gidley", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Winton", postcode: 2344, lga: "Tamworth Regional (Area)" },
  { suburb: "Duri", postcode: 2344, lga: "Tamworth Regional (Area)" },
  { suburb: "Goonoo Goonoo", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Loomberah", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Hanging Rock", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Woolomin", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Crawney", postcode: 2338, lga: "Tamworth Regional (Area)" },
  { suburb: "Ironbark", postcode: 2347, lga: "Tamworth Regional (Area)" },
  { suburb: "Banoon", postcode: 2347, lga: "Tamworth Regional (Area)" },
  { suburb: "Red Hill", postcode: 2347, lga: "Tamworth Regional (Area)" },
  { suburb: "Longarm", postcode: 2347, lga: "Tamworth Regional (Area)" },
  { suburb: "Barry", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Duncans Creek", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Niangala", postcode: 2354, lga: "Tamworth Regional (Area)" },
  { suburb: "Woolbrook", postcode: 2354, lga: "Tamworth Regional (Area)" },
  { suburb: "Kentucky", postcode: 2354, lga: "Tamworth Regional (Area)" },
  { suburb: "Bithramere", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Temora", postcode: 2666, lga: "Temora (Area)" },
  { suburb: "Combaning", postcode: 2666, lga: "Temora (Area)" },
  { suburb: "Tara", postcode: 2665, lga: "Temora (Area)" },
  { suburb: "Pucawan", postcode: 2666, lga: "Temora (Area)" },
  { suburb: "Mimosa", postcode: 2666, lga: "Temora (Area)" },
  { suburb: "Reefton", postcode: 2666, lga: "Temora (Area)" },
  { suburb: "Springdale", postcode: 2666, lga: "Temora (Area)" },
  { suburb: "Narraburra", postcode: 2666, lga: "Temora (Area)" },
  { suburb: "Quandary", postcode: 2665, lga: "Temora (Area)" },
  { suburb: "Sebastopol", postcode: 2666, lga: "Temora (Area)" },
  { suburb: "Grogan", postcode: 2666, lga: "Temora (Area)" },
  { suburb: "Trungley Hall", postcode: 2666, lga: "Temora (Area)" },
  { suburb: "Gidginbung", postcode: 2666, lga: "Temora (Area)" },
  { suburb: "Tenterfield", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Maryland", postcode: 4377, lga: "Tenterfield (Area)" },
  { suburb: "Legume", postcode: 2476, lga: "Tenterfield (Area)" },
  { suburb: "Drake", postcode: 2469, lga: "Tenterfield (Area)" },
  { suburb: "Back Creek", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Tarban", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Bookookoorara", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Upper Tooloom", postcode: 2475, lga: "Tenterfield (Area)" },
  { suburb: "Koreelah", postcode: 2476, lga: "Tenterfield (Area)" },
  { suburb: "Cottonvale", postcode: 4375, lga: "Tenterfield (Area)" },
  { suburb: "Acacia Creek", postcode: 2476, lga: "Tenterfield (Area)" },
  { suburb: "Cullendore", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Lower Acacia Creek", postcode: 2476, lga: "Tenterfield (Area)" },
  { suburb: "Wylie Creek", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Liston", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Ruby Creek", postcode: 4380, lga: "Tenterfield (Area)" },
  { suburb: "Amosfield", postcode: 4380, lga: "Tenterfield (Area)" },
  { suburb: "Boonoo Boonoo", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Undercliffe", postcode: 4380, lga: "Tenterfield (Area)" },
  { suburb: "Boorook", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Sandy Hill", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Rocky River", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Timbarra", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Rivertree", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Woodside", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Jennings", postcode: 4383, lga: "Tenterfield (Area)" },
  { suburb: "Dumaresq Valley", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Forest Land", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Sandy Flat", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Mingoola", postcode: 4380, lga: "Tenterfield (Area)" },
  { suburb: "Mole River", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Bolivia", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Silent Grove", postcode: 2372, lga: "Tenterfield (Area)" },
  { suburb: "Buddong", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Willigobung", postcode: 2653, lga: "Snowy Valleys (Area)" },
  { suburb: "Tumbarumba", postcode: 2653, lga: "Snowy Valleys (Area)" },
  { suburb: "Green Hills", postcode: 2730, lga: "Snowy Valleys (Area)" },
  { suburb: "Glenroy", postcode: 2653, lga: "Snowy Valleys (Area)" },
  { suburb: "Geehi", postcode: 2642, lga: "Snowy Valleys (Area)" },
  { suburb: "Bringenbrong", postcode: 3707, lga: "Snowy Valleys (Area)" },
  { suburb: "Pilot Wilderness", postcode: 2627, lga: "Snowy Valleys (Area)" },
  {
    suburb: "Jagungal Wilderness",
    postcode: 2642,
    lga: "Snowy Valleys (Area)",
  },
  { suburb: "Cabramurra", postcode: 2629, lga: "Snowy Valleys (Area)" },
  { suburb: "Jagumba", postcode: 2642, lga: "Snowy Valleys (Area)" },
  { suburb: "Nurenmerenmong", postcode: 2649, lga: "Snowy Valleys (Area)" },
  { suburb: "Burra", postcode: 2653, lga: "Snowy Valleys (Area)" },
  { suburb: "Greg Greg", postcode: 2642, lga: "Snowy Valleys (Area)" },
  { suburb: "Khancoban", postcode: 2642, lga: "Snowy Valleys (Area)" },
  { suburb: "Indi", postcode: 2642, lga: "Snowy Valleys (Area)" },
  { suburb: "Tooma", postcode: 2642, lga: "Snowy Valleys (Area)" },
  { suburb: "Ournie", postcode: 2640, lga: "Snowy Valleys (Area)" },
  { suburb: "Munderoo", postcode: 2653, lga: "Snowy Valleys (Area)" },
  { suburb: "Paddys River", postcode: 2653, lga: "Snowy Valleys (Area)" },
  { suburb: "Courabyra", postcode: 2653, lga: "Snowy Valleys (Area)" },
  { suburb: "Maragle", postcode: 2653, lga: "Snowy Valleys (Area)" },
  { suburb: "Laurel Hill", postcode: 2649, lga: "Snowy Valleys (Area)" },
  { suburb: "Westdale", postcode: 2653, lga: "Snowy Valleys (Area)" },
  { suburb: "Taradale", postcode: 2653, lga: "Snowy Valleys (Area)" },
  { suburb: "Mannus", postcode: 2653, lga: "Snowy Valleys (Area)" },
  { suburb: "Gilmore", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Tumut", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Lacmalac", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Tumorrama", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Califat", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Wereboldera", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Minjary", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Batlow", postcode: 2730, lga: "Snowy Valleys (Area)" },
  { suburb: "Gocup", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Goobarragandra", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Blowering", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Tumut Plains", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Little River", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Jones Bridge", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Sandy Gully", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Grahamstown", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Black Creek", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Mount Adrah", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Kunama", postcode: 2730, lga: "Snowy Valleys (Area)" },
  { suburb: "Long Plain", postcode: 2629, lga: "Snowy Valleys (Area)" },
  { suburb: "Cooleman", postcode: 2611, lga: "Snowy Valleys (Area)" },
  { suburb: "Sharps Creek", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Gadara", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Wondalga", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Talbingo", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Couragago", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Yarrangobilly", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Brindabella", postcode: 2611, lga: "Snowy Valleys (Area)" },
  { suburb: "Uriarra", postcode: 2611, lga: "Snowy Valleys (Area)" },
  { suburb: "Adelong", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Mount Horeb", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Killimicat", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Red Hill", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Westwood", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Darlow", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Lower Bago", postcode: 2730, lga: "Snowy Valleys (Area)" },
  { suburb: "Wermatong", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Pinbeyan", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Bangadang", postcode: 2729, lga: "Snowy Valleys (Area)" },
  {
    suburb: "Bogong Peaks Wilderness",
    postcode: 2720,
    lga: "Snowy Valleys (Area)",
  },
  { suburb: "Wyangle", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Argalong", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Mundongo", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Cooleys Creek", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Brungle", postcode: 2722, lga: "Snowy Valleys (Area)" },
  { suburb: "Bombowlee Creek", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Carool", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "Bilambil Heights", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "Terranora", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "Tweed Heads West", postcode: 2485, lga: "Tweed (Area)" },
  { suburb: "Banora Point", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "Tweed Heads South", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "Tweed Heads", postcode: 2485, lga: "Tweed (Area)" },
  { suburb: "Cudgen", postcode: 2487, lga: "Tweed (Area)" },
  { suburb: "Fingal Head", postcode: 2487, lga: "Tweed (Area)" },
  { suburb: "Kingscliff", postcode: 2487, lga: "Tweed (Area)" },
  { suburb: "Chinderah", postcode: 2487, lga: "Tweed (Area)" },
  { suburb: "Piggabeen", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "Bilambil", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "Casuarina", postcode: 2487, lga: "Tweed (Area)" },
  { suburb: "Cobaki", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "North Tumbulgum", postcode: 2490, lga: "Tweed (Area)" },
  { suburb: "Cobaki Lakes", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "Sleepy Hollow", postcode: 2483, lga: "Tweed (Area)" },
  { suburb: "Cudgera Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Pottsville", postcode: 2489, lga: "Tweed (Area)" },
  { suburb: "Bogangar", postcode: 2488, lga: "Tweed (Area)" },
  { suburb: "Clothiers Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Hastings Point", postcode: 2489, lga: "Tweed (Area)" },
  { suburb: "Crabbes Creek", postcode: 2483, lga: "Tweed (Area)" },
  { suburb: "Kings Forest", postcode: 2487, lga: "Tweed (Area)" },
  { suburb: "Tanglewood", postcode: 2488, lga: "Tweed (Area)" },
  { suburb: "Cabarita Beach", postcode: 2488, lga: "Tweed (Area)" },
  { suburb: "Round Mountain", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Duranbah", postcode: 2487, lga: "Tweed (Area)" },
  { suburb: "Mooball", postcode: 2483, lga: "Tweed (Area)" },
  { suburb: "Chillingham", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Murwillumbah", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "South Murwillumbah", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Upper Duroby", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "Wardrop Valley", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Duroby", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "Tumbulgum", postcode: 2490, lga: "Tweed (Area)" },
  { suburb: "Terragon", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Doon Doon", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Rowlands Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Bungalora", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "Byangum", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Tomewin", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Chowan Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Smiths Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Palmvale", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Back Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Mebbin", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Mount Warning", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Hopkins Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Kunghur", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Cedar Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Zara", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Commissioners Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Glengarrie", postcode: 2486, lga: "Tweed (Area)" },
  { suburb: "Stokers Siding", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Numinbah", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Stotts Creek", postcode: 2487, lga: "Tweed (Area)" },
  { suburb: "Kunghur Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Uki", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Condong", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Bray Park", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Kynnumboon", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Tyalgum", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Pumpenbil", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Limpinwood", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Nobbys Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Burringbar", postcode: 2483, lga: "Tweed (Area)" },
  { suburb: "Kielvale", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Tygalgah", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Fernvale", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Nunderi", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Farrants Hill", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Dunbible", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Urliup", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Eviron", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Tyalgum Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Mount Burrell", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Upper Crystal Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Dungay", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Dulguigan", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "North Arm", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Brays Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Dum Dum", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Reserve Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Eungella", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Crystal Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Rouchel", postcode: 2336, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Cassilis", postcode: 2329, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Brawboy", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Scone", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Aberdeen", postcode: 2336, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Sandy Creek", postcode: 2338, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Scotts Creek", postcode: 2338, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Upper Rouchel", postcode: 2336, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Rossgole", postcode: 2336, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Gungal", postcode: 2333, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Merriwa", postcode: 2329, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Glenbawn", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Wingen", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Waverly", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Dry Creek", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Pages Creek", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  {
    suburb: "Upper Dartbrook",
    postcode: 2336,
    lga: "Upper Hunter Shire (Area)",
  },
  { suburb: "Glenrock", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Omadale", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Murrurundi", postcode: 2338, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Timor", postcode: 2338, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Green Creek", postcode: 2338, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Segenhoe", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Gundy", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Woolooma", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Belltrees", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Kars Springs", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Middle Brook", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Moonan Flat", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Dartbrook", postcode: 2336, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Parkville", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Bunnan", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Moobi", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Davis Creek", postcode: 2336, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Owens Gap", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Moonan Brook", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Pages River", postcode: 2338, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Murulla", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  {
    suburb: "Stewarts Brook",
    postcode: 2337,
    lga: "Upper Hunter Shire (Area)",
  },
  { suburb: "Limerick", postcode: 2583, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Crookwell", postcode: 2583, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Roslyn", postcode: 2580, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Gurrundah", postcode: 2581, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Lerida", postcode: 2581, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Taralga", postcode: 2580, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Bannister", postcode: 2580, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Wheeo", postcode: 2583, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Dalton", postcode: 2581, lga: "Upper Lachlan Shire (Area)" },
  {
    suburb: "Grabben Gullen",
    postcode: 2583,
    lga: "Upper Lachlan Shire (Area)",
  },
  { suburb: "Laggan", postcode: 2583, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Binda", postcode: 2583, lga: "Upper Lachlan Shire (Area)" },
  {
    suburb: "Wombeyan Caves",
    postcode: 2580,
    lga: "Upper Lachlan Shire (Area)",
  },
  { suburb: "Fullerton", postcode: 2583, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Tuena", postcode: 2583, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Bigga", postcode: 2583, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Peelwood", postcode: 2583, lga: "Upper Lachlan Shire (Area)" },
  {
    suburb: "Crooked Corner",
    postcode: 2583,
    lga: "Upper Lachlan Shire (Area)",
  },
  { suburb: "Golspie", postcode: 2580, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Yalbraith", postcode: 2580, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Curraweela", postcode: 2580, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Bannaby", postcode: 2580, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Stonequarry", postcode: 2580, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Richlands", postcode: 2580, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Myrtleville", postcode: 2580, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Lost River", postcode: 2583, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Narrawa", postcode: 2583, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Bevendale", postcode: 2581, lga: "Upper Lachlan Shire (Area)" },
  {
    suburb: "Blakney Creek",
    postcode: 2581,
    lga: "Upper Lachlan Shire (Area)",
  },
  { suburb: "Broadway", postcode: 2581, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Jerrawa", postcode: 2582, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Oolong", postcode: 2581, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Lade Vale", postcode: 2581, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Gunning", postcode: 2581, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Merrill", postcode: 2581, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Biala", postcode: 2581, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Cullerin", postcode: 2581, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Pejar", postcode: 2583, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Chatsbury", postcode: 2580, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Big Hill", postcode: 2579, lga: "Upper Lachlan Shire (Area)" },
  {
    suburb: "Bellmount Forest",
    postcode: 2581,
    lga: "Upper Lachlan Shire (Area)",
  },
  { suburb: "Uralla", postcode: 2358, lga: "Uralla (Area)" },
  { suburb: "Kentucky South", postcode: 2354, lga: "Uralla (Area)" },
  { suburb: "Wollun", postcode: 2354, lga: "Uralla (Area)" },
  { suburb: "Salisbury Plains", postcode: 2358, lga: "Uralla (Area)" },
  { suburb: "Torryburn", postcode: 2358, lga: "Uralla (Area)" },
  { suburb: "Camerons Creek", postcode: 2359, lga: "Uralla (Area)" },
  { suburb: "Abington", postcode: 2350, lga: "Uralla (Area)" },
  { suburb: "Kingstown", postcode: 2358, lga: "Uralla (Area)" },
  { suburb: "Yarrowyck", postcode: 2358, lga: "Uralla (Area)" },
  { suburb: "Saumarez Ponds", postcode: 2350, lga: "Uralla (Area)" },
  { suburb: "Invergowrie", postcode: 2350, lga: "Uralla (Area)" },
  { suburb: "Saumarez", postcode: 2350, lga: "Uralla (Area)" },
  { suburb: "Arding", postcode: 2358, lga: "Uralla (Area)" },
  { suburb: "Rocky River", postcode: 2358, lga: "Uralla (Area)" },
  { suburb: "Balala", postcode: 2358, lga: "Uralla (Area)" },
  { suburb: "Gostwyck", postcode: 2358, lga: "Uralla (Area)" },
  { suburb: "Mihi", postcode: 2358, lga: "Uralla (Area)" },
  { suburb: "Ashmont", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Glenfield Park", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Tolland", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Mount Austin", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Bourkelands", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Wagga Wagga", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Turvey Park", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Lake Albert", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Kooringal", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "North Wagga Wagga", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "East Wagga Wagga", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Forest Hill", postcode: 2651, lga: "Wagga Wagga (City)" },
  { suburb: "Gregadoo", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Uranquinty", postcode: 2652, lga: "Wagga Wagga (City)" },
  { suburb: "Lloyd", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Estella", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Yarragundry", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Moorong", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Tatton", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Gumly Gumly", postcode: 2652, lga: "Wagga Wagga (City)" },
  { suburb: "Kapooka", postcode: 2661, lga: "Wagga Wagga (City)" },
  { suburb: "Springvale", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "San Isidore", postcode: 2650, lga: "Wagga Wagga (City)" },
  {
    suburb: "Charles Sturt University",
    postcode: 2678,
    lga: "Wagga Wagga (City)",
  },
  { suburb: "Cartwrights Hill", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Bomen", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Rowan", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Collingullie", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Belfrayden", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Gelston Park", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Currawarna", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Maxwell", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Big Springs", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Pulletop", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Alfredtown", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Wallacetown", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Ladysmith", postcode: 2652, lga: "Wagga Wagga (City)" },
  { suburb: "Mangoplah", postcode: 2652, lga: "Wagga Wagga (City)" },
  { suburb: "Bulgary", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "The Gap", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Brucedale", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Borambola", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Tarcutta", postcode: 2652, lga: "Wagga Wagga (City)" },
  { suburb: "Dhulura", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Kyeamba", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Oura", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Euberta", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Brookdale", postcode: 2656, lga: "Wagga Wagga (City)" },
  { suburb: "Eunanoreenya", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Downside", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Gobbagombalin", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Hillgrove", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Burrandana", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Tooleybuc", postcode: 2736, lga: "Murray River (Area)" },
  { suburb: "Moolpa", postcode: 2734, lga: "Murray River (Area)" },
  { suburb: "Mellool", postcode: 2734, lga: "Murray River (Area)" },
  { suburb: "Waugorah", postcode: 2711, lga: "Murray River (Area)" },
  { suburb: "Wakool", postcode: 2710, lga: "Murray River (Area)" },
  { suburb: "Stony Crossing", postcode: 2734, lga: "Murray River (Area)" },
  { suburb: "Yanga", postcode: 2711, lga: "Murray River (Area)" },
  { suburb: "Mallan", postcode: 2734, lga: "Murray River (Area)" },
  { suburb: "Barham", postcode: 2732, lga: "Murray River (Area)" },
  { suburb: "Koraleigh", postcode: 2735, lga: "Murray River (Area)" },
  { suburb: "Gonn", postcode: 2732, lga: "Murray River (Area)" },
  { suburb: "Tullakool", postcode: 2732, lga: "Murray River (Area)" },
  { suburb: "Goodnight", postcode: 2736, lga: "Murray River (Area)" },
  { suburb: "Keri Keri", postcode: 2711, lga: "Murray River (Area)" },
  { suburb: "Murray Downs", postcode: 2734, lga: "Murray River (Area)" },
  { suburb: "Speewa", postcode: 2735, lga: "Murray River (Area)" },
  { suburb: "Cunninyeuk", postcode: 2734, lga: "Murray River (Area)" },
  { suburb: "Noorong", postcode: 2732, lga: "Murray River (Area)" },
  { suburb: "Dhuragoon", postcode: 2733, lga: "Murray River (Area)" },
  { suburb: "Dilpurra", postcode: 2734, lga: "Murray River (Area)" },
  { suburb: "Cobramunga", postcode: 2732, lga: "Murray River (Area)" },
  { suburb: "Burraboi", postcode: 2732, lga: "Murray River (Area)" },
  { suburb: "Wetuppa", postcode: 2734, lga: "Murray River (Area)" },
  { suburb: "Tooranie", postcode: 2734, lga: "Murray River (Area)" },
  { suburb: "Niemur", postcode: 2733, lga: "Murray River (Area)" },
  { suburb: "Walcha", postcode: 2354, lga: "Walcha (Area)" },
  { suburb: "Yarrowitch", postcode: 2354, lga: "Walcha (Area)" },
  { suburb: "Nowendoc", postcode: 2354, lga: "Walcha (Area)" },
  { suburb: "Lightning Ridge", postcode: 2834, lga: "Walgett (Area)" },
  { suburb: "Burren Junction", postcode: 2386, lga: "Walgett (Area)" },
  { suburb: "Cumborah", postcode: 2832, lga: "Walgett (Area)" },
  { suburb: "Come By Chance", postcode: 2832, lga: "Walgett (Area)" },
  { suburb: "Walgett", postcode: 2832, lga: "Walgett (Area)" },
  { suburb: "Cryon", postcode: 2832, lga: "Walgett (Area)" },
  { suburb: "Warren", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Ravenswood", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Tenandra", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Bogan", postcode: 2826, lga: "Warren (Area)" },
  { suburb: "Oxley", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Nevertire", postcode: 2826, lga: "Warren (Area)" },
  { suburb: "Beemunnel", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Mount Harris", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Mount Foster", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Bullagreen", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Pine Clump", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Pigeonbah", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Marthaguy", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Eenaweena", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Mumblebone Plain", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Mullengudgery", postcode: 2825, lga: "Warren (Area)" },
  { suburb: "Red Hill", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Snakes Plain", postcode: 2824, lga: "Warren (Area)" },
  { suburb: "Cathundral", postcode: 2823, lga: "Warren (Area)" },
  { suburb: "Belrose", postcode: 2085, lga: "Northern Beaches (Area)" },
  {
    suburb: "Collaroy Plateau",
    postcode: 2097,
    lga: "Northern Beaches (Area)",
  },
  { suburb: "North Manly", postcode: 2100, lga: "Northern Beaches (Area)" },
  { suburb: "Narraweena", postcode: 2099, lga: "Northern Beaches (Area)" },
  { suburb: "Narrabeen", postcode: 2101, lga: "Northern Beaches (Area)" },
  { suburb: "Cromer", postcode: 2099, lga: "Northern Beaches (Area)" },
  { suburb: "Beacon Hill", postcode: 2100, lga: "Northern Beaches (Area)" },
  { suburb: "Curl Curl", postcode: 2096, lga: "Northern Beaches (Area)" },
  { suburb: "Brookvale", postcode: 2100, lga: "Northern Beaches (Area)" },
  { suburb: "North Curl Curl", postcode: 2099, lga: "Northern Beaches (Area)" },
  { suburb: "Dee Why", postcode: 2099, lga: "Northern Beaches (Area)" },
  { suburb: "Frenchs Forest", postcode: 2086, lga: "Northern Beaches (Area)" },
  { suburb: "Freshwater", postcode: 2096, lga: "Northern Beaches (Area)" },
  { suburb: "Collaroy", postcode: 2097, lga: "Northern Beaches (Area)" },
  { suburb: "Wheeler Heights", postcode: 2097, lga: "Northern Beaches (Area)" },
  { suburb: "Manly Vale", postcode: 2093, lga: "Northern Beaches (Area)" },
  { suburb: "Oxford Falls", postcode: 2100, lga: "Northern Beaches (Area)" },
  {
    suburb: "Killarney Heights",
    postcode: 2087,
    lga: "Northern Beaches (Area)",
  },
  { suburb: "Terrey Hills", postcode: 2084, lga: "Northern Beaches (Area)" },
  { suburb: "Davidson", postcode: 2085, lga: "Northern Beaches (Area)" },
  { suburb: "Forestville", postcode: 2087, lga: "Northern Beaches (Area)" },
  {
    suburb: "Allambie Heights",
    postcode: 2100,
    lga: "Northern Beaches (Area)",
  },
  { suburb: "North Balgowlah", postcode: 2093, lga: "Northern Beaches (Area)" },
  { suburb: "Cottage Point", postcode: 2084, lga: "Northern Beaches (Area)" },
  { suburb: "Queenscliff", postcode: 2096, lga: "Northern Beaches (Area)" },
  { suburb: "Duffys Forest", postcode: 2084, lga: "Northern Beaches (Area)" },
  { suburb: "Coonabarabran", postcode: 2357, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Dunedoo", postcode: 2844, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Ropers Road", postcode: 2395, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Weetaliba", postcode: 2395, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Coolah", postcode: 2843, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Bomera", postcode: 2357, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Tannabar", postcode: 2357, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Kenebri", postcode: 2396, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Baradine", postcode: 2396, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Bugaldie", postcode: 2357, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Goorianawa", postcode: 2396, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Dandry", postcode: 2357, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Rocky Glen", postcode: 2357, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Binnaway", postcode: 2395, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Merrygoen", postcode: 2831, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Uarbry", postcode: 2329, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Leadville", postcode: 2844, lga: "Warrumbungle Shire (Area)" },
  {
    suburb: "Wattle Springs",
    postcode: 2357,
    lga: "Warrumbungle Shire (Area)",
  },
  { suburb: "Gowang", postcode: 2357, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Mollyan", postcode: 2842, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Neilrex", postcode: 2831, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Yarragrin", postcode: 2842, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Box Ridge", postcode: 2357, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Barwon", postcode: 2396, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Nombi", postcode: 2379, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Napier Lane", postcode: 2379, lga: "Warrumbungle Shire (Area)" },
  { suburb: "Purlewaugh", postcode: 2357, lga: "Warrumbungle Shire (Area)" },
  { suburb: "North Bondi", postcode: 2026, lga: "Waverley (Area)" },
  { suburb: "Vaucluse", postcode: 2030, lga: "Waverley (Area)" },
  { suburb: "Waverley", postcode: 2024, lga: "Waverley (Area)" },
  { suburb: "Rose Bay", postcode: 2029, lga: "Waverley (Area)" },
  { suburb: "Bondi Junction", postcode: 2022, lga: "Waverley (Area)" },
  { suburb: "Bondi Beach", postcode: 2026, lga: "Waverley (Area)" },
  { suburb: "Dover Heights", postcode: 2030, lga: "Waverley (Area)" },
  { suburb: "Tamarama", postcode: 2026, lga: "Waverley (Area)" },
  { suburb: "Bronte", postcode: 2024, lga: "Waverley (Area)" },
  { suburb: "Bondi", postcode: 2026, lga: "Waverley (Area)" },
  { suburb: "Queens Park", postcode: 2022, lga: "Waverley (Area)" },
  { suburb: "Glenelg", postcode: 2810, lga: "Weddin (Area)" },
  { suburb: "Grenfell", postcode: 2810, lga: "Weddin (Area)" },
  { suburb: "Caragabal", postcode: 2810, lga: "Weddin (Area)" },
  { suburb: "Bribbaree", postcode: 2594, lga: "Weddin (Area)" },
  { suburb: "Bimbi", postcode: 2810, lga: "Weddin (Area)" },
  { suburb: "Pinnacle", postcode: 2810, lga: "Weddin (Area)" },
  { suburb: "Pullabooka", postcode: 2810, lga: "Weddin (Area)" },
  { suburb: "Warraderry", postcode: 2810, lga: "Weddin (Area)" },
  { suburb: "Piney Range", postcode: 2810, lga: "Weddin (Area)" },
  { suburb: "Suntop", postcode: 2820, lga: "Western Plains Regional (Area)" },
  {
    suburb: "Curra Creek",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  { suburb: "Gollan", postcode: 2820, lga: "Western Plains Regional (Area)" },
  {
    suburb: "Montefiores",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Wellington",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  { suburb: "Farnham", postcode: 2820, lga: "Western Plains Regional (Area)" },
  {
    suburb: "North Yeoval",
    postcode: 2868,
    lga: "Western Plains Regional (Area)",
  },
  { suburb: "Ponto", postcode: 2818, lga: "Western Plains Regional (Area)" },
  { suburb: "Yarragal", postcode: 2820, lga: "Western Plains Regional (Area)" },
  {
    suburb: "Arthurville",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Cundumbul",
    postcode: 2866,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Comobella",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  { suburb: "Geurie", postcode: 2818, lga: "Western Plains Regional (Area)" },
  {
    suburb: "Mookerawa",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Mount Aquila",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Spicers Creek",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Terrabella",
    postcode: 2818,
    lga: "Western Plains Regional (Area)",
  },
  { suburb: "Walmer", postcode: 2820, lga: "Western Plains Regional (Area)" },
  { suburb: "Wuuluman", postcode: 2820, lga: "Western Plains Regional (Area)" },
  {
    suburb: "Bodangora",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Mount Arthur",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Stuart Town",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  { suburb: "Mumbil", postcode: 2820, lga: "Western Plains Regional (Area)" },
  {
    suburb: "Lake Burrendong",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Dripstone",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  { suburb: "Maryvale", postcode: 2820, lga: "Western Plains Regional (Area)" },
  { suburb: "Neurea", postcode: 2820, lga: "Western Plains Regional (Area)" },
  {
    suburb: "Bakers Swamp",
    postcode: 2820,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Euchareena",
    postcode: 2866,
    lga: "Western Plains Regional (Area)",
  },
  {
    suburb: "Bournewood",
    postcode: 2868,
    lga: "Western Plains Regional (Area)",
  },
  { suburb: "Rufus", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Scotia", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Wentworth", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Curlwaa", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Pooncarie", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Anabranch North", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Anabranch South", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Pan Ban", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Mallee", postcode: 2738, lga: "Wentworth (Area)" },
  { suburb: "Monak", postcode: 2738, lga: "Wentworth (Area)" },
  { suburb: "Coomealla", postcode: 2717, lga: "Wentworth (Area)" },
  { suburb: "Dareton", postcode: 2717, lga: "Wentworth (Area)" },
  { suburb: "Buronga", postcode: 2739, lga: "Wentworth (Area)" },
  { suburb: "Gol Gol", postcode: 2738, lga: "Wentworth (Area)" },
  { suburb: "Trentham Cliffs", postcode: 2738, lga: "Wentworth (Area)" },
  { suburb: "Chatswood", postcode: 2067, lga: "Willoughby (City)" },
  { suburb: "Artarmon", postcode: 2064, lga: "Willoughby (City)" },
  { suburb: "Willoughby", postcode: 2068, lga: "Willoughby (City)" },
  { suburb: "Naremburn", postcode: 2065, lga: "Willoughby (City)" },
  { suburb: "North Willoughby", postcode: 2068, lga: "Willoughby (City)" },
  { suburb: "Northbridge", postcode: 2063, lga: "Willoughby (City)" },
  { suburb: "Castlecrag", postcode: 2068, lga: "Willoughby (City)" },
  { suburb: "Castle Cove", postcode: 2069, lga: "Willoughby (City)" },
  { suburb: "Middle Cove", postcode: 2068, lga: "Willoughby (City)" },
  { suburb: "Willoughby East", postcode: 2068, lga: "Willoughby (City)" },
  { suburb: "New Berrima", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Moss Vale", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Burradoo", postcode: 2576, lga: "Wingecarribee (Area)" },
  { suburb: "Bowral", postcode: 2576, lga: "Wingecarribee (Area)" },
  { suburb: "Welby", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Mittagong", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Willow Vale", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Colo Vale", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Werai", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Wingello", postcode: 2579, lga: "Wingecarribee (Area)" },
  { suburb: "Mount Murray", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Avon", postcode: 2574, lga: "Wingecarribee (Area)" },
  { suburb: "Bargo", postcode: 2574, lga: "Wingecarribee (Area)" },
  { suburb: "Buxton", postcode: 2571, lga: "Wingecarribee (Area)" },
  { suburb: "High Range", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Woodlands", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Yerrinbool", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Mount Lindsey", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Meryla", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Wattle Ridge", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Glenquarry", postcode: 2576, lga: "Wingecarribee (Area)" },
  { suburb: "Burrawang", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Kangaloon", postcode: 2576, lga: "Wingecarribee (Area)" },
  { suburb: "Sutton Forest", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Penrose", postcode: 2579, lga: "Wingecarribee (Area)" },
  { suburb: "Wildes Meadow", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Robertson", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Fitzroy Falls", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "East Kangaloon", postcode: 2576, lga: "Wingecarribee (Area)" },
  { suburb: "Balmoral", postcode: 2571, lga: "Wingecarribee (Area)" },
  { suburb: "Hill Top", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Bullio", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Canyonleigh", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Joadja", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Bundanoon", postcode: 2578, lga: "Wingecarribee (Area)" },
  { suburb: "Exeter", postcode: 2579, lga: "Wingecarribee (Area)" },
  { suburb: "Berrima", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Medway", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Aylmerton", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Avoca", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Belanglo", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Braemar", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Balaclava", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Renwick", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Mandemar", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Manchester Square", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Orangeville", postcode: 2570, lga: "Wollondilly (Area)" },
  { suburb: "Silverdale", postcode: 2752, lga: "Wollondilly (Area)" },
  { suburb: "Pheasants Nest", postcode: 2574, lga: "Wollondilly (Area)" },
  { suburb: "Yerranderie", postcode: 2787, lga: "Wollondilly (Area)" },
  { suburb: "The Oaks", postcode: 2570, lga: "Wollondilly (Area)" },
  { suburb: "Appin", postcode: 2560, lga: "Wollondilly (Area)" },
  { suburb: "Lakesland", postcode: 2572, lga: "Wollondilly (Area)" },
  { suburb: "Brownlow Hill", postcode: 2570, lga: "Wollondilly (Area)" },
  { suburb: "Camden Park", postcode: 2570, lga: "Wollondilly (Area)" },
  { suburb: "Theresa Park", postcode: 2570, lga: "Wollondilly (Area)" },
  { suburb: "Warragamba", postcode: 2752, lga: "Wollondilly (Area)" },
  { suburb: "Tahmoor", postcode: 2573, lga: "Wollondilly (Area)" },
  { suburb: "Mount Hunter", postcode: 2570, lga: "Wollondilly (Area)" },
  { suburb: "Menangle", postcode: 2568, lga: "Wollondilly (Area)" },
  { suburb: "Nattai", postcode: 2570, lga: "Wollondilly (Area)" },
  { suburb: "Picton", postcode: 2571, lga: "Wollondilly (Area)" },
  { suburb: "Oakdale", postcode: 2570, lga: "Wollondilly (Area)" },
  { suburb: "Mowbray Park", postcode: 2571, lga: "Wollondilly (Area)" },
  { suburb: "Wilton", postcode: 2571, lga: "Wollondilly (Area)" },
  { suburb: "Thirlmere", postcode: 2572, lga: "Wollondilly (Area)" },
  { suburb: "Douglas Park", postcode: 2569, lga: "Wollondilly (Area)" },
  { suburb: "Werombi", postcode: 2570, lga: "Wollondilly (Area)" },
  { suburb: "Yanderra", postcode: 2574, lga: "Wollondilly (Area)" },
  { suburb: "Couridjah", postcode: 2571, lga: "Wollondilly (Area)" },
  { suburb: "Razorback", postcode: 2571, lga: "Wollondilly (Area)" },
  { suburb: "Belimbla Park", postcode: 2570, lga: "Wollondilly (Area)" },
  { suburb: "Cataract", postcode: 2560, lga: "Wollondilly (Area)" },
  { suburb: "Glenmore", postcode: 2570, lga: "Wollondilly (Area)" },
  { suburb: "Maldon", postcode: 2571, lga: "Wollondilly (Area)" },
  { suburb: "Mount Pleasant", postcode: 2519, lga: "Wollongong (City)" },
  { suburb: "Keiraville", postcode: 2500, lga: "Wollongong (City)" },
  { suburb: "Gwynneville", postcode: 2500, lga: "Wollongong (City)" },
  { suburb: "Tarrawanna", postcode: 2518, lga: "Wollongong (City)" },
  { suburb: "Fernhill", postcode: 2519, lga: "Wollongong (City)" },
  { suburb: "Corrimal", postcode: 2518, lga: "Wollongong (City)" },
  { suburb: "Austinmer", postcode: 2515, lga: "Wollongong (City)" },
  { suburb: "Thirroul", postcode: 2515, lga: "Wollongong (City)" },
  { suburb: "Russell Vale", postcode: 2517, lga: "Wollongong (City)" },
  { suburb: "Woonona", postcode: 2517, lga: "Wollongong (City)" },
  { suburb: "Bellambi", postcode: 2518, lga: "Wollongong (City)" },
  { suburb: "Bulli", postcode: 2516, lga: "Wollongong (City)" },
  { suburb: "Coledale", postcode: 2515, lga: "Wollongong (City)" },
  { suburb: "Wombarra", postcode: 2515, lga: "Wollongong (City)" },
  { suburb: "Scarborough", postcode: 2515, lga: "Wollongong (City)" },
  { suburb: "Clifton", postcode: 2515, lga: "Wollongong (City)" },
  { suburb: "Mount Ousley", postcode: 2519, lga: "Wollongong (City)" },
  { suburb: "Figtree", postcode: 2525, lga: "Wollongong (City)" },
  { suburb: "Spring Hill", postcode: 2500, lga: "Wollongong (City)" },
  { suburb: "Horsley", postcode: 2530, lga: "Wollongong (City)" },
  { suburb: "Avondale", postcode: 2530, lga: "Wollongong (City)" },
  { suburb: "Cordeaux", postcode: 2526, lga: "Wollongong (City)" },
  { suburb: "Dapto", postcode: 2530, lga: "Wollongong (City)" },
  { suburb: "Kanahooka", postcode: 2530, lga: "Wollongong (City)" },
  { suburb: "Koonawarra", postcode: 2530, lga: "Wollongong (City)" },
  { suburb: "Farmborough Heights", postcode: 2526, lga: "Wollongong (City)" },
  { suburb: "Cordeaux Heights", postcode: 2526, lga: "Wollongong (City)" },
  { suburb: "Unanderra", postcode: 2526, lga: "Wollongong (City)" },
  { suburb: "Berkeley", postcode: 2506, lga: "Wollongong (City)" },
  { suburb: "Lake Heights", postcode: 2502, lga: "Wollongong (City)" },
  { suburb: "Windang", postcode: 2528, lga: "Wollongong (City)" },
  { suburb: "Cringila", postcode: 2502, lga: "Wollongong (City)" },
  { suburb: "Warrawong", postcode: 2502, lga: "Wollongong (City)" },
  { suburb: "Helensburgh", postcode: 2508, lga: "Wollongong (City)" },
  { suburb: "Otford", postcode: 2508, lga: "Wollongong (City)" },
  { suburb: "Brownsville", postcode: 2530, lga: "Wollongong (City)" },
  { suburb: "Stanwell Park", postcode: 2508, lga: "Wollongong (City)" },
  { suburb: "Kembla Grange", postcode: 2526, lga: "Wollongong (City)" },
  { suburb: "Primbee", postcode: 2502, lga: "Wollongong (City)" },
  { suburb: "Mount Kembla", postcode: 2526, lga: "Wollongong (City)" },
  { suburb: "Wongawilli", postcode: 2530, lga: "Wollongong (City)" },
  { suburb: "Huntley", postcode: 2530, lga: "Wollongong (City)" },
  { suburb: "Dombarton", postcode: 2530, lga: "Wollongong (City)" },
  { suburb: "Kembla Heights", postcode: 2526, lga: "Wollongong (City)" },
  { suburb: "Coalcliff", postcode: 2508, lga: "Wollongong (City)" },
  { suburb: "Darkes Forest", postcode: 2508, lga: "Wollongong (City)" },
  { suburb: "Stanwell Tops", postcode: 2508, lga: "Wollongong (City)" },
  { suburb: "Lilyvale", postcode: 2508, lga: "Wollongong (City)" },
  { suburb: "Haywards Bay", postcode: 2530, lga: "Wollongong (City)" },
  { suburb: "Yallah", postcode: 2530, lga: "Wollongong (City)" },
  { suburb: "Maddens Plains", postcode: 2508, lga: "Wollongong (City)" },
  { suburb: "Cleveland", postcode: 2530, lga: "Wollongong (City)" },
  { suburb: "Darling Point", postcode: 2027, lga: "Woollahra (Area)" },
  { suburb: "Woollahra", postcode: 2025, lga: "Woollahra (Area)" },
  { suburb: "Bellevue Hill", postcode: 2023, lga: "Woollahra (Area)" },
  { suburb: "Double Bay", postcode: 2028, lga: "Woollahra (Area)" },
  { suburb: "Point Piper", postcode: 2027, lga: "Woollahra (Area)" },
  { suburb: "Edgecliff", postcode: 2027, lga: "Woollahra (Area)" },
  { suburb: "Watsons Bay", postcode: 2030, lga: "Woollahra (Area)" },
  { suburb: "Wadalba", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Kanwal", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Wyongah", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Blue Haven", postcode: 2262, lga: "Central Coast (City)" },
  { suburb: "Lake Haven", postcode: 2263, lga: "Central Coast (City)" },
  { suburb: "Gorokan", postcode: 2263, lga: "Central Coast (City)" },
  { suburb: "Charmhaven", postcode: 2263, lga: "Central Coast (City)" },
  { suburb: "San Remo", postcode: 2262, lga: "Central Coast (City)" },
  { suburb: "Buff Point", postcode: 2262, lga: "Central Coast (City)" },
  { suburb: "Toukley", postcode: 2263, lga: "Central Coast (City)" },
  { suburb: "Mannering Park", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Colongra", postcode: 2262, lga: "Central Coast (City)" },
  { suburb: "Canton Beach", postcode: 2263, lga: "Central Coast (City)" },
  { suburb: "Budgewoi", postcode: 2262, lga: "Central Coast (City)" },
  { suburb: "Noraville", postcode: 2263, lga: "Central Coast (City)" },
  { suburb: "Halekulani", postcode: 2262, lga: "Central Coast (City)" },
  { suburb: "Summerland Point", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Norah Head", postcode: 2263, lga: "Central Coast (City)" },
  { suburb: "Chain Valley Bay", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Lake Munmorah", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Gwandalan", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Wyong", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Durren Durren", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Kingfisher Shores", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Doyalson North", postcode: 2262, lga: "Central Coast (City)" },
  { suburb: "Doyalson", postcode: 2262, lga: "Central Coast (City)" },
  { suburb: "Point Wolstoncroft", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Crangan Bay", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Moonee", postcode: 2259, lga: "Lake Macquarie (City)" },
  { suburb: "Hamlyn Terrace", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Woongarrah", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Warnervale", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Wallarah", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Halloran", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Bushells Ridge", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Kiar", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Alison", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Jilliby", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Freemans", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Frazer Park", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Wybung", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Dooralong", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Mardi", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Tuggerah", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Watanobbi", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Berkeley Vale", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "Tumbi Umbi", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "Chittaway Bay", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "Killarney Vale", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "Bateau Bay", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "Long Jetty", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "Shelly Beach", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "The Entrance", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "Blue Bay", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "The Entrance North", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "Magenta", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "Tuggerawong", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Ourimbah", postcode: 2258, lga: "Central Coast (City)" },
  { suburb: "Toowoon Bay", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "Glenning Valley", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "Chittaway Point", postcode: 2261, lga: "Central Coast (City)" },
  { suburb: "Fountaindale", postcode: 2258, lga: "Central Coast (City)" },
  { suburb: "Tacoma South", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Tacoma", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Rocky Point", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Kangy Angy", postcode: 2258, lga: "Central Coast (City)" },
  { suburb: "Palm Grove", postcode: 2258, lga: "Central Coast (City)" },
  { suburb: "Little Jilliby", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Wyong Creek", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Yarramalong", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Ravensdale", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Cedar Brush Creek", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Lemon Tree", postcode: 2259, lga: "Central Coast (City)" },
  { suburb: "Palmdale", postcode: 2258, lga: "Central Coast (City)" },
  { suburb: "Wallaroo", postcode: 2618, lga: "Yass Valley (Area)" },
  { suburb: "Binalong", postcode: 2584, lga: "Yass Valley (Area)" },
  { suburb: "Yass", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Murrumbateman", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Nanima", postcode: 2618, lga: "Yass Valley (Area)" },
  { suburb: "Kangiara", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Wee Jasper", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Bowning", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Bookham", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Burrinjuck", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Narrangullen", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Good Hope", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Boambolo", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Cavan", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Gundaroo", postcode: 2620, lga: "Yass Valley (Area)" },
  { suburb: "Manton", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Bango", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Springrange", postcode: 2618, lga: "Yass Valley (Area)" },
  { suburb: "Yass River", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Woolgarlo", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Marchmont", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Jeir", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Mullion", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Young", postcode: 2594, lga: "Hilltops (Area)" },
  { suburb: "Bulla Creek", postcode: 2594, lga: "Hilltops (Area)" },
  { suburb: "Wirrimah", postcode: 2803, lga: "Hilltops (Area)" },
  { suburb: "Crowther", postcode: 2803, lga: "Hilltops (Area)" },
  { suburb: "Burrangong", postcode: 2594, lga: "Hilltops (Area)" },
  { suburb: "Murringo", postcode: 2586, lga: "Hilltops (Area)" },
  { suburb: "Bendick Murrell", postcode: 2803, lga: "Hilltops (Area)" },
  { suburb: "Monteagle", postcode: 2594, lga: "Hilltops (Area)" },
  { suburb: "Kikiamah", postcode: 2594, lga: "Hilltops (Area)" },
  { suburb: "Thuddungra", postcode: 2594, lga: "Hilltops (Area)" },
  { suburb: "Maimuru", postcode: 2594, lga: "Hilltops (Area)" },
  { suburb: "Tubbul", postcode: 2594, lga: "Hilltops (Area)" },
  { suburb: "Weedallion", postcode: 2594, lga: "Hilltops (Area)" },
  { suburb: "Memagong", postcode: 2594, lga: "Hilltops (Area)" },
  { suburb: "Berthong", postcode: 2594, lga: "Hilltops (Area)" },
  { suburb: "Milparinka", postcode: 2880, lga: "Unincorporated" },
  { suburb: "Silverton", postcode: 2880, lga: "Unincorporated" },
  { suburb: "Broughams Gate", postcode: 2880, lga: "Unincorporated" },
  { suburb: "Packsaddle", postcode: 2880, lga: "Unincorporated" },
  { suburb: "Mutawintji", postcode: 2880, lga: "Unincorporated" },
  { suburb: "Little Topar", postcode: 2880, lga: "Unincorporated" },
  { suburb: "Tibooburra", postcode: 2880, lga: "Unincorporated" },
  { suburb: "White Cliffs", postcode: 2836, lga: "Unincorporated" },
  { suburb: "Lord Howe Island", postcode: 2898, lga: "Unincorporated" },
  { suburb: "Winburndale", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Moollattoo", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Mondayong", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Calderwood", postcode: 2527, lga: "Shellharbour (City)" },
  { suburb: "Budgewoi Peninsula", postcode: 2262, lga: "Central Coast (City)" },
  { suburb: "Greengrove", postcode: 2250, lga: "Central Coast (City)" },
  { suburb: "Little Wobby", postcode: 2256, lga: "Central Coast (City)" },
  { suburb: "Calamia", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Warrumbungle", postcode: 2828, lga: "Coonamble (Area)" },
  { suburb: "Ryhope", postcode: 2283, lga: "Lake Macquarie (City)" },
  { suburb: "Havilah", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Riverlea", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Mullamuddy", postcode: 2850, lga: "Mid-Western Regional (Area)" },
  { suburb: "Coxs Creek", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  {
    suburb: "Upper Bylong",
    postcode: 2849,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "The Ponds", postcode: 2769, lga: "Blacktown (City)" },
  { suburb: "Sunset Strip", postcode: 2879, lga: "Central Darling (Area)" },
  { suburb: "Carnes Hill", postcode: 2171, lga: "Liverpool (City)" },
  { suburb: "New Park", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Tichborne", postcode: 2870, lga: "Parkes (Area)" },
  { suburb: "Elizabeth Hills", postcode: 2171, lga: "Liverpool (City)" },
  { suburb: "Lavadia", postcode: 2462, lga: "Clarence Valley (Area)" },
  { suburb: "Wambool", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Oyster Cove", postcode: 2318, lga: "Port Stephens (Area)" },
  { suburb: "Bardia", postcode: 2565, lga: "Campbelltown (City)" },
  { suburb: "Boorooma", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Gobarralong", postcode: 2727, lga: "Gundagai (Area)" },
  { suburb: "Barkers Vale", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "North Bourke", postcode: 2840, lga: "Bourke (Area)" },
  { suburb: "Alectown", postcode: 2870, lga: "Parkes (Area)" },
  { suburb: "Spring Farm", postcode: 2570, lga: "Camden (Area)" },
  { suburb: "Nundle", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Barangaroo", postcode: 2000, lga: "Sydney (City)" },
  { suburb: "Euabalong", postcode: 2877, lga: "Lachlan (Area)" },
  { suburb: "Walcha Road", postcode: 2354, lga: "Walcha (Area)" },
  { suburb: "Ellerslie", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Euabalong West", postcode: 2877, lga: "Cobar (Area)" },
  { suburb: "Para", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Moorara", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Boeill Creek", postcode: 2739, lga: "Wentworth (Area)" },
  { suburb: "Oran Park", postcode: 2570, lga: "Camden (Area)" },
  { suburb: "Illaroo", postcode: 2540, lga: "Shoalhaven (City)" },
  { suburb: "Yambulla", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Brookong", postcode: 2656, lga: "Lockhart (Area)" },
  { suburb: "Tongarra", postcode: 2527, lga: "Shellharbour (City)" },
  { suburb: "Perthville", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Evans Plains", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Tannas Mount", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Hill Top", postcode: 2628, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Bocobra", postcode: 2865, lga: "Cabonne (Area)" },
  { suburb: "Wadeville", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Lyndhurst", postcode: 2797, lga: "Blayney (Area)" },
  { suburb: "Pomona", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Piallamore", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Macquarie Pass", postcode: 2577, lga: "Shellharbour (City)" },
  { suburb: "Marshall Mount", postcode: 2530, lga: "Wollongong (City)" },
  {
    suburb: "Rollands Plains",
    postcode: 2441,
    lga: "Port Macquarie-Hastings (Area)",
  },
  { suburb: "Killawarra", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Greenethorpe", postcode: 2809, lga: "Weddin (Area)" },
  { suburb: "Thyra", postcode: 2731, lga: "Murray River (Area)" },
  { suburb: "Apsley", postcode: 2820, lga: "Western Plains Regional (Area)" },
  { suburb: "Dyers Crossing", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Ellerslie", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Mogareeka", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Upper Burringbar", postcode: 2483, lga: "Tweed (Area)" },
  { suburb: "Bedgerabong", postcode: 2871, lga: "Forbes (Area)" },
  { suburb: "Hill End", postcode: 2850, lga: "Bathurst Regional (Area)" },
  { suburb: "Lake Hume Village", postcode: 3691, lga: "Albury (City)" },
  { suburb: "Midginbil", postcode: 2484, lga: "Tweed (Area)" },
  {
    suburb: "Reidsdale",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  { suburb: "James Creek", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Gloucester Tops", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Weilmoringle", postcode: 2839, lga: "Brewarrina (Area)" },
  { suburb: "Murrays Beach", postcode: 2281, lga: "Lake Macquarie (City)" },
  { suburb: "Bobin", postcode: 2429, lga: "Mid-Coast (Area)" },
  { suburb: "Faulkland", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Booerie Creek", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Edgeroi", postcode: 2390, lga: "Narrabri (Area)" },
  { suburb: "Booyong", postcode: 2480, lga: "Lismore (City)" },
  { suburb: "Barratta", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Ellerston", postcode: 2337, lga: "Upper Hunter Shire (Area)" },
  { suburb: "Cornwallis", postcode: 2756, lga: "Hawkesbury (City)" },
  { suburb: "Richmond Lowlands", postcode: 2753, lga: "Hawkesbury (City)" },
  { suburb: "Hobbys Yards", postcode: 2795, lga: "Blayney (Area)" },
  { suburb: "Rookhurst", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Dungowan", postcode: 2340, lga: "Tamworth Regional (Area)" },
  {
    suburb: "Krawarree",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  { suburb: "Mondrook", postcode: 2430, lga: "Mid-Coast (Area)" },
  {
    suburb: "Mount Fairy",
    postcode: 2580,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  { suburb: "Windera", postcode: 2800, lga: "Cabonne (Area)" },
  { suburb: "Limekilns", postcode: 2795, lga: "Bathurst Regional (Area)" },
  { suburb: "Gledswood Hills", postcode: 2557, lga: "Camden (Area)" },
  { suburb: "Dungeree", postcode: 2849, lga: "Mid-Western Regional (Area)" },
  { suburb: "Yaven Creek", postcode: 2729, lga: "Snowy Valleys (Area)" },
  { suburb: "Myall Park", postcode: 2681, lga: "Carrathool (Area)" },
  { suburb: "Bombowlee", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Bimberi", postcode: 2611, lga: "Snowy Valleys (Area)" },
  { suburb: "Len Waters Estate", postcode: 2171, lga: "Liverpool (City)" },
  { suburb: "Alpine", postcode: 2575, lga: "Wingecarribee (Area)" },
  { suburb: "Hartwood", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Mourquong", postcode: 2739, lga: "Wentworth (Area)" },
  { suburb: "Morago", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Sandy Creek", postcode: 2835, lga: "Cobar (Area)" },
  { suburb: "Coopers Gully", postcode: 2550, lga: "Bega Valley (Area)" },
  { suburb: "Fowlers Gap", postcode: 2880, lga: "Unincorporated" },
  { suburb: "Lake Brewster", postcode: 2675, lga: "Carrathool (Area)" },
  { suburb: "Murray Gorge", postcode: 2642, lga: "Snowy Valleys (Area)" },
  { suburb: "Kosciuszko", postcode: 2627, lga: "Snowy Valleys (Area)" },
  { suburb: "Glen Nevis", postcode: 2365, lga: "Glen Innes Severn (Area)" },
  { suburb: "Baryulgil", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Eden Creek", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Fawcetts Plain", postcode: 2474, lga: "Kyogle (Area)" },
  { suburb: "Wandera", postcode: 2360, lga: "Inverell (Area)" },
  { suburb: "Byrrill Creek", postcode: 2484, lga: "Tweed (Area)" },
  { suburb: "Burnt Bridge", postcode: 2440, lga: "Kempsey (Area)" },
  {
    suburb: "Coasters Retreat",
    postcode: 2108,
    lga: "Northern Beaches (Area)",
  },
  { suburb: "Ulamambri", postcode: 2357, lga: "Warrumbungle Shire (Area)" },
  {
    suburb: "Doctor George Mountain",
    postcode: 2550,
    lga: "Bega Valley (Area)",
  },
  { suburb: "Ettamogah", postcode: 2640, lga: "Albury (City)" },
  { suburb: "Appleby", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Yantabulla", postcode: 2840, lga: "Bourke (Area)" },
  { suburb: "Windowie", postcode: 2720, lga: "Snowy Valleys (Area)" },
  { suburb: "Brungle Creek", postcode: 2722, lga: "Snowy Valleys (Area)" },
  { suburb: "Woodhill", postcode: 2535, lga: "Shoalhaven (City)" },
  { suburb: "Pretty Pine", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Nyora", postcode: 2646, lga: "Murrumbidgee (Area)" },
  { suburb: "Willurah", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Paringi", postcode: 2738, lga: "Wentworth (Area)" },
  { suburb: "Mayrung", postcode: 2710, lga: "Edward River (Area)" },
  { suburb: "Gilgunnia", postcode: 2835, lga: "Cobar (Area)" },
  { suburb: "Pine Camp", postcode: 2648, lga: "Wentworth (Area)" },
  { suburb: "Murrin Bridge", postcode: 2672, lga: "Cobar (Area)" },
  { suburb: "Gilmandyke", postcode: 2795, lga: "Oberon (Area)" },
  {
    suburb: "Ballalaba",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  {
    suburb: "Canadian Lead",
    postcode: 2850,
    lga: "Mid-Western Regional (Area)",
  },
  { suburb: "Gunbar", postcode: 2711, lga: "Carrathool (Area)" },
  { suburb: "Glen Ward", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Dalmorton", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Redlands", postcode: 2646, lga: "Federation (Area)" },
  { suburb: "Oberne Creek", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Book Book", postcode: 2650, lga: "Wagga Wagga (City)" },
  { suburb: "Paddys River", postcode: 2577, lga: "Wingecarribee (Area)" },
  { suburb: "Bakers Creek", postcode: 2359, lga: "Uralla (Area)" },
  { suburb: "Bectric", postcode: 2665, lga: "Temora (Area)" },
  { suburb: "Weabonga", postcode: 2340, lga: "Tamworth Regional (Area)" },
  { suburb: "Borah Creek", postcode: 2346, lga: "Tamworth Regional (Area)" },
  { suburb: "Taloumbi", postcode: 2463, lga: "Clarence Valley (Area)" },
  { suburb: "Shark Creek", postcode: 2463, lga: "Clarence Valley (Area)" },
  {
    suburb: "Larbert",
    postcode: 2622,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  { suburb: "Breadalbane", postcode: 2581, lga: "Upper Lachlan Shire (Area)" },
  { suburb: "Warkworth", postcode: 2330, lga: "Singleton (Area)" },
  { suburb: "Moppy", postcode: 2422, lga: "Mid-Coast (Area)" },
  { suburb: "Moorilda", postcode: 2795, lga: "Blayney (Area)" },
  { suburb: "Wingadee", postcode: 2829, lga: "Coonamble (Area)" },
  { suburb: "Laverstock", postcode: 2582, lga: "Yass Valley (Area)" },
  { suburb: "Punchbowl", postcode: 2460, lga: "Clarence Valley (Area)" },
  { suburb: "Bolaro", postcode: 2629, lga: "Snowy Monaro Regional (Area)" },
  { suburb: "Welaregang", postcode: 2642, lga: "Snowy Valleys (Area)" },
  { suburb: "Upper Bingara", postcode: 2404, lga: "Gwydir (Area)" },
  { suburb: "Southampton", postcode: 2460, lga: "Clarence Valley (Area)" },
  {
    suburb: "Environa",
    postcode: 2620,
    lga: "Queanbeyan-Palerang Regional (Area)",
  },
  { suburb: "Glenellen", postcode: 2642, lga: "Greater Hume Shire (Area)" },
];
