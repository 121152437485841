import "./App.less";
import React from "react";
import "react-quill/dist/quill.snow.css";
import { Route, Redirect, Switch } from "react-router-dom";
import HomePage from "./components/HomePage";
import KayitFormu from "./components/KayitFormu";
import LoginPage from "./components/LoginPage";
import NotFound from "./components/NotFound";
import Submitted from "./components/Submitted";
import NewBus from "./components/NewBus";
import ExitForm from "./components/ExitForm";
import AdminLayout from "./layouts/AdminLayout";

function App() {
  return (
    <div className="bg-eeeeee">
      <Switch>
        <Route path="/" exact>
          <Redirect to="/home" />
        </Route>
        <Route path="/home" component={HomePage} />
        <Route path="/newbus" component={NewBus} />
        <Route path="/exitbus" component={ExitForm} />

        <Route path="/form" component={KayitFormu} />
        <Route path="/login" component={LoginPage} />
        <Route path="/admin" component={AdminLayout} />
        <Route path="/completed" component={Submitted} />

        <Route path="/404" component={NotFound} />

        <Route>
          <Redirect to="/404" />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
