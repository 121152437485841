import { PrinterOutlined } from "@ant-design/icons";
import { Button, Descriptions, Divider, Table, Tag } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { AdminService } from "src/resources/AdminService";

const BusList = () => {
  const { id } = useParams();
  const [bus, setBus] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.busStudents(id).then((data) => {
        setBus(data.data);
        console.log(data.data);
      });
    }
  }, [initialRender]);

  const ComponentToPrint = React.forwardRef((props, ref) => {
    const columns = [
      {
        title: "Fullname",
        dataIndex: "fullname",
      },
      {
        title: "Address",
        dataIndex: "address",
      },
      {
        title: "Suburb",
        dataIndex: "suburb",
      },
      {
        title: "Postcode",
        dataIndex: "postcode",
      },
      {
        title: "Contact",
        dataIndex: "guardian",
      },
      {
        title: "Phone",
        dataIndex: "phone",
      },
      {
        title: "Year",
        dataIndex: "year",
      },
      {
        title: "Usage",
        dataIndex: "usage",
      },
    ];
    return (
      <div className="bg-white border border-gray-300 px-4 py-6 my-4">
        {bus && (
          <div ref={ref} className="max-w-7xl mx-auto">
            <Descriptions
              bordered
              title={`Bus - ${bus.busno} details`}
              size="small"
            >
              <Descriptions.Item label="Driver Name">
                {bus.driver}
              </Descriptions.Item>
              <Descriptions.Item label="Driver Phone">
                {bus.phone}
              </Descriptions.Item>
              <Descriptions.Item label="Suburbs">
                {bus.suburbs.map((s, index) => (
                  <Tag key={index}>{s.name}</Tag>
                ))}
              </Descriptions.Item>
            </Descriptions>
            <Divider orientation="left">Students List</Divider>
            <Table
              dataSource={bus.students}
              bordered
              size="small"
              columns={columns}
              rowKey="id"
              pagination={false}
            />
          </div>
        )}
      </div>
    );
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="max-w-7xl mx-auto m-4">
      <ComponentToPrint ref={componentRef} />
      <br />
      <Button
        size="large"
        icon={<PrinterOutlined />}
        type="primary"
        onClick={handlePrint}
      >
        Print this Page
      </Button>
    </div>
  );
};

export default BusList;
