import React, { useRef, useState } from "react";
import { ClearOutlined, CloseOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
} from "antd";
import { useHistory } from "react-router-dom";
import { GlobalVariables } from "src/resources/GlobalVariables";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { AdminService } from "src/resources/AdminService";
import SignaturePad from "react-signature-canvas";

export default function KayitFormu() {
  const router = useHistory();
  const [form] = Form.useForm();
  const [isAllergic, setIsAllergic] = useState(false);
  const { lg } = useBreakpoint();
  const [imageURL, setImageURL] = useState(null);
  const [sigModal, setSigModal] = useState(false);
  const sigCanvas = useRef({});
  const [onay, setOnay] = useState(true);
  /* a function that uses the canvas ref to clear the canvas 
    via a method given by react-signature-canvas */
  const clear = () => {
    sigCanvas.current.clear();
    setImageURL(null);
  };
  const close = () => setSigModal(false);
  const onFinish = (values) => {
    console.log(values);
    if (imageURL === null) {
      notification.error({
        message: "Hata",
        description: "Imza atmaniz gerekmektedir",
      });
      return;
    }
    values = { ...values, signature: imageURL };
    AdminService.newStudent(values).then((data) => {
      notification.success({
        message: "Basarili!",
        description: "Ogrenci kaydedildi!",
      });
      onReset();
      router.replace("/completed");
    });
  };
  const save = () => {
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    setSigModal(false);
  };
  const onReset = () => {
    form.resetFields();
  };
  const allergieChanged = (v) => {
    console.log(v);

    v === "Evet" ? setIsAllergic(true) : setIsAllergic(false);
  };
  const onChange = (e) => {
    setOnay(e.target.checked);
  };
  return (
    <div className="container mx-auto min-h-screen">
      <Modal visible={sigModal} onCancel={close} footer={null} closable={false}>
        <div>
          <SignaturePad
            ref={sigCanvas}
            canvasProps={{
              className: "signatureCanvas",
            }}
          />
          {/* Button to trigger save canvas image */}
          <Space className="mt-3" size="small">
            <Button type="primary" icon={<SaveOutlined />} onClick={save}>
              Kaydet
            </Button>
            <Button
              className="bg-gradient-to-b from-gray-50 to-gray-200"
              icon={<ClearOutlined />}
              onClick={clear}
            >
              Sil
            </Button>
            <Button
              className="bg-gradient-to-b from-gray-50 to-gray-200 text-red-600"
              onClick={() => setSigModal(false)}
            >
              Kapat
            </Button>
          </Space>
        </div>
      </Modal>
      <div className="py-6  overflow-hidden lg:pb-24">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl">
              YAZ ERKEK KUR'AN KURSU PROGRAMI
              <br />
              KAYIT FORMU
            </h2>
            <br />
          </div>
          <div className="border border-gray-300 bg-f5f5f5 p-2 lg:p-8 shadow-lg mx-auto max-w-4xl">
            <Form
              form={form}
              onFinish={onFinish}
              onReset={onReset}
              layout={lg ? "horizontal" : "vertical"}
              {...GlobalVariables.formLayout}
            >
              <Form.Item
                label="Öğrenci ad soyadı"
                name="fullname"
                rules={[{ required: true, message: "Öğrenci ad soyadı!" }]}
              >
                <Input placeholder="Öğrenci ad soyadını yazınız" />
              </Form.Item>
              {/* <Form.Item
                label="Cinsiyet"
                name="gender"
                rules={[{ required: true, message: "Gender is required!" }]}
              >
                <Select placeholder="Lütfen cinsiyet seçiniz">
                  {["Erkek", "Kız"].map((g) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item> */}
              <Form.Item
                label="Yıl"
                name="year"
                rules={[{ required: true, message: "Year Level is required!" }]}
              >
                <Select placeholder="2021 yılında okududugu sınıf seviyesi">
                  {[
                    "Year 6",
                    "Year 7",
                    "Year 8",
                    "Year 9",
                    "Year 10",
                    "Year 11",
                    "Year 12",
                  ].map((g) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Kur'an Seviyesi"
                name="level"
                rules={[
                  { required: true, message: "Qur'an Level is required!" },
                ]}
              >
                <Input placeholder="Kur'an seviyesini birkac kelime ile ifade ediniz" />
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Daha önce Kur'an <br />
                    dersi aldı mı?
                  </span>
                }
                name="isBefore"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Select placeholder="Lütfen seçiniz" onChange={allergieChanged}>
                  {["Evet", "Hayır"].map((g) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span>
                    Çocuğunuzun <br />
                    alerjisi var mı
                  </span>
                }
                name="isAllergie"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Select placeholder="Lütfen seçiniz" onChange={allergieChanged}>
                  {["Evet", "Hayır"].map((g) => (
                    <Select.Option key={g} value={g}>
                      {g}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              {isAllergic && (
                <Form.Item
                  label="Alerji Detayları"
                  name="allergie"
                  rules={[{ required: true, message: "Details is required!" }]}
                >
                  <Input placeholder="Alerji detaylarını yazınız" />
                </Form.Item>
              )}
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p>
                  Çocuğunuz ile ilgili bilmemiz gereken sağlık durumu var mı? (?
                </p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                name="health"
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <Input placeholder="Sağlık bildirimi (eğer varsa)" />
              </Form.Item>
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p className="font-semibold">Veli Bilgisi</p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                label="Veli Ad Soyadı"
                name="gfullname"
                rules={[{ required: true, message: "Fullname is required!" }]}
              >
                <Input placeholder="Lütfen ad soyadı yazınız" />
              </Form.Item>
              <Form.Item
                label="Veli Telefonu"
                name="gphone"
                rules={[{ required: true, message: "Phone is required!" }]}
              >
                <Input placeholder="Lütfen telefonu yazınız" />
              </Form.Item>
              <Form.Item
                label="Veli Email"
                name="email"
                rules={[{ required: true, message: "Email is required!" }]}
              >
                <Input placeholder="Lütfen emailinizi yazınız" />
              </Form.Item>
              <Form.Item
                wrapperCol={
                  lg
                    ? {
                        offset: 6,
                        span: 18,
                      }
                    : {}
                }
              >
                <p className="font-semibold">
                  Acil Durumlarda aranacak kişi (Veliden farklı olarak)
                </p>
              </Form.Item>
              <Form.Item
                className="-mt-4"
                label="Kişi tam adı"
                name="efullname"
                rules={[{ required: true, message: "Fullname is required!" }]}
              >
                <Input placeholder="Lütfen ad yazınız" />
              </Form.Item>
              <Form.Item
                label="Kişi Telefonu"
                name="ephone"
                rules={[{ required: true, message: "Phone is required!" }]}
              >
                <Input placeholder="Lütfen Telefonu yazınız" />
              </Form.Item>
              <Form.Item label="Onay">
                <Checkbox onChange={onChange} defaultChecked={true}>
                  Program boyunca velisi bulunduğum kızımın aktivitelere ve
                  aktivite için gerekli ulaşım araçlarına binmesine izin
                  veriyorum. Acil bir durumda görevli öğretmenin gerekli ilk
                  yardım müdahalesini yapmasına izin veriyorum.
                </Checkbox>
              </Form.Item>
              <Form.Item label="imza" required>
                <Button
                  onClick={() => setSigModal(true)}
                  className="text-sky-600"
                >
                  İmza Panelini Aç
                </Button>

                <br />
                <br />

                {imageURL ? (
                  <img
                    src={imageURL}
                    alt="my signature"
                    style={{
                      border: "1px solid #ddd",
                      padding: "15px",
                      backgroundColor: "white",
                      width: "150px",
                    }}
                  />
                ) : null}
              </Form.Item>
              <Form.Item {...GlobalVariables.tailLayout} className="mt-6">
                <Space>
                  <Button
                    type="default"
                    htmlType="reset"
                    icon={<ClearOutlined />}
                  >
                    Temizle
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                    disabled={!onay || !imageURL}
                  >
                    Kaydet
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </div>
          <svg
            className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={784}
              fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
            />
          </svg>
        </div>
      </div>
    </div>
  );
}
