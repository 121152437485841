import { axios } from "./HttpClient";

export const AdminService = {
  newStudent: (values) => {
    return axios.post("/students", values);
  },
  login: (values) => {
    return axios.post("/staff/login", values);
  },
  getStudents: () => {
    return axios.get("/students");
  },
  getExcel: () => {
    return axios.get("reports/excel", { responseType: "blob" });
  },
  deleteStudent: (id) => {
    return axios.delete("/students/" + id);
  },
  sendEmailToGuardians: (values) => {
    return axios.post("/students/email", values);
  },
  getBuses: () => {
    return axios.get("/buses");
  },
  newBus: (values) => {
    return axios.post("/buses", values);
  },
  busStudents: (id) => {
    return axios.get("/buses/" + id);
  },
  changeBus: (values) => {
    return axios.put("/buses", values);
  },
  getStudent: (id) => {
    return axios.get("/students/" + id);
  },
  newWithDraw: (values) => {
    return axios.post("/withdraws", values);
  },
  getWithdraws: () => {
    return axios.get("/withdraws");
  },
  getReports: () => {
    return axios.get("/reports");
  },
  editBus: (values) => {
    return axios.put("/buses/" + values.id, values);
  },
  deleteBus: (id) => {
    return axios.delete("/buses/" + id);
  },
};
