import { Divider, Table } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AdminService } from "src/resources/AdminService";

const Withdraws = () => {
  const [wdraws, setWdraws] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getWithdraws().then((data) => {
        console.log(data.data);
        setWdraws(data.data);
      });
    }
  }, [initialRender]);
  const columns = [
    {
      title: "Given Name",
      dataIndex: "givenName",
    },
    {
      title: "Family Name",
      dataIndex: "familyName",
    },
    {
      title: "Year",
      dataIndex: "year",
    },
    {
      title: "Parent Name",
      dataIndex: "p1givenName",
    },
    {
      title: "Parent Surname",
      dataIndex: "p1familyName",
    },
    {
      title: "Parent Email",
      dataIndex: "p1email",
    },
    {
      title: "Parent Phone",
      dataIndex: "p1phone",
    },
    {
      title: "Date",
      dataIndex: "createdOn",
      render: (createdOn) => moment(createdOn).format("DD-MM-YY"),
    },
  ];
  return (
    <div className="max-w-7xl mx-auto bg-fafafa border-gray-300 border p-4">
      <Divider orientation="left">Withdraws</Divider>
      <Table
        rowKey="id"
        size="small"
        bordered
        dataSource={wdraws}
        columns={columns}
        loading={wdraws === null}
      />
    </div>
  );
};

export default Withdraws;
