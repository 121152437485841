import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Space } from "antd";
import { useHistory } from "react-router-dom";

export default function HomePage() {
  const router = useHistory();

  return (
    <div className="container mx-auto min-h-screen">
      <div className="py-6  overflow-hidden lg:pb-24 ">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h2 className="text-center uppercase text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl mb-6">
              Bus Enrolment Form
            </h2>
            <h2 className="text-center uppercase text-3xl leading-8 font-extrabold tracking-tight text-red-600 sm:text-4xl">
              CHOPPERS TRANSPORT
            </h2>

            <p className="text-center text-base">
              CHOPPERS TRANSPORT PTY. LTD. ABN: 67-165-797-875
              <br />
              6/24 BLENHEIM AVENUE ROOTY HILL NSW 2766
              <br />
              PHONE/FAX NO: (02) 8678 8620
              <br />
              MOBILE NO: 0450377155
              <br />
              bus@amity.nsw.edu.au
            </p>
          </div>
          <br />
          <br />
          <div className="relative">
            <h3 className="text-2xl font-bold text-amazonl tracking-tight sm:text-3xl mb-4 uppercase">
              About Choppers Transport
            </h3>
            <div className="space-y-4 text-base">
              <p>
                Amity College uses Choppers Transport PTY. LTD. as its official
                bus service provider.
                <br />
                <br />
                Choppers Transport Pty Ltd is committed to providing
                transportation and services of the highest standards to our
                valued customers. We are committed to ensuring a safe and
                seamless travel experience. Focusing on safety standards and
                complying with all current Passenger Transport Regulation, we
                aim to excel in quality transport services.
                <br />
                <br />
                Our company policy at Choppers Transport Pty Ltd is to implement
                the highest standards of safety and risk management practices
                ensuring seamless operations in student and employee transport.
                Prioritizing the safety and wellbeing of our passengers and
                drivers Choppers Transport Ptl Ltd is committed to continuous
                education assuring quality services and a continuous improvement
                in safety procedures.
                <br />
                <br />
                Our company believes safety is achieved through ongoing training
                programs and the transparency of prevalent safety standards. We
                will strive for ongoing safety, quality and customer centric
                excellence from management to all individual employees. <br />
                <br />
                {/* We are looking forward to providing your child(ren) quality
                transportation service in 2022. If you require a bus service,
                please complete our enrolment form below: */}
                <br />
                <br />
              </p>
              {/* <BusRoutes />
              <NewBus />

              <br />
              <TermsAndConditions />

              <br />
              <ExitForm /> */}
              <Space direction="vertical">
                <Button
                  block
                  className="bg-gradient-to-b from-sky-400 to-sky-600 hover:bg-gradient-to-b hover:from-sky-400 hover:to-sky-600 border-2 border-sky-600 hover:border-sky-800"
                  type="primary"
                  size="large"
                  icon={<ArrowRightOutlined />}
                  onClick={() => {
                    router.push("/newbus");
                  }}
                >
                  Apply / Renew bus service
                </Button>
                <Button
                  block
                  className="max-w-full mt-2 bg-gradient-to-b from-amazont to-amazonb  border-2 border-amazonl text-black hover:bg-gradient-to-b hover:from-amazont hover:to-amazonb hover:text-black hover:border-amazonl  "
                  size="large"
                  icon={<ArrowRightOutlined />}
                  onClick={() => {
                    router.push("/exitbus");
                  }}
                >
                  Withdraw from the existing service
                </Button>
              </Space>
            </div>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}
