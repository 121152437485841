import {
  ClearOutlined,
  ArrowRightOutlined,
  SaveOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Select,
  Space,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import { suburbs } from "src/resources/suburbs";
import TermsAndConditions from "./TermsAndConditions";

const NewBus = () => {
  const [form] = Form.useForm();
  const { lg } = useBreakpoint();
  const [s1Open, setS1Open] = useState(false);
  const [s2Open, setS2Open] = useState(false);
  const [s3Open, setS3Open] = useState(false);
  const router = useHistory();
  const [visible, setVisible] = useState(false);

  const onFinish = (values) => {
    console.log(values);
    if (form.getFieldValue("accepted") === false) {
      notification.error({ message: "Please accept terms and conditions" });
      return;
    }
    AdminService.newStudent(values).then((data) => {
      notification.success({
        message: "Success!",
        description: "Saved successfully",
      });
      router.push("/completed");
    });
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <div className="container mx-auto min-h-screen">
      <Modal
        visible={visible}
        width="100%"
        onCancel={null}
        onOk={() => setVisible(false)}
        closable={false}
        className="max-w-5xl mx-auto"
        footer={null}
      >
        <p className="text-sky-500 font-bold text-2xl mb-4">
          Terms and Conditions
        </p>
        <TermsAndConditions />
        <Button
          onClick={() => setVisible(false)}
          type="primary"
          className="mt-6 ml-6"
        >
          I Agree
        </Button>
      </Modal>
      <div className="py-6  overflow-hidden lg:pb-24 ">
        <img
          className="relative mx-auto mb-4"
          width={250}
          src={require("../assets/images/logo.png").default}
          alt="logo"
        />
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
          <svg
            className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
            width={404}
            height={784}
            fill="none"
            viewBox="0 0 404 784"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
            />
          </svg>

          <div className="relative">
            <h2 className="text-center uppercase text-3xl leading-8 font-extrabold tracking-tight text-amazonl sm:text-4xl mb-6">
              Bus Enrolment Form
            </h2>
            <h2 className="text-center uppercase text-3xl leading-8 font-extrabold tracking-tight text-red-600 sm:text-4xl">
              CHOPPERS TRANSPORT
            </h2>
          </div>
          <Form
            form={form}
            onFinish={onFinish}
            onReset={onReset}
            layout={lg ? "horizontal" : "vertical"}
            {...GlobalVariables.formLayout}
            initialValues={{ accepted: false }}
          >
            <Form.Item>
              <Divider orientation="left">
                <span className="text-red-600 font-bold">STUDENT DETAILS</span>
              </Divider>
            </Form.Item>
            <Form.Item
              label="Application Type"
              name="appType"
              rules={[{ required: true, message: "This filed is required!" }]}
            >
              <Select placeholder="Please select">
                {["New Application", "Renew Application"].map((a, index) => (
                  <Select.Option key={index} value={a}>
                    {a}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Family Name"
              name="familyName"
              rules={[{ required: true, message: "Family name is required!" }]}
            >
              <Input placeholder="Please type family name" />
            </Form.Item>
            <Form.Item
              label="Given Name"
              name="givenName"
              rules={[{ required: true, message: "Given name is required!" }]}
            >
              <Input placeholder="Please type given name" />
            </Form.Item>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Input placeholder="Please Type Address " />
            </Form.Item>
            <Form.Item
              wrapperCol={
                lg
                  ? {
                      offset: 6,
                      span: 18,
                    }
                  : {}
              }
            >
              <Form.Item
                rules={[{ required: true, message: "This field is required!" }]}
                name="suburb"
                style={{ display: "inline-block", width: "calc(70%)" }}
              >
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  placeholder={"Select Suburb"}
                  filterOption={(input, option) => {
                    return (
                      option.title.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    );
                  }}
                >
                  {suburbs
                    .sort((first, second) => {
                      return first.suburb > second.suburb ? 1 : -1;
                    })
                    .map((item, index) => (
                      <Select.Option
                        title={item.suburb}
                        key={index}
                        value={item.suburb}
                      >
                        {item.suburb}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Form.Item
                style={{ display: "inline-block", width: "calc(30%)" }}
                name="postcode"
                rules={[{ required: true, message: "This field is required!" }]}
              >
                <Input placeholder="Post code " />
              </Form.Item>
            </Form.Item>
            <Form.Item
              label="Student Year Level in 2022"
              name="year"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select placeholder="Select Year">
                {GlobalVariables.years.map((y) => (
                  <Select.Option key={y} value={y}>
                    {y}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Student Usage"
              name="usage"
              rules={[{ required: true, message: "This field is required!" }]}
            >
              <Select placeholder="Select Usage">
                {GlobalVariables.usages.map((y) => (
                  <Select.Option key={y.key} value={y.key}>
                    {y.value}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Any Medical Condition?" name="medical">
              <Input placeholder="Please Type Medical conditions (if any) " />
            </Form.Item>
            <Form.Item>
              <Divider orientation="left">
                {" "}
                <span className="text-red-600 font-bold">PARENT-1 DETAILS</span>
              </Divider>
            </Form.Item>
            <Form.Item
              label="Family Name"
              name="p1familyName"
              rules={[{ required: true, message: "Family name is required!" }]}
            >
              <Input placeholder="Please type family name" />
            </Form.Item>
            <Form.Item
              label="Given Name"
              name="p1givenName"
              rules={[{ required: true, message: "Given name is required!" }]}
            >
              <Input placeholder="Please type given name" />
            </Form.Item>
            <Form.Item
              label="E-mail"
              name="p1email"
              rules={[{ required: true, message: "Family name is required!" }]}
            >
              <Input placeholder="Please type parent-1 email" />
            </Form.Item>
            <Form.Item
              label="Mobile Phone"
              name="p1phone"
              rules={[{ required: true, message: "Given name is required!" }]}
            >
              <Input placeholder="Please type parent-1 mobile phone" />
            </Form.Item>
            <Form.Item>
              <Divider orientation="left">
                <span className="text-red-600 font-bold">PARENT-2 DETAILS</span>
              </Divider>
            </Form.Item>
            <Form.Item
              label="Family Name"
              name="p2familyName"
              rules={[{ required: true, message: "Family name is required!" }]}
            >
              <Input placeholder="Please type family name" />
            </Form.Item>
            <Form.Item
              label="Given Name"
              name="p2givenName"
              rules={[{ required: true, message: "Given name is required!" }]}
            >
              <Input placeholder="Please type given name" />
            </Form.Item>
            <Form.Item
              label="E-mail"
              name="p2email"
              rules={[{ required: true, message: "Family name is required!" }]}
            >
              <Input placeholder="Please type parent-1 email" />
            </Form.Item>
            <Form.Item
              label="Mobile Phone"
              name="p2phone"
              rules={[{ required: true, message: "Given name is required!" }]}
            >
              <Input placeholder="Please type parent-1 mobile phone" />
            </Form.Item>{" "}
            <Form.Item>
              <Divider orientation="left">
                <span className="text-red-600 font-bold">CONTACT PARENT</span>
              </Divider>
            </Form.Item>
            <Form.Item
              label="Contact Parent"
              name="cparent"
              rules={[{ required: true, message: "Contact is required!" }]}
            >
              <Select placeholder="Please select contact">
                {["Parent-1", "Parent-2"].map((p) => (
                  <Select.Option key={p} value={p}>
                    {p}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Divider orientation="left">
                <span className="text-red-600 font-bold">
                  EMERGENCY CONTACT DETAILS
                </span>
              </Divider>
            </Form.Item>
            <Form.Item
              label="Family Name"
              name="efamilyName"
              rules={[{ required: true, message: "Family name is required!" }]}
            >
              <Input placeholder="Please type family name" />
            </Form.Item>
            <Form.Item
              label="Given Name"
              name="egivenName"
              rules={[{ required: true, message: "Given name is required!" }]}
            >
              <Input placeholder="Please type given name" />
            </Form.Item>
            <Form.Item
              label="Mobile Phone"
              name="ephone"
              rules={[{ required: true, message: "Given name is required!" }]}
            >
              <Input placeholder="Please type emergency contact mobile phone" />
            </Form.Item>
            <Form.Item
              wrapperCol={
                lg
                  ? {
                      offset: 6,
                      span: 18,
                    }
                  : {}
              }
              className="mt-6"
            >
              Please read and accept{" "}
              <span
                className="text-sky-500 cursor-pointer"
                onClick={() => {
                  setVisible(true);
                  form.setFieldsValue({ accepted: true });
                }}
              >
                terms and conditions
              </span>
            </Form.Item>
            <Form.Item
              wrapperCol={
                lg
                  ? {
                      offset: 6,
                      span: 18,
                    }
                  : {}
              }
              className="-mt-6"
              name="accepted"
              valuePropName="checked"
            >
              <Checkbox
                onChange={() => {
                  form.getFieldValue("accepted") && setVisible(true);
                }}
              >
                I accept the{" "}
                <span className="text-sky-500">terms and conditions</span>
              </Checkbox>
            </Form.Item>
            <Form.Item
              wrapperCol={
                lg
                  ? {
                      offset: 6,
                      span: 18,
                    }
                  : {}
              }
            >
              {!s1Open && <p>You can add any siblings you want to add.</p>}
            </Form.Item>
            {s1Open && (
              <>
                {" "}
                <Form.Item>
                  <Divider orientation="left">
                    <span className="text-red-600 font-bold">
                      SIBLING-1 DETAILS
                    </span>
                  </Divider>
                </Form.Item>
                <Form.Item
                  label="Application Type"
                  name="s1appType"
                  rules={[
                    { required: true, message: "This filed is required!" },
                  ]}
                >
                  <Select placeholder="Please select">
                    {["New Application", "Renew Application"].map(
                      (a, index) => (
                        <Select.Option key={index} value={a}>
                          {a}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Family Name"
                  name="s1familyName"
                  rules={[
                    { required: true, message: "Family name is required!" },
                  ]}
                >
                  <Input placeholder="Please type family name" />
                </Form.Item>
                <Form.Item
                  label="Given Name"
                  name="s1givenName"
                  rules={[
                    { required: true, message: "Given name is required!" },
                  ]}
                >
                  <Input placeholder="Please type given name" />
                </Form.Item>
                <Form.Item
                  label="Student Year Level in 2022"
                  name="s1year"
                  rules={[
                    { required: true, message: "This field is required!" },
                  ]}
                >
                  <Select placeholder="Select Year">
                    {GlobalVariables.years.map((y) => (
                      <Select.Option key={y} value={y}>
                        {y}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Student Usage"
                  name="s1usage"
                  rules={[
                    { required: true, message: "This field is required!" },
                  ]}
                >
                  <Select placeholder="Select Usage">
                    {GlobalVariables.usages.map((y) => (
                      <Select.Option key={y.key} value={y.key}>
                        {y.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Any Medical Condition?" name="s1medical">
                  <Input placeholder="Please Type Medical conditions (if any) " />
                </Form.Item>
                <Form.Item
                  wrapperCol={
                    lg
                      ? {
                          offset: 6,
                          span: 18,
                        }
                      : {}
                  }
                >
                  {s1Open && (
                    <Button
                      type="primary"
                      danger
                      ghost
                      onClick={() => setS1Open(!s1Open)}
                    >
                      Cancel Sibling-1
                    </Button>
                  )}{" "}
                  {!s2Open && (
                    <Button type="primary" onClick={() => setS2Open(!s2Open)}>
                      Add Another Sibling
                    </Button>
                  )}
                </Form.Item>
              </>
            )}
            {s2Open && (
              <>
                {" "}
                <Form.Item>
                  <Divider orientation="left">
                    <span className="text-red-600 font-bold">
                      SIBLING-2 DETAILS
                    </span>
                  </Divider>
                </Form.Item>
                <Form.Item
                  label="Application Type"
                  name="s2appType"
                  rules={[
                    { required: true, message: "This filed is required!" },
                  ]}
                >
                  <Select placeholder="Please select">
                    {["New Application", "Renew Application"].map(
                      (a, index) => (
                        <Select.Option key={index} value={a}>
                          {a}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Family Name"
                  name="s2familyName"
                  rules={[
                    { required: true, message: "Family name is required!" },
                  ]}
                >
                  <Input placeholder="Please type family name" />
                </Form.Item>
                <Form.Item
                  label="Given Name"
                  name="s2givenName"
                  rules={[
                    { required: true, message: "Given name is required!" },
                  ]}
                >
                  <Input placeholder="Please type given name" />
                </Form.Item>
                <Form.Item
                  label="Student Year Level in 2022"
                  name="s2year"
                  rules={[
                    { required: true, message: "This field is required!" },
                  ]}
                >
                  <Select placeholder="Select Year">
                    {GlobalVariables.years.map((y) => (
                      <Select.Option key={y} value={y}>
                        {y}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Student Usage"
                  name="s2usage"
                  rules={[
                    { required: true, message: "This field is required!" },
                  ]}
                >
                  <Select placeholder="Select Usage">
                    {GlobalVariables.usages.map((y) => (
                      <Select.Option key={y.key} value={y.key}>
                        {y.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Any Medical Condition?" name="s2medical">
                  <Input placeholder="Please Type Medical conditions (if any) " />
                </Form.Item>
                <Form.Item
                  wrapperCol={
                    lg
                      ? {
                          offset: 6,
                          span: 18,
                        }
                      : {}
                  }
                >
                  {s1Open && (
                    <Button
                      type="primary"
                      danger
                      ghost
                      onClick={() => setS2Open(!s2Open)}
                    >
                      Cancel Sibling-2
                    </Button>
                  )}{" "}
                  {!s3Open && (
                    <Button type="primary" onClick={() => setS3Open(!s3Open)}>
                      Add Another Sibling
                    </Button>
                  )}
                </Form.Item>
              </>
            )}
            {s3Open && (
              <>
                {" "}
                <Form.Item>
                  <Divider orientation="left">
                    <span className="text-red-600 font-bold">
                      SIBLING-3 DETAILS
                    </span>
                  </Divider>
                </Form.Item>
                <Form.Item
                  label="Application Type"
                  name="s3appType"
                  rules={[
                    { required: true, message: "This filed is required!" },
                  ]}
                >
                  <Select placeholder="Please select">
                    {["New Application", "Renew Application"].map(
                      (a, index) => (
                        <Select.Option key={index} value={a}>
                          {a}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Family Name"
                  name="s3familyName"
                  rules={[
                    { required: true, message: "Family name is required!" },
                  ]}
                >
                  <Input placeholder="Please type family name" />
                </Form.Item>
                <Form.Item
                  label="Given Name"
                  name="s3givenName"
                  rules={[
                    { required: true, message: "Given name is required!" },
                  ]}
                >
                  <Input placeholder="Please type given name" />
                </Form.Item>
                <Form.Item
                  label="Student Year Level in 2022"
                  name="s3year"
                  rules={[
                    { required: true, message: "This field is required!" },
                  ]}
                >
                  <Select placeholder="Select Year">
                    {GlobalVariables.years.map((y) => (
                      <Select.Option key={y} value={y}>
                        {y}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Student Usage"
                  name="s3usage"
                  rules={[
                    { required: true, message: "This field is required!" },
                  ]}
                >
                  <Select placeholder="Select Usage">
                    {GlobalVariables.usages.map((y) => (
                      <Select.Option key={y.key} value={y.key}>
                        {y.value}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Any Medical Condition?" name="s3medical">
                  <Input placeholder="Please Type Medical conditions (if any) " />
                </Form.Item>
                <Form.Item
                  wrapperCol={
                    lg
                      ? {
                          offset: 6,
                          span: 18,
                        }
                      : {}
                  }
                >
                  {s1Open && (
                    <Button
                      type="primary"
                      danger
                      ghost
                      onClick={() => setS3Open(!s3Open)}
                    >
                      Cancel Sibling-3
                    </Button>
                  )}
                </Form.Item>
              </>
            )}
            <Form.Item
              wrapperCol={
                lg
                  ? {
                      offset: 6,
                      span: 18,
                    }
                  : {}
              }
              className="mt-6"
            >
              <Space direction="vertical" size="large">
                {!s1Open && (
                  <Button
                    type="default"
                    onClick={() => setS1Open(!s1Open)}
                    icon={<UserAddOutlined />}
                  >
                    Add Sibling
                  </Button>
                )}
                <Space>
                  <Button
                    type="default"
                    htmlType="reset"
                    icon={<ClearOutlined />}
                  >
                    Clear Form
                  </Button>

                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                    disabled={form.getFieldValue("accepted") === false}
                  >
                    Submit Form
                  </Button>
                </Space>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default NewBus;
