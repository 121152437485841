import {
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  OrderedListOutlined,
  SaveOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Form,
  Input,
  InputNumber,
  notification,
  Popconfirm,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
} from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AdminService } from "src/resources/AdminService";
import { GlobalVariables } from "src/resources/GlobalVariables";
import { suburbs } from "src/resources/suburbs";

const Buses = () => {
  const [form] = Form.useForm();
  const { lg } = useBreakpoint();
  const [initialRender, setInitialRender] = useState(true);
  const [buses, setBuses] = useState(null);
  const [onEdit, setOnEdit] = useState(false);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getBuses().then((data) => {
        console.log("buses", data.data);
        setBuses(data.data);
      });
    }
  }, [initialRender]);

  const onFinish = (v) => {
    console.log(v);
    if (!onEdit) {
      AdminService.newBus(v).then((data) => {
        notification.success({ message: "Bus Added!" });
        onReset();
        setInitialRender(true);
      });
    } else {
      console.log(v);
      AdminService.editBus(v).then((data) => {
        notification.success({ message: "Bus Edited" });
        onReset();
        setInitialRender(true);
      });
    }
  };
  const onReset = () => {
    form.resetFields();
    setOnEdit(false);
  };

  const columns = [
    {
      title: "Number",
      dataIndex: "busno",
      render: (busno) => `Bus - ${busno}`,
    },
    {
      title: "Driver",
      dataIndex: "driver",
    },
    {
      title: "Driver Phone",
      dataIndex: "phone",
    },
    {
      title: "Suburbs",
      dataIndex: "suburbs",
      render: (suburbs) =>
        suburbs.map((s, index) => <Tag key={index}>{s.name}</Tag>),
    },
    {
      title: "Students",
      dataIndex: "count",
      className: "text-center",
    },
    {
      title: "Actions",
      render: (item) => (
        <Space>
          <Button
            icon={<OrderedListOutlined />}
            onClick={() => router.push("/admin/buses/" + item.id)}
          />
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => editBus(item)}
          />{" "}
          {item.count === 0 && (
            <Tooltip title="Delete Bus" placement="left">
              <Popconfirm
                title="Are you sure ?"
                onConfirm={() => deleteBus(item.id)}
                okText="Yes! Delete."
                cancelText="Cancel"
              >
                <Button type="primary" danger icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];
  const deleteBus = (id) => {
    AdminService.deleteBus(id).then((data) => {
      notification.success({ message: "Bus Deleted!" });
      setInitialRender(true);
    });
  };
  const editBus = (v) => {
    console.log(v);
    setOnEdit(true);
    const subs = [];
    v.suburbs.forEach((s) => {
      subs.push(s.name);
    });
    form.setFieldsValue({
      busno: v.busno,
      driver: v.driver,
      phone: v.phone,
      suburbs: subs,
      id: v.id,
    });
  };
  return (
    <div className="max-w-6xl bg-fafafa border border-gray-300  mx-auto p-4">
      <Divider orientation="left">Buses</Divider>
      <Table
        rowKey="id"
        dataSource={buses}
        columns={columns}
        size="small"
        bordered
        loading={buses === null}
        pagination={false}
      />
      <br />
      <Divider orientation="left">
        {" "}
        {onEdit ? "Edit " : "New "} Bus Form
      </Divider>
      <Form
        form={form}
        onFinish={onFinish}
        onReset={onReset}
        {...GlobalVariables.formLayout}
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          label="Bus No"
          name="busno"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <InputNumber max={50} min={1} placeholder="Bus No" />
        </Form.Item>
        <Form.Item
          label="Bus Driver"
          name="driver"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Input placeholder="Please type driver's name" />
        </Form.Item>
        <Form.Item
          label="Driver Phone"
          name="phone"
          rules={[{ required: true, message: "This field is required!" }]}
        >
          <Input placeholder="Please type driver's phone" />
        </Form.Item>
        <Form.Item
          label="Bus Routes"
          name="suburbs"
          rules={[{ required: true, message: "Suburbs are required" }]}
        >
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder="Please Select Suburbs"
            mode="multiple"
            allowClear
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {suburbs
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map((c, index) => (
                <Select.Option key={index} value={c.suburb}>
                  {c.suburb}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          wrapperCol={
            lg
              ? {
                  offset: 6,
                  span: 18,
                }
              : {}
          }
          className="mt-6"
        >
          <Space>
            <Button type="default" htmlType="reset" icon={<ClearOutlined />}>
              Clear Form
            </Button>

            <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
              {onEdit ? "Edit " : "Submit "} Form
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Buses;
