import React from "react";

const TermsAndConditions = () => {
  return (
    <div>
      <p className="text-base font-bold text-gray-800">
        Acknowledge Terms and Conditions - 1*
      </p>
      <p className="ml-6">
        Choppers Transport cannot guarantee a place in a particular bus route.
        Parents should inform Choppers Transport about their intention of using
        a particular school bus route by filling in Students details below, at
        the time of enrolment/re-enrolment. Bus routes are subject to change by
        Choppers Transport Management.
      </p>
      <p className="text-base font-bold text-gray-800">
        Acknowledge Terms and Conditions - 2*
      </p>
      <p className="ml-6">
        A priority will be given to one year long applications and/or two way
        usages. Bus service is available at least for one full term and one way
        use request if subject to availability
      </p>
      <p className="text-base font-bold text-gray-800">
        Acknowledge Terms and Conditions - 3*
      </p>
      <p className="ml-6">
        No remission of bus fees, either in whole or in part shall be made,
        should the student be absent from Amity College, for any reason
        (including holidays).
      </p>
      <p className="text-base font-bold text-gray-800">
        Acknowledge Terms and Conditions - 4*
      </p>
      <p className="ml-6">
        Transport (Bus) Fees are updated yearly and available at school
        reception.
      </p>
      <p className="text-base font-bold text-gray-800">
        Acknowledge Terms and Conditions - 5*
      </p>
      <p className="ml-6">
        Pickup & Drop off times are between the hours 7.00am – 5.15pm. We cannot
        guarantee a certain time. At any given time changes can be made
        depending on the situation (such as new applicants or students leaving
        etc.)
      </p>
      <p className="text-base font-bold text-gray-800">
        Acknowledge Terms and Conditions - 6*
      </p>
      <p className="ml-6">
        Please be advised, the bus service can not change its route for your
        child/children extra curriculum activities.
      </p>
      <p className="text-base font-bold text-gray-800">
        Acknowledge Terms and Conditions - 7*
      </p>
      <p className="ml-6">
        Any change of address on your behalf, we can not guarantee our bus
        services to the new address
      </p>
      <p className="text-base font-bold text-gray-800">
        Acknowledge Terms and Conditions - 8*
      </p>
      <p className="ml-6">
        School Bus Service may be suspended or withdrawn if The student or
        parent/carer does not comply with the conditions of School Bus Service
        (including bus fee)
      </p>
      <p className="text-base font-bold text-gray-800">
        Acknowledge Terms and Conditions - 9*
      </p>
      <p className="ml-6">
        School Bus Service may be suspended or withdrawn if The student does not
        obey the code of conduct for travel on the school buses and/or behaves
        unacceptably
      </p>
      <p className="text-base font-bold text-gray-800">
        Acknowledge Terms and Conditions - 10*
      </p>
      <p className="ml-6">
        In order for students to enjoy safe, comfortable transport, we work hard
        to maintain clean vehicles. Students are responsible for damages to the
        interior or exterior of a vehicle. Fees are assessed and charged
        according to the extent of damage. These fees are based on a national
        average of costs and will be subject to review by the school. The bus
        company has the right to refuse service to any student that may
        continually cause damage to the vehicles.
      </p>
      <p className="text-base font-bold text-gray-800">
        Acknowledge Terms and Conditions - 11*
      </p>
      <p className="ml-6">
        The bus company may reserve the right to set pick up and drop off
        locations elsewhere such as a location close to but not the intended
        address or across the road to the home address if;
        <span className="pl-4">
          <br /> {"   "} i) Vehicle access is not safe according to the company
          or driver <br /> {"   "} ii) The home address is not suitable and/or
          may cause delays on the bus route <br /> {"   "} iii) The parents,
          school or bus company proposes an alternate address.
        </span>
      </p>
      <p className="ml-6">
        Please note, we do not guarantee a bus service to your suburb in 2022.
      </p>
      <p className="ml-6">Any request is subject to approval.</p>
      <p className="ml-6">
        Transport fees are in the schools fees documents at Amity College
        Website
      </p>
      <p className="font-bold">Conditions of School Bus Service</p>
      <p className="ml-6">
        • Student/s must be ready on time for their morning pick-up.{" "}
      </p>
      <p className="ml-6">
        • Our drivers are advised to wait for 2 minutes after arriving at their
        homes. If the student is not ready for pick up, buses can not wait or
        return to the address at a later time as the routes are time sensitive.
      </p>
      <p className="ml-6">
        • Students under the age of 10 must be met by their parents/guardians at
        drop of point.{" "}
      </p>
      <p className="ml-6">
        • Primary students must be with/met by their parents/guardians at
        pick-up/drop-off point.
      </p>
      <p className="ml-6">
        • Chopper/Amity takes no responsibility of child left unattended at
        pick-up/drop-off points
      </p>
      <p className="ml-6">• School bus will depart at 3:45pm from school. </p>
      <p className="font-bold">
        Misbehaviors Listed Below Will Not Be Tolerated{" "}
      </p>
      <p className="ml-6">
        • Serious bullying and harassment of other students.{" "}
      </p>
      <p className="ml-6">
        • Allowing any part of the body to protrude from the bus whilst bus is
        in motion.{" "}
      </p>
      <p className="ml-6">
        • Stopping others from disembarking at their stop.{" "}
      </p>
      <p className="ml-6">
        • Verbally threatening the driver or other students.{" "}
      </p>
      <p className="ml-6">• Standing and refusing to sit down. </p>
      <p className="ml-6">
        • Pushing and shoving when boarding or exiting bus.{" "}
      </p>
      <p className="ml-6">• Swinging on bus. </p>
      <p className="ml-6">• Throwing things inside or out of the bus. </p>
      <p className="ml-6">• Fighting with other students. </p>
      <p className="ml-6">• Using Matches and lighters. </p>
      <p className="ml-6">• Carrying dangerous items. </p>
      <p className="ml-6">• Refusing to wear seat belts where fitted. </p>
      <p className="ml-6">
        • Causing damage to bus property (Action taken apart from compensation
        of damage){" "}
      </p>
      <p className="ml-6">
        Please note all school buses are fitted with surveillance cameras and
        will be recording both inside & outside of vehicle. If any school bus is
        stopped by police & the driver is issued an infringement notice for a
        student not wearing a seat belt, their Parents/Guardians/Carers are
        liable to pay the infringement notice.{" "}
      </p>
      <p className="font-bold">PROCEDURE OF MISBEHAVIOUR </p>
      <p className="ml-6">
        First Offence / Phone call Management will advise
        Parents/Guardians/Carers, verbally over phone call.{" "}
      </p>
      <p className="ml-6">
        Second Offence or for Serious Misconduct Management will advise
        Parents/Guardians/Carers, and the School in writing.{" "}
      </p>
      <p className="ml-6">
        Third Offence or for Continual Misbehaviour Management will advise
        Parents/Guardians/Carers, and the School in writing.
      </p>
      <p className="ml-6">
        Students may be allocated specific seats if any damage is caused to
        allocated seats while students are on the bus costs may be incurred by
        parents.
      </p>
    </div>
  );
};

export default TermsAndConditions;
