import { Row, Col, Card, Statistic } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AdminService } from "src/resources/AdminService";

const Dashboard = () => {
  const [reports, setReports] = useState(null);
  const [initialRender, setInitialRender] = useState(true);
  const router = useHistory();
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      AdminService.getReports().then((data) => {
        setReports(data.data);
        console.log(data.data);
      });
    }
  }, [initialRender]);

  return (
    <div>
      <div>
        {reports && (
          <div
            style={{
              backgroundColor: "#f5f5f5",
              padding: "15px",
              border: "1px solid #ddd",
            }}
          >
            <Row gutter={[6, 6]} wrap>
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <Card
                  className="stat-card"
                  hoverable
                  onClick={() => router.push("/admin/students")}
                >
                  <Statistic
                    title="
    Students"
                    value={reports.students}
                    valueStyle={{ fontWeight: "bold" }}
                  />
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <Card
                  className="stat-card"
                  hoverable
                  onClick={() => router.push("/admin/buses")}
                >
                  <Statistic
                    title="
    Buses"
                    value={reports.buses}
                    valueStyle={{ fontWeight: "bold" }}
                  />
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <Card
                  className="stat-card"
                  hoverable
                  onClick={() => router.push("/admin/withdraw")}
                >
                  <Statistic
                    title="
    Withdraws"
                    value={reports.wdraws}
                    valueStyle={{ fontWeight: "bold" }}
                  />
                </Card>
              </Col>
              <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                <Card className="stat-card" hoverable>
                  <Statistic
                    title="
    Suburbs"
                    value={reports.suburbs}
                    valueStyle={{ fontWeight: "bold" }}
                  />
                </Card>
              </Col>
              {reports.suburbStudents.map((s, index) => (
                <Col xs={24} sm={12} md={8} lg={6} xl={6}>
                  <Card
                    className="stat-card bg-yellow-50 border-gray-300 border"
                    hoverable
                  >
                    <Statistic
                      title={s.name}
                      value={s.ss}
                      valueStyle={{ fontWeight: "bold" }}
                    />
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
